import React, { useState } from "react";
import {
  Building2,
  Mail,
  ArrowRight,
  Loader2,
  AlertCircle,
  Lock,
  Eye,
  EyeOff,
} from "lucide-react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/components/Common";
import { Button } from "@/components/Common";
import { Input } from "@/components/Common";
import { Label } from "@/components/Common";
import { motion } from "framer-motion";
import {
  FaGoogle,
  FaSlack,
  FaClock,
  FaCode,
} from "react-icons/fa";
import { SiSupabase, SiAirtable, SiPostgresql } from "react-icons/si";
import { SetupStep, useSetup } from "@/context/SetupContext";
import { useTeam } from "@/context/TeamContext";
import { setupService } from "@/services/setupService";

const sections = {
  databases: {
    id: "databases",
    title: "",
    heading: "Connect your data sources",
    description:
      "WaveQuery integrates seamlessly with industry-leading databases",
    items: [
      {
        title: "Google BigQuery",
        tag: "ENTERPRISE READY",
        description: "Serverless data warehouse for enterprise analytics",
        icon: FaGoogle,
        bgClass: "from-blue-50 via-indigo-50 to-red-50",
        link: "https://cloud.google.com/bigquery",
      },
      {
        title: "PostgreSQL",
        tag: "BATTLE TESTED",
        description: "World's most advanced open source database",
        icon: SiPostgresql,
        bgClass: "from-blue-50 via-indigo-50 to-blue-100",
        link: "https://www.postgresql.org/",
      },
      {
        title: "Supabase",
        tag: "DEVELOPER FRIENDLY",
        description: "Open source Firebase alternative with PostgreSQL",
        icon: SiSupabase,
        bgClass: "from-emerald-50 via-green-50 to-emerald-100",
        link: "https://supabase.com/",
      },
      {
        title: "Airtable",
        tag: "NO-CODE FRIENDLY",
        description: "Powerful spreadsheet-database hybrid with API access",
        icon: SiAirtable,
        bgClass: "from-teal-50 via-cyan-50 to-teal-100",
        link: "https://airtable.com/",
      },
    ],
  },
  integrations: {
    id: "integrations",
    title: "AVAILABLE INTEGRATIONS",
    heading: "Enhance your workflow",
    description: "Supercharge your data analysis with powerful integrations",
    items: [
      {
        title: "Automated Workflows",
        tag: "PRODUCTIVITY",
        description: "Schedule and automate your data tasks effortlessly",
        icon: FaClock,
        bgClass: "from-purple-50 via-purple-50 to-purple-100",
        soon: false,
      },
      {
        title: "API Access",
        tag: "DEVELOPER TOOLS",
        description: "Integrate WaveQuery directly into your applications",
        icon: FaCode,
        bgClass: "from-gray-50 via-gray-50 to-gray-100",
        soon: false,
      },
      {
        title: "Slack Integration",
        tag: "TEAM COLLABORATION",
        description: "Get insights and alerts directly in your Slack channels",
        icon: FaSlack,
        bgClass: "from-yellow-50 via-yellow-50 to-yellow-100",
        soon: false,
      },
    ],
  },
};

const FeatureCard = ({ feature, index }) => {
  const Icon = feature.icon;

  const handleClick = () => {
    if (feature.link && !feature.soon) {
      window.open(feature.link, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
      onClick={handleClick}
      className={`
        group relative overflow-hidden rounded-xl p-6 transition-all duration-300
        ${feature.link && !feature.soon ? "cursor-pointer" : ""}
        ${feature.soon ? "opacity-80" : ""}
        border border-gray-100 dark:border-gray-800
        hover:shadow-lg hover:scale-[1.02] active:scale-[0.99]
        bg-gradient-to-br ${feature.bgClass}
      `}
    >
      {/* Background Icon */}
      <div className="absolute right-0 bottom-0 opacity-[0.07] transform translate-x-1/4 translate-y-1/4 transition-transform group-hover:scale-110">
        <Icon className="w-32 h-32" />
      </div>

      {/* Content */}
      <div className="relative z-10">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-3">
            <div className="p-2.5 bg-white dark:bg-gray-800 rounded-lg shadow-sm">
              <Icon className="w-5 h-5 text-gray-600 dark:text-gray-300" />
            </div>
            <div>
              <p className="text-[10px] font-medium tracking-wider text-gray-500 dark:text-gray-400 mb-1">
                {feature.tag}
              </p>
              <h3 className="font-medium text-gray-900 dark:text-gray-100">
                {feature.title}
              </h3>
            </div>
          </div>
          {feature.soon && (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100/50 text-gray-600 dark:bg-gray-800/50 dark:text-gray-300">
              Coming Soon
            </span>
          )}
        </div>

        <p className="text-sm text-gray-600 dark:text-gray-300 mt-2 line-clamp-2">
          {feature.description}
        </p>

        {feature.link && !feature.soon && (
          <div className="mt-4 inline-flex items-center text-sm text-blue-600 dark:text-blue-400 font-medium">
            Learn more
            <ArrowRight className="w-4 h-4 ml-1 transition-transform group-hover:translate-x-1" />
          </div>
        )}
      </div>
    </motion.div>
  );
};

const RegisterOrg: React.FC = () => {
  const { updateStep } = useSetup();
  const { teamId } = useTeam();

  const [formData, setFormData] = useState({
    orgName: "",
    email: "",
    password: "", // Add password to form state
    confirmPassword: "", // Add confirmation password for validation
  });

  const [showPassword, setShowPassword] = useState(false); // Toggle password visibility
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [activeSection, setActiveSection] = useState("databases");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    // Add password validation
    if (formData.password.length < 8) {
      setError("Password must be at least 8 characters long");
      setIsLoading(false);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      setIsLoading(false);
      return;
    }

    try {
      if (!teamId) {
        throw new Error("Team ID is missing");
      }
      const response = await setupService.registerOrg({
        name: formData.orgName,
        teamId,
        adminEmail: formData.email,
        password: formData.password, // Add password to API call
      });
      const { data } = response;
      await updateStep(data.nextStep as SetupStep, { userId: data.userId });
    } catch (err) {
      setError("Failed to register organization. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-full w-full max-w-9xl mx-auto grid grid-cols-2 lg:grid-cols-2 gap-4">
      <div className="flex items-start justify-center">
        <div className="w-full max-w-md">
          <Card className="border-0 shadow-none bg-gradient-to-br from-white/50 via-white/25 to-white/30 dark:from-gray-900/50 dark:via-gray-900/25 dark:to-gray-900/30 backdrop-blur-sm">
            <CardHeader className="space-y-6 pb-6">
              <div className="space-y-2">
                <div className="flex items-center">
                  <div className="flex items-center gap-2">
                    <span className="inline-flex items-center px-3 py-1.5 rounded-full text-xs font-medium bg-emerald-50 text-emerald-600 dark:bg-emerald-900/30 dark:text-emerald-400 border border-emerald-100 dark:border-emerald-800">
                      <SiPostgresql className="w-3.5 h-3.5 mr-1.5" />
                      Test with Sample Database in less than minutes
                    </span>
                    <ArrowRight className="w-4 h-4 text-emerald-600 dark:text-emerald-400 animate-pulse" />
                  </div>
                </div>
                <CardTitle className="text-3xl font-normal">
                  Create your organization
                </CardTitle>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  Set up your workspace and start analyzing your data in minutes
                </p>
              </div>
            </CardHeader>

            <CardContent>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="space-y-4">
                  <div className="space-y-2">
                    <div className="relative">
                      <Input
                        id="orgName"
                        type="text"
                        value={formData.orgName}
                        onChange={(e) =>
                          setFormData({ ...formData, orgName: e.target.value })
                        }
                        className="h-11 pl-10 bg-white dark:bg-black border-gray-200 dark:border-gray-800 
                        focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
                        placeholder="Enter your organization name"
                        required
                        disabled={isLoading}
                      />
                      <Building2 className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
                    </div>
                  </div>

                  <div className="space-y-2">
                    <div className="relative">
                      <Input
                        id="email"
                        type="email"
                        value={formData.email}
                        onChange={(e) =>
                          setFormData({ ...formData, email: e.target.value })
                        }
                        className="h-11 pl-10 bg-white dark:bg-black border-gray-200 dark:border-gray-800 
                        focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
                        placeholder="Enter your email address"
                        required
                        disabled={isLoading}
                      />
                      <Mail className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
                    </div>
                  </div>
                  <div className="space-y-2">
                    <Label
                      htmlFor="password"
                      className="text-sm font-medium text-gray-700 dark:text-gray-300"
                    >
                      Password
                    </Label>
                    <div className="relative">
                      <Input
                        id="password"
                        type={showPassword ? "text" : "password"}
                        value={formData.password}
                        onChange={(e) =>
                          setFormData({ ...formData, password: e.target.value })
                        }
                        className="h-11 pl-10 pr-10 bg-white dark:bg-black border-gray-200 dark:border-gray-800 
            focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
                        placeholder="Enter your password"
                        required
                        disabled={isLoading}
                        minLength={8}
                      />
                      <Lock className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute right-3 top-3 text-gray-400 hover:text-gray-600"
                      >
                        {showPassword ? (
                          <EyeOff className="h-5 w-5" />
                        ) : (
                          <Eye className="h-5 w-5" />
                        )}
                      </button>
                    </div>
                    <p className="text-xs text-gray-500">
                      Must be at least 8 characters
                    </p>
                  </div>

                  <div className="space-y-2">
                    <div className="relative">
                      <Input
                        id="confirmPassword"
                        type={showPassword ? "text" : "password"}
                        value={formData.confirmPassword}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            confirmPassword: e.target.value,
                          })
                        }
                        className="h-11 pl-10 bg-white dark:bg-black border-gray-200 dark:border-gray-800 
            focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
                        placeholder="Confirm your password"
                        required
                        disabled={isLoading}
                      />
                      <Lock className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
                    </div>
                  </div>
                </div>

                {error && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="p-3 border border-red-100 dark:border-red-900/50 rounded-lg 
                    bg-red-50/50 dark:bg-red-900/10 backdrop-blur-sm"
                  >
                    <div className="flex gap-3">
                      <AlertCircle className="w-5 h-5 text-red-600 dark:text-red-400 flex-shrink-0" />
                      <p className="text-sm text-red-600 dark:text-red-400">
                        {error}
                      </p>
                    </div>
                  </motion.div>
                )}

                <Button
                  type="submit"
                  disabled={isLoading}
                  className="w-full h-11 bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-500 
                  hover:to-blue-600 text-white transition-all font-normal relative overflow-hidden group"
                >
                  <span className="relative z-10 flex items-center justify-center gap-2">
                    {isLoading ? (
                      <>
                        <Loader2 className="w-4 h-4 animate-spin" />
                        Setting up...
                      </>
                    ) : (
                      <>
                        Get Started
                        <ArrowRight className="w-4 h-4 group-hover:translate-x-0.5 transition-transform" />
                      </>
                    )}
                  </span>
                </Button>
              </form>
            </CardContent>

            <CardFooter>
              <div className="w-full space-y-2">
                <p className="text-sm text-gray-500 dark:text-gray-400 text-center">
                  By creating an organization, you agree to our{" "}
                  <a
                    target="_blank"
                    href="https://wavequery.com/terms"
                    className="text-blue-600 dark:text-blue-400 hover:underline"
                  >
                    Terms
                  </a>{" "}
                  and{" "}
                  <a
                    target="_blank"
                    href="https://wavequery.com/privacy"
                    className="text-blue-600 dark:text-blue-400 hover:underline"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
            </CardFooter>
          </Card>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex items-center space-x-1 p-1 mb-8 rounded-lg bg-gray-100/50 dark:bg-gray-800/50 backdrop-blur-sm w-fit">
          {Object.values(sections).map((section) => (
            <button
              key={section.id}
              onClick={() => setActiveSection(section.id)}
              className={`px-4 py-2 text-sm font-medium rounded-md transition-all
          ${
            activeSection === section.id
              ? "bg-white dark:bg-gray-900 text-blue-600 dark:text-blue-400 shadow-sm"
              : "text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200"
          }`}
            >
              {section.id.charAt(0).toUpperCase() + section.id.slice(1)}
            </button>
          ))}
        </div>

        <motion.div
          key={activeSection}
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -20 }}
          transition={{ duration: 0.2 }}
          className="space-y-6"
        >
          <div>
            <span className="text-blue-500 dark:text-blue-400 text-sm font-medium">
              {sections[activeSection].title}
            </span>
            <h2 className="text-2xl font-medium mt-2 mb-2">
              {sections[activeSection].heading}
            </h2>
            <p className="text-base text-gray-600 dark:text-gray-400">
              {sections[activeSection].description}
            </p>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4">
            {sections[activeSection].items.map((feature, index) => (
              <FeatureCard
                key={feature.title}
                feature={feature}
                index={index}
              />
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default RegisterOrg;
