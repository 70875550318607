import React from "react";
import { MessageSquare, ArrowRight } from "lucide-react";
import { motion } from "framer-motion";
import { cn } from "@/components/utils";
import { useTeam } from "@/context/TeamContext";

interface WelcomeScreenProps {
  onQuestionClick: (question: string) => void;
}

const WelcomeScreen: React.FC<WelcomeScreenProps> = ({ onQuestionClick }) => {
  const { warehouseDetails } = useTeam();

  const sampleQuestions = [
    {
      title: "Customer Rentals by Store",
      question:
        "Give me a list of customers who are renting from a store located in '478 Joliet Way'",
      description: "Find rental patterns by store location",
      gradient:
        "from-blue-50 to-indigo-50 dark:from-blue-900/10 dark:to-indigo-900/10",
      borderColor: "border-blue-100 dark:border-blue-800",
    },
    {
      title: "User Data Overview",
      question: "Show me the table related to user data",
      description: "Quick look at customer information",
      gradient:
        "from-blue-50 to-indigo-50 dark:from-blue-900/10 dark:to-indigo-900/10",
      borderColor: "border-blue-100 dark:border-blue-800",
    },
    {
      title: "Movies by Genre",
      question: "List me 10 movies in different unique genres",
      description: "Explore film variety across categories",
      gradient:
        "from-blue-50 to-indigo-50 dark:from-blue-900/10 dark:to-indigo-900/10",
      borderColor: "border-blue-100 dark:border-blue-800",
    },
    {
      title: "Staff Performance",
      question:
        "Who are the top 5 staff members by rental revenue, and which store do they work at?",
      description: "Analyze staff sales performance",
      gradient:
        "from-blue-50 to-indigo-50 dark:from-blue-900/10 dark:to-indigo-900/10",
      borderColor: "border-blue-100 dark:border-blue-800",
    },
    {
      title: "Customer Demographics",
      question:
        "Show me the distribution of customers across different countries",
      description: "Geographical customer analysis",
      gradient:
        "from-blue-50 to-indigo-50 dark:from-blue-900/10 dark:to-indigo-900/10",
      borderColor: "border-blue-100 dark:border-blue-800",
    },
    {
      title: "Payment Analysis",
      question: "What is the average rental payment amount by film category?",
      description: "Revenue analysis by genre",
      gradient:
        "from-blue-50 to-indigo-50 dark:from-blue-900/10 dark:to-indigo-900/10",
      borderColor: "border-blue-100 dark:border-blue-800",
    },
  ];

  return (
    <div className="h-full w-full overflow-y-auto p-8">
      <div className="max-w-5xl mx-auto">
        <div className="flex flex-col items-center justify-center mb-12">
          <MessageSquare size={64} className="text-blue-500 mb-6" />
          <h2 className="text-2xl font-bold mb-4 text-center">
            Welcome to WaveQuery Chat
            {/* {warehouseDetails?.warehouse === "postgres"
              ? "Postgres"
              : "BigQuery"}{" "} */}
          </h2>
          <p className="text-gray-600 dark:text-gray-300 text-center">
            Ask questions about your data or try one of these examples
          </p>
        </div>

        {warehouseDetails?.datasetId === "pagila" && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {sampleQuestions.map((item, index) => {
              return (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => onQuestionClick(item.question)}
                  className={cn(
                    "relative overflow-hidden rounded-xl border transition-all cursor-pointer",
                    "hover:shadow-lg",
                    item.borderColor,
                    "bg-gradient-to-br",
                    item.gradient
                  )}
                >
                  <div className="relative z-10 p-6">
                    <div className="flex items-center gap-3 mb-3">
                      <h3 className="font-semibold text-gray-900 dark:text-gray-100">
                        {item.title}
                      </h3>
                    </div>
                    <p className="text-sm text-gray-600 dark:text-gray-300 mb-4">
                      {item.description}
                    </p>
                    <div className="flex items-center text-sm text-blue-600 dark:text-blue-400">
                      Try this query
                      <ArrowRight className="w-4 h-4 ml-1 transition-transform group-hover:translate-x-1" />
                    </div>
                  </div>
                </motion.div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default WelcomeScreen;
