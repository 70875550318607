import React, { useState, useCallback, useEffect } from "react";
import {
  Bot,
  Send,
  Loader2,
  MessageSquare,
  RotateCcw,
  Sparkles,
  Database,
  Info,
  X,
} from "lucide-react";

import { Button } from "@/components/Common/Button";
import { TextArea } from "@/components/Common/TextArea";
import { DatabaseConnection, usePlayground } from "@/context/PlaygroundContext";
import { ScrollArea } from "@/components/Common/Scroll";
import { playgroundService } from "@/services/playgroundService";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/Common/Tooltip";

import { extractTablesFromSQL, findRelatedEdges } from "../sqlParser";
import QueryResults from "../QueryResultTable";

interface Message {
  role: "user" | "assistant";
  content: string;
}

const SampleQuestions = ({ schema, onQuestionSelect }) => {
  const sampleQuestions = {
    pagila: [
      "List me 10 movies in different unique genres",
      "Show me the table related to user data",
      "Give me a list of customers who are renting from a store located in '478 Joliet Way'",
    ],
    // northwind: [
    //   "Which tables contain timestamp columns?",
    //   "Show me the schema for user authentication",
    //   "List all tables with their row counts",
    // ],
  };

  return sampleQuestions[schema] ? (
    <div className="p-4 space-y-3 mt-10">
      <div className="flex items-center gap-2 text-sm text-muted-foreground">
        <MessageSquare className="h-4 w-4" />
        <span>Try these sample questions:</span>
      </div>
      <div className="space-y-2">
        {sampleQuestions[schema].map((question, index) => (
          <button
            key={index}
            onClick={() => onQuestionSelect(question)}
            className="w-full text-left p-3 text-sm rounded-md bg-muted/50 hover:bg-muted transition-colors duration-200"
          >
            {question}
          </button>
        ))}
      </div>
    </div>
  ) : null;
};

const InfoBanner = ({ onClose }) => (
  <div className="bg-gradient-to-r from-emerald-50 to-sky-50 border border-emerald-100 rounded-lg p-4 relative">
    <Button
      variant="ghost"
      size="sm"
      className="h-6 w-6 p-0 absolute top-2 right-2 hover:bg-emerald-100/50"
      onClick={onClose}
    >
      <X className="h-3 w-3 text-emerald-600" />
    </Button>
    <div className="flex items-start gap-3">
      <Sparkles className="h-4 w-4 text-emerald-600 flex-shrink-0 mt-0.5" />
      <div className="space-y-1">
        <p className="text-xs text-emerald-700 leading-relaxed">
          Powered by WaveQuery semantic architecture and GPT-4o mini. Please
          note this playground is on the beta version and does not benefit the
          full set of features.
        </p>
      </div>
    </div>
  </div>
);

const DatabaseInfoBanner = ({ databaseSchema, onClose }) => {
  const getDatabaseInfo = (schema) => {
    const databases = {
      pagila: {
        name: "Pagila DVD Rental",
        description:
          "A DVD rental store database showcasing relationships, temporal data handling, and inventory management.",
        stats: {
          tables: 22,
        },
        icon: <Database className="h-5 w-5 text-orange-500" />,
      },
      northwind: {
        name: "Northwind",
        description:
          "The classic business database showcasing product management, order processing, and customer relationships.",
        stats: {
          tables: 13,
        },
        icon: <Database className="h-5 w-5 text-orange-500" />,
      },
    };
    return databases[schema];
  };

  const dbInfo = getDatabaseInfo(databaseSchema);
  if (!dbInfo) return null;

  return (
    <div className="mt-2 bg-gradient-to-r from-orange-50 to-amber-50 border border-orange-100 rounded-lg p-4 relative">
      <Button
        variant="ghost"
        size="sm"
        className="h-6 w-6 p-0 absolute top-2 right-2 hover:bg-orange-100/50"
        onClick={onClose}
      >
        <X className="h-3 w-3 text-orange-800" />
      </Button>
      <div className="flex items-start gap-3">
        {dbInfo.icon}
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <h4 className="text-sm font-medium text-orange-900">
              {dbInfo.name}
            </h4>
            <span className="text-xs bg-orange-100 text-orange-800 px-2 py-0.5 rounded-full">
              {dbInfo.stats.tables} tables
            </span>
          </div>
          <p className="text-xs text-orange-800 leading-relaxed">
            {dbInfo.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export const ChatPanel = () => {
  const { connection, schema, setSchema } = usePlayground();
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [showInfoBanner, setShowInfoBanner] = useState(true);
  const [showDbBanner, setShowDbBanner] = useState(true);

  const handleSend = useCallback(async () => {
    console.log("input,", input, messages);
    if (!input.trim()) return;

    const userMessage = { role: "user", content: input.trim() };
    setMessages((prev) => [...prev, userMessage]);
    setInput("");
    setIsLoading(true);

    try {
      const { response } = await playgroundService.chat(
        connection as DatabaseConnection,
        input.trim(),
        messages
      );

      // Handle SQL query highlighting
      if (response.type === "query" && response.content?.query) {
        const involvedTables = extractTablesFromSQL(response.content.query);
        const relatedEdges = findRelatedEdges(
          involvedTables,
          schema?.edges || []
        );

        // Update schema with highlighted elements
        setSchema((prev) => ({
          ...prev,
          highlightedNodes: involvedTables,
          highlightedEdges: relatedEdges,
        }));
      } else if (response.type === "conversation") {
        setMessages((prev) => [
          ...prev,
          {
            role: "assistant",
            content: { followupQuestion: response.content },
          },
        ]);
      } else {
        // Clear highlights if not a query response
        setSchema((prev) => ({
          ...prev,
          highlightedNodes: [],
          highlightedEdges: [],
        }));
      }

      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: response,
        },
      ]);
    } catch (error) {
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: "Sorry, I encountered an error. Please try again.",
        },
      ]);

      // Clear highlights on error
      setSchema((prev) => ({
        ...prev,
        highlightedNodes: [],
        highlightedEdges: [],
      }));
    } finally {
      setIsLoading(false);
    }
  }, [input, connection, messages, schema?.edges]);

  const handleSampleQuestionSelect = (question) => {
    setMessages((prev) => [...prev, question]);
    setInput(question);
    // Auto-submit the question
    setTimeout(() => {
      handleSend();
    }, 100);
  };


  useEffect(() => {
    return () => {
      setSchema((prev) => ({
        ...prev,
        highlightedNodes: [],
        highlightedEdges: [],
      }));
    };
  }, []);

  return (
    <div className="flex bg-white flex-col w-full h-[calc(100vh-8rem)]">
      {/* Header */}
      <div className="p-4 border-b flex flex-col gap-2 bg-muted/50">
        <div className="flex flex-row justify-between">
          <div>
            <h3 className="font-semibold">Database Assistant</h3>
            <p className="text-xs text-muted-foreground">
              Ask questions about your schema
            </p>
          </div>
          <div>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="sm"
                  className="h-6 w-6 p-0"
                  onClick={() => setMessages([])}
                >
                  <RotateCcw className="h-3 w-3" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Clear chat history</TooltipContent>
            </Tooltip>
          </div>
        </div>
        {showInfoBanner && (
          <InfoBanner onClose={() => setShowInfoBanner(false)} />
        )}
        {showDbBanner && (
          <DatabaseInfoBanner
            databaseSchema={connection?.schema}
            onClose={() => setShowDbBanner(false)}
          />
        )}
      </div>

      {/* Messages */}
      <ScrollArea className="flex-1 p-4 w-full">
        {messages.length === 0 ? (
          <>
            <div className="h-full flex flex-col items-center justify-center text-center p-4 space-y-4 text-muted-foreground  w-full">
              <MessageSquare className="h-8 w-8" />
              <div className="space-y-2">
                <p className="text-sm font-medium">No messages yet</p>
                <p className="text-xs">
                  Ask questions about your database schema, relationships, or
                  data insights
                </p>
              </div>
            </div>
            <SampleQuestions
              schema={connection?.schema}
              onQuestionSelect={handleSampleQuestionSelect}
            />
          </>
        ) : (
          <div className="space-y-4 w-full">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex ${
                  message.role === "user" ? "justify-end" : "justify-start"
                }`}
              >
                <div
                  className={`${
                    message.role === "user"
                      ? "bg-primary text-primary-foreground ml-4"
                      : "bg-muted mr-4"
                  } ${
                    message.role === "assistant" ? "max-w-full w-full" : "p-3"
                  } rounded-lg`}
                >
                  {message.role === "assistant" ? (
                    <div className="space-y-2 w-full">
                      {message?.content?.followupQuestion && (
                        <p className="text-sm whitespace-pre-wrap p-3">
                          {message.content.followupQuestion}
                        </p>
                      )}
                      {message?.content?.result && (
                        <div
                          className="overflow-x-auto"
                          style={{ maxWidth: "calc(100vw - 600px)" }}
                        >
                          <div className="p-3 min-w-[600px]">
                            <QueryResults data={message.content.result} />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <p className="text-sm whitespace-pre-wrap">
                      {message.content}
                    </p>
                  )}
                </div>
              </div>
            ))}
            {isLoading && (
              <div className="flex justify-start">
                <div className="bg-muted max-w-[80%] p-3 rounded-lg">
                  <Loader2 className="h-5 w-5 animate-spin" />
                </div>
              </div>
            )}
          </div>
        )}
      </ScrollArea>

      {/* Input */}
      <div className="p-4 border-t">
        <div className="flex gap-2">
          <TextArea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Ask a question..."
            className="resize-none"
            rows={1}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSend();
              }
            }}
          />
          <Button
            onClick={handleSend}
            disabled={!input.trim() || isLoading}
            className="flex-shrink-0"
          >
            {isLoading ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <Send className="h-4 w-4" />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
