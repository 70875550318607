import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSetup } from "../context/SetupContext";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "../components/Common/Card.tsx";
import { Button } from "../components/Common/Button";
import { CheckCircle, ArrowRight, Book, Code, Puzzle } from "lucide-react";

const CompletedSetup: React.FC = () => {
  const navigate = useNavigate();
  const { currentStep } = useSetup();

  const nextSteps = [
    {
      href: "/docs",
      icon: Book,
      text: "Read our documentation",
      description: "Learn how to use WaveQuery effectively",
      bgClass: "from-blue-50 via-indigo-50 to-blue-100",
    },
    // {
    //   href: "/api-docs",
    //   icon: Code,
    //   text: "Explore our API",
    //   description: "Integrate WaveQuery into your applications",
    //   bgClass: "from-green-50 via-emerald-50 to-green-100",
    // },
    // {
    //   href: "/integration-guide",
    //   icon: Puzzle,
    //   text: "Integration Guide",
    //   description: "Step-by-step guide to connect your tools",
    //   bgClass: "from-purple-50 via-violet-50 to-purple-100",
    // },
  ];

  useEffect(() => {
    if (currentStep !== "completed") {
      navigate(`/setup/${currentStep}`);
    }
  }, [currentStep, navigate]);

  return (
    <div className="max-w-4xl mx-auto overflow-scroll">
      <Card className="border-0 shadow-none bg-white/50 dark:bg-black/50 backdrop-blur-sm mb-8">
        <CardHeader className="text-center pb-8">
          <div className="flex flex-col items-center space-y-4">
            <div className="rounded-full p-3 bg-green-50 dark:bg-green-900/20">
              <CheckCircle className="w-8 h-8 text-green-500 dark:text-green-400" />
            </div>
            <div className="space-y-2">
              <CardTitle className="text-3xl font-normal">
                Setup Complete!
              </CardTitle>
              <p className="text-base text-gray-600 dark:text-gray-400">
                You're all set to start analyzing your data
              </p>
            </div>
          </div>
        </CardHeader>

        <CardContent>
          <div className="flex justify-center md:grid-cols-2 gap-4">
            <Button
              asChild
              className="h-11 bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-600 group"
            >
              <Link
                to="/dashboard"
                className="flex items-center text-white justify-center gap-2"
              >
                Go to Dashboard
                <ArrowRight className="w-4 h-4 group-hover:translate-x-0.5 transition-transform" />
              </Link>
            </Button>
          
          </div>
        </CardContent>
      </Card>

      <Card className="border-0 shadow-none bg-white/50 dark:bg-black/50 backdrop-blur-sm">
        <CardHeader className="pb-8">
          <div className="space-y-2">
            <span className="text-blue-600 dark:text-blue-500 text-sm font-medium">
              GETTING STARTED
            </span>
            <div className="space-y-2">
              <CardTitle className="text-3xl font-normal">
                Explore next steps
              </CardTitle>
              <p className="text-base text-gray-600 dark:text-gray-400">
                Get the most out of WaveQuery with these resources
              </p>
            </div>
          </div>
        </CardHeader>

        <CardContent className="space-y-4">
          {nextSteps.map((item, index) => (
            <Link
              key={index}
              to={item.href}
              className={`
                block relative overflow-hidden rounded-xl p-6 transition-all duration-300
                hover:shadow-lg hover:scale-[1.02] active:scale-[0.99]
                border border-gray-100 dark:border-gray-800
                bg-gradient-to-br ${item.bgClass}
              `}
            >
              <div className="absolute right-0 bottom-0 opacity-[0.07] transform translate-x-1/4 translate-y-1/4">
                <item.icon className="w-32 h-32" />
              </div>

              <div className="relative z-10 flex items-center justify-between">
                <div className="space-y-1">
                  <div className="flex items-center gap-3">
                    <div className="p-2 rounded-lg bg-white dark:bg-gray-800">
                      <item.icon className="w-5 h-5 text-blue-600 dark:text-blue-400" />
                    </div>
                    <h3 className="font-medium text-gray-900 dark:text-gray-100">
                      {item.text}
                    </h3>
                  </div>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    {item.description}
                  </p>
                </div>
                <ArrowRight className="w-5 h-5 text-gray-400" />
              </div>
            </Link>
          ))}
        </CardContent>

        <CardFooter className="pt-6 border-t border-gray-100 dark:border-gray-800">
          <div className="w-full flex items-center justify-between">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Need help getting started?
            </p>
            <Button
              variant="link"
              asChild
              className="text-blue-600 dark:text-blue-400"
            >
              <Link to="/help">Contact Support</Link>
            </Button>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default CompletedSetup;
