import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AlertCircle, Database, ArrowRight, FolderKanban } from "lucide-react";
import { FaGoogle } from "react-icons/fa";

import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
} from "../components/Common/Card";
import { Button } from "../components/Common/Button";
import { Input } from "../components/Common/Input";
import { Label } from "../components/Common/Label";

import { useSetup, SetupStep } from "../context/SetupContext";
import { useTeam } from "../context/TeamContext";
import { setupService } from "../services/setupService";

const DatasetInfo: React.FC = () => {
  const { teamId, updateWarehouseDetails } = useTeam();
  const [formData, setFormData] = useState({
    projectId: "",
    datasetId: ""
  });
  const [error, setError] = useState("");
  
  const navigate = useNavigate();
  const { currentStep, updateStep, isLoading } = useSetup();

  useEffect(() => {
    if (currentStep !== "dataset_info") {
      navigate(`/setup/${currentStep}`);
    }
  }, [currentStep, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    try {
      const response = await setupService.submitDatasetInfo(teamId as string, formData);
      updateWarehouseDetails({
        projectId: response.projectId,
        datasetId: response.datasetId,
      });

      await updateStep(response.nextStep as SetupStep, {
        projectId: response.projectId,
        datasetId: response.datasetId,
      });
    } catch (err) {
      setError("Failed to verify dataset information. Please check your Project ID and Dataset ID.");
    }
  };

  return (
    <div className="max-w-2xl mx-auto">
      <Card className="border-0 shadow-none bg-white/50 dark:bg-black/50 backdrop-blur-sm">
        <CardHeader className="space-y-6 pb-8">
          <div className="space-y-2">
            <span className="text-blue-600 dark:text-blue-500 text-sm font-medium">
              BIGQUERY SETUP
            </span>
            <div className="space-y-2">
              <h1 className="text-3xl font-normal text-gray-900 dark:text-gray-100">
                Configure BigQuery Connection
              </h1>
              <p className="text-base text-gray-600 dark:text-gray-400">
                Enter your BigQuery project and dataset details to start analyzing your data
              </p>
            </div>
          </div>
        </CardHeader>

        <CardContent>
          <div className="mb-8">
            <div className="p-4 rounded-lg bg-gradient-to-br from-blue-50 via-indigo-50 to-blue-50 dark:from-blue-900/20 dark:via-indigo-900/20 dark:to-blue-900/20 border border-blue-100 dark:border-blue-900/50">
              <div className="flex items-center gap-3 mb-3">
                <div className="p-2 rounded-md bg-white dark:bg-gray-800">
                  <FaGoogle className="w-5 h-5 text-blue-600 dark:text-blue-400" />
                </div>
                <p className="font-medium text-blue-900 dark:text-blue-100">
                  Google Account Connected
                </p>
              </div>
              <p className="text-sm text-blue-700 dark:text-blue-300">
                Your Google account has been successfully authenticated. Now, let's set up your BigQuery connection.
              </p>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
              <div className="space-y-2">
                <Label 
                  htmlFor="projectId"
                  className="flex items-center gap-2 text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  <FolderKanban className="w-4 h-4 text-gray-500" />
                  Project ID
                </Label>
                <Input
                  id="projectId"
                  value={formData.projectId}
                  onChange={(e) => setFormData({ ...formData, projectId: e.target.value })}
                  placeholder="my-project-id"
                  className="h-11"
                  required
                />
                <p className="text-xs text-gray-500">
                  Find this in your Google Cloud Console under Project Info
                </p>
              </div>

              <div className="space-y-2">
                <Label 
                  htmlFor="datasetId"
                  className="flex items-center gap-2 text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  <Database className="w-4 h-4 text-gray-500" />
                  Dataset ID
                </Label>
                <Input
                  id="datasetId"
                  value={formData.datasetId}
                  onChange={(e) => setFormData({ ...formData, datasetId: e.target.value })}
                  placeholder="my_dataset"
                  className="h-11"
                  required
                />
                <p className="text-xs text-gray-500">
                  The name of your BigQuery dataset containing the tables you want to analyze
                </p>
              </div>
            </div>

            {error && (
              <div className="p-3 rounded-lg bg-red-50/50 dark:bg-red-900/10 border border-red-100 dark:border-red-900/50">
                <div className="flex gap-3">
                  <AlertCircle className="w-5 h-5 text-red-600 dark:text-red-400 flex-shrink-0" />
                  <p className="text-sm text-red-600 dark:text-red-400">{error}</p>
                </div>
              </div>
            )}

            <Button
              type="submit"
              disabled={isLoading}
              className="w-full h-11 bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-600 
                text-white transition-all font-normal relative overflow-hidden group"
            >
              <span className="relative z-10 flex items-center justify-center gap-2">
                {isLoading ? (
                  <>
                    <div className="w-4 h-4 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                    Verifying...
                  </>
                ) : (
                  <>
                    Connect Dataset
                    <ArrowRight className="w-4 h-4 group-hover:translate-x-0.5 transition-transform" />
                  </>
                )}
              </span>
            </Button>
          </form>
        </CardContent>

        <CardFooter className="pt-6 border-t border-gray-100 dark:border-gray-800">
          <div className="flex items-center justify-center gap-2 text-xs text-gray-500">
            <span className="flex h-2 w-2 relative">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-2 w-2 bg-blue-500"></span>
            </span>
            Your data stays secure in BigQuery
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default DatasetInfo;