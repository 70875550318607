import Cookies from "js-cookie";

const TokenService = {
  clearTokens: () => {
    Cookies.remove("accessToken", {
      path: "/",
      sameSite: "none",
      secure: true,
    });
    Cookies.remove("refreshToken", {
      path: "/",
      sameSite: "none",
      secure: true,
    });
  }
};

export { TokenService };
