import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Database,
  Network,
  Table,
  CircleEqual,
  Boxes,
  Activity,
} from "lucide-react";
import { Dialog, DialogContent } from "@/components/Common/Dialog";

const steps = [
  {
    id: "connecting",
    label: "Connecting to database",
    icon: Database,
    duration: 1000,
  },
  {
    id: "relationships",
    label: "Analyzing relationships",
    icon: Network,
    duration: 1000,
  },
  {
    id: "optimization",
    label: "Optimizing layout",
    icon: Boxes,
    duration: 100,
  },
];

const SchemaLoadingDialog = ({ isOpen }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => prev + 100);
    }, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timeouts = steps.map((step, index) => {
      return setTimeout(
        () => {
          setCurrentStep(index);
        },
        steps.slice(0, index).reduce((acc, s) => acc + s.duration, 0)
      );
    });

    return () => timeouts.forEach(clearTimeout);
  }, []);

  return (
    <Dialog open={isOpen}>
      <DialogContent className="sm:max-w-[425px]" showClose={false}>
        <div className="p-4 space-y-6">
          {/* Main Icon Animation */}
          <div className="relative flex justify-center py-4">
            <motion.div
              animate={{
                scale: [1, 1.1, 1],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut",
              }}
              className="text-blue-500"
            >
              <Activity size={48} />
            </motion.div>
          </div>

          {/* Progress Bar */}
          <div className="h-1.5 bg-gray-100 dark:bg-gray-800 rounded-full overflow-hidden">
            <motion.div
              className="h-full bg-blue-500"
              initial={{ width: "0%" }}
              animate={{ width: "100%" }}
              transition={{ duration: 6 }}
            />
          </div>

          {/* Steps */}
          <div className="space-y-3">
            {steps.map((step, index) => {
              const Icon = step.icon;
              const isActive = index === currentStep;
              const isCompleted = index < currentStep;

              return (
                <motion.div
                  key={step.id}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.15 }}
                  className={`flex items-center gap-3 p-3 rounded-lg transition-colors ${
                    isActive ? "bg-blue-50 dark:bg-blue-900/20" : ""
                  }`}
                >
                  <div
                    className={`w-10 h-10 rounded-lg flex items-center justify-center ${
                      isCompleted
                        ? "bg-green-500"
                        : isActive
                        ? "bg-blue-500"
                        : "bg-gray-100 dark:bg-gray-800"
                    }`}
                  >
                    <Icon
                      className={`w-5 h-5 ${
                        isCompleted || isActive ? "text-white" : "text-gray-400"
                      }`}
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center justify-between gap-2">
                      <span
                        className={`text-sm font-medium truncate ${
                          isActive
                            ? "text-blue-600 dark:text-blue-400"
                            : isCompleted
                            ? "text-green-600 dark:text-green-400"
                            : "text-gray-600 dark:text-gray-400"
                        }`}
                      >
                        {step.label}
                      </span>
                      {isActive && (
                        <span className="text-xs tabular-nums text-gray-500">
                          {(timer / 1000).toFixed(1)}s
                        </span>
                      )}
                    </div>
                    {isActive && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="flex space-x-1 mt-1.5"
                      >
                        <div
                          className="w-1.5 h-1.5 bg-blue-500 rounded-full animate-bounce"
                          style={{ animationDelay: "0ms" }}
                        />
                        <div
                          className="w-1.5 h-1.5 bg-blue-500 rounded-full animate-bounce"
                          style={{ animationDelay: "150ms" }}
                        />
                        <div
                          className="w-1.5 h-1.5 bg-blue-500 rounded-full animate-bounce"
                          style={{ animationDelay: "300ms" }}
                        />
                      </motion.div>
                    )}
                  </div>
                </motion.div>
              );
            })}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SchemaLoadingDialog;
