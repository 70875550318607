// import React, { FC, useState, useCallback, useEffect } from "react";

// import { useNavigate } from "react-router-dom";

// import { AnalyzedTableData, Relationship } from "../../state/types/graphTypes";
// import { useFetchTables } from "../../hooks/useFetchTables";

// import TableOverview from "./TableOverview";
// import FinalReviewStep from "./FinalReviewStep";
// import ClusterConfirmation from "./Clustering/ClusterConfirmation";
// import RelationshipMappingStep from "./RelationshipMappingStep";

// import { setupService } from "../../services/setupService";
// import { useTeam } from "../../context/TeamContext";
// import DataSourceManager from "../Dashboard/DataSourceManager";
// // import { SetupStep, useSetup } from "../../context/SetupContext";

// // import TableDescriptionStep from "./TableDescriptionStep";

// enum WizardStep {
//   Overview,
//   ClusterConfirmation,
//   Relationships,
//   Review,
//   // Descriptions,
// }

// const DefineData: FC = () => {
//   const { teamId, warehouseDetails } = useTeam();

//   const { data, isLoading, error } = useFetchTables(
//     teamId,
//     warehouseDetails?.datasetId
//   );

//   // const { currentStep: RouteStep, updateStep } = useSetup();
//   const [currentStep, setCurrentStep] = useState<WizardStep>(
//     WizardStep.Overview
//   );
//   // const navigate = useNavigate();
//   const [tables, setTables] = useState<AnalyzedTableData[]>([]);
//   const [clusters, setClusters] = useState<{ [key: string]: string[] }>({});
//   const [acceptedRelationships, setAcceptedRelationships] = useState<
//     Relationship[]
//   >([]);

//   // useEffect(() => {
//   //   if (RouteStep !== "describe_data") {
//   //     navigate(`/setup/${currentStep}`);
//   //   }
//   // }, [currentStep, navigate]);

//   const handleStartWizard = useCallback(() => {
//     setTables(data?.tables || []);
//     setClusters(data?.clusters || {});
//     setCurrentStep(WizardStep.ClusterConfirmation);
//   }, [data]);

//   const handleUpdateDescription = useCallback(
//     (tableId: string, description: string) => {
//       setTables((prevTables) =>
//         prevTables.map((table) =>
//           table.id === tableId ? { ...table, description } : table
//         )
//       );
//     },
//     []
//   );

//   const handleConfirmClusters = useCallback(
//     (confirmedClusters: { [key: string]: string[] }) => {
//       setClusters(confirmedClusters);
//       setCurrentStep(WizardStep.Relationships);
//     },
//     []
//   );

//   const handleAcceptRelationship = useCallback(
//     (acceptedRelationships: Relationship[]) => {
//       setAcceptedRelationships(acceptedRelationships);
//     },
//     []
//   );

//   const handleSubmit = useCallback(async () => {
//     console.log(
//       "Submitting data schemas",
//       teamId,
//       warehouseDetails,
//       tables,
//       acceptedRelationships
//     );
//     try {
//       const response = await setupService.submitDataSchemas(
//         teamId,
//         warehouseDetails?.datasetId,
//         {
//           tables,
//           relationsips: acceptedRelationships,
//         }
//       );
//       // await updateStep(response.nextStep as SetupStep, {
//       //   projectId: warehouseDetails?.projectId,
//       //   datasetId: warehouseDetails?.datasetId,
//       // });
//     } catch (error) {
//       console.error("Error saving data:", error);
//       alert("An error occurred while saving. Please try again.");
//     }
//   }, [teamId, tables, acceptedRelationships]); // updateStep

//   const handleBack = useCallback(() => {
//     setCurrentStep((prevStep) => {
//       switch (prevStep) {
//         case WizardStep.ClusterConfirmation:
//           return WizardStep.Overview;
//         case WizardStep.Relationships:
//           return WizardStep.ClusterConfirmation;
//         case WizardStep.Review:
//           return WizardStep.Relationships;
//         default:
//           return prevStep;
//       }
//     });
//   }, []);

//   if (isLoading) return <div>Preparing your data...</div>;
//   if (error) return <div>Error: {error.message}</div>;

//   switch (currentStep) {
//     case WizardStep.Overview:
//       return (
//         <div className="flex flex-column">
//           <DataSourceManager />
//           <TableOverview
//             tables={data?.tables || []}
//             onStartWizard={handleStartWizard}
//           />
//         </div>
//       );
//     // TODO: This needs to get the tables descriptions
//     //
//     // case WizardStep.Descriptions:
//     //   return (
//     //     <TableDescriptionStep
//     //       tables={tables}
//     //       onUpdateDescription={handleUpdateDescription}
//     //       onNext={() => setCurrentStep(WizardStep.Relationships)}
//     //       onBack={handleBack}
//     //     />
//     //   );
//     case WizardStep.ClusterConfirmation:
//       return (
//         <ClusterConfirmation
//           initialClusters={clusters}
//           onConfirm={handleConfirmClusters}
//           onBack={handleBack}
//         />
//       );
//     case WizardStep.Relationships:
//       return (
//         <RelationshipMappingStep
//           tables={tables}
//           relationships={data?.relationships || []}
//           onAcceptRelationship={handleAcceptRelationship}
//           onNext={() => setCurrentStep(WizardStep.Review)}
//           onBack={handleBack}
//         />
//       );
//     case WizardStep.Review:
//       return (
//         <FinalReviewStep
//           tables={tables}
//           relationships={acceptedRelationships}
//           onSubmit={handleSubmit}
//           onBack={handleBack}
//         />
//       );
//     default:
//       return null;
//   }
// };

// export default DefineData;


import React, { FC, useState, useCallback, useEffect } from "react";
import { Database, TableProperties, CheckCircle2, ChevronRight } from "lucide-react";
import { cn } from "@/components/utils";
import { Card } from "@/components/Common/Card";
import { Separator } from "@/components/Common/Separator";
import { Alert, AlertTitle, AlertDescription } from "@/components/Common/Alert";
import { AnalyzedTableData, Relationship } from "../../state/types/graphTypes";
import { useFetchTables } from "../../hooks/useFetchTables";
import { setupService } from "../../services/setupService";
import { useTeam } from "../../context/TeamContext";

import TableOverview from "./TableOverview";
import FinalReviewStep from "./FinalReviewStep";
import ClusterConfirmation from "./Clustering/ClusterConfirmation";
import RelationshipMappingStep from "./RelationshipMappingStep";

enum WizardStep {
  Overview,
  ClusterConfirmation,
  Relationships,
  Review,
}

const StepIcon = {
  [WizardStep.Overview]: TableProperties,
  [WizardStep.ClusterConfirmation]: Database,
  [WizardStep.Relationships]: Database,
  [WizardStep.Review]: CheckCircle2,
};

const StepTitle = {
  [WizardStep.Overview]: "Table Overview",
  [WizardStep.ClusterConfirmation]: "Cluster Confirmation",
  [WizardStep.Relationships]: "Relationships",
  [WizardStep.Review]: "Final Review",
};

const DefineData: FC = () => {
  const { teamId, warehouseDetails } = useTeam();
  const { data, isLoading, error } = useFetchTables(teamId, warehouseDetails?.datasetId);
  
  const [currentStep, setCurrentStep] = useState<WizardStep>(WizardStep.Overview);
  const [tables, setTables] = useState<AnalyzedTableData[]>([]);
  const [clusters, setClusters] = useState<{ [key: string]: string[] }>({});
  const [acceptedRelationships, setAcceptedRelationships] = useState<Relationship[]>([]);

  const handleStartWizard = useCallback(() => {
    setTables(data?.tables || []);
    setClusters(data?.clusters || {});
    setCurrentStep(WizardStep.ClusterConfirmation);
  }, [data]);

  const handleUpdateDescription = useCallback((tableId: string, description: string) => {
    setTables((prevTables) =>
      prevTables.map((table) =>
        table.id === tableId ? { ...table, description } : table
      )
    );
  }, []);

  const handleConfirmClusters = useCallback((confirmedClusters: { [key: string]: string[] }) => {
    setClusters(confirmedClusters);
    setCurrentStep(WizardStep.Relationships);
  }, []);

  const handleAcceptRelationship = useCallback((acceptedRelationships: Relationship[]) => {
    setAcceptedRelationships(acceptedRelationships);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      const response = await setupService.submitDataSchemas(
        teamId,
        warehouseDetails?.datasetId,
        {
          tables,
          relationships: acceptedRelationships,
        }
      );
      // Handle successful submission
      console.log("Data schemas submitted successfully", response);
    } catch (error) {
      console.error("Error saving data:", error);
      // TODO: Replace with proper error handling
      alert("An error occurred while saving. Please try again.");
    }
  }, [teamId, warehouseDetails?.datasetId, tables, acceptedRelationships]);

  const handleBack = useCallback(() => {
    setCurrentStep((prevStep) => {
      switch (prevStep) {
        case WizardStep.ClusterConfirmation:
          return WizardStep.Overview;
        case WizardStep.Relationships:
          return WizardStep.ClusterConfirmation;
        case WizardStep.Review:
          return WizardStep.Relationships;
        default:
          return prevStep;
      }
    });
  }, []);

  const renderStepContent = () => {
    switch (currentStep) {
      case WizardStep.Overview:
        return (
          <div className="flex flex-col gap-6 h-full">
            <TableOverview
              tables={data?.tables || []}
              onStartWizard={handleStartWizard}
            />
          </div>
        );
      case WizardStep.ClusterConfirmation:
        return (
          <ClusterConfirmation
            initialClusters={clusters}
            onConfirm={handleConfirmClusters}
            onBack={handleBack}
          />
        );
      case WizardStep.Relationships:
        return (
          <RelationshipMappingStep
            tables={tables}
            relationships={data?.relationships || []}
            onAcceptRelationship={handleAcceptRelationship}
            onNext={() => setCurrentStep(WizardStep.Review)}
            onBack={handleBack}
          />
        );
      case WizardStep.Review:
        return (
          <FinalReviewStep
            tables={tables}
            relationships={acceptedRelationships}
            onSubmit={handleSubmit}
            onBack={handleBack}
          />
        );
      default:
        return null;
    }
  };

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="flex gap-6 h-full pt-6">
      {/* Sidebar */}
      <div className="w-64 flex-shrink-0">
        <div className="flex items-center gap-2 px-4 mb-4">
          <Database className="w-5 h-5" />
          <h2 className="font-semibold text-lg">Datasets</h2>
        </div>
        <Separator className="mb-4" />
        
        {/* Current Dataset Info */}
        <div className="px-4 mb-6">
          <div className="text-sm font-medium text-muted-foreground mb-2">
            Current Dataset
          </div>
          <div className="p-3 rounded-lg bg-secondary/20">
            <div className="font-medium truncate">
              {warehouseDetails?.datasetId || 'No dataset selected'}
            </div>
            <div className="text-xs text-muted-foreground mt-1">
              {warehouseDetails?.projectId || 'No project'}
            </div>
          </div>
        </div>

        <Separator className="mb-4" />

        {/* Steps Navigation */}
        <nav className="space-y-1 px-2">
          {Object.values(WizardStep).map((step) => {
            if (typeof step === 'number') {
              const Icon = StepIcon[step];
              return (
                <button
                  key={step}
                  onClick={() => !isLoading && setCurrentStep(step)}
                  disabled={isLoading}
                  className={cn(
                    "w-full flex items-center gap-3 px-4 py-2 text-sm rounded-lg transition-colors",
                    "hover:bg-secondary/80",
                    "focus:outline-none focus-visible:ring-2 focus-visible:ring-ring",
                    currentStep === step ? 
                      "bg-secondary text-primary font-medium" : 
                      "text-muted-foreground",
                    isLoading && "opacity-50 cursor-not-allowed"
                  )}
                >
                  <Icon className="w-4 h-4" />
                  <span className="flex-1 text-left">{StepTitle[step]}</span>
                  {currentStep === step && (
                    <ChevronRight className="w-4 h-4" />
                  )}
                </button>
              );
            }
            return null;
          })}
        </nav>
      </div>

      {/* Main Content */}
      <div className="flex-1 min-w-0">
        {isLoading ? (
          <Card className="p-8">
            <div className="flex items-center justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary" />
              <span className="ml-3 text-muted-foreground">
                Preparing your data...
              </span>
            </div>
          </Card>
        ) : (
          renderStepContent()
        )}
      </div>
    </div>
  );
};

export default DefineData;