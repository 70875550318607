import { DatabaseConnection } from "@/context/PlaygroundContext";
import { API_URL, axiosInstance } from "../config";

interface SchemaAnalysisResult {
  tables: Array<{
    name: string;
    columns: Array<{
      name: string;
      type: string;
      isPrimary: boolean;
      isForeign: boolean;
      isNullable: boolean;
      defaultValue?: string;
    }>;
  }>;
  relationships: Array<{
    sourceTable: string;
    targetTable: string;
    sourceColumn: string;
    targetColumn: string;
  }>;
  junctionTables: string[];
  views: Array<{
    name: string;
    definition: string;
  }>;
}

interface ChatMessage {
  role: "user" | "assistant";
  content: string;
}

interface ChatResponse {
  type: "query" | "message";
  content: string;
  metadata?: {
    query?: string;
    explanation?: string[];
    relatedTables?: string[];
  };
}

export const playgroundService = {
  analyzeSchema: async (config: DatabaseConnection) => {
    try {
      const response = await axiosInstance.post<SchemaAnalysisResult>(
        `${API_URL}/api/v0/playground/analyze`,
        config
      );

      return response.data;
    } catch (error) {
      // Handle specific error cases that might occur during schema analysis
      if (error.response?.status === 401) {
        throw new Error(
          "Authentication failed. Please check your database credentials."
        );
      }

      if (error.response?.status === 403) {
        throw new Error("You don't have permission to access this database.");
      }

      if (error.response?.status === 429) {
        throw new Error("Too many requests. Please try again later.");
      }

      // Log the error for debugging purposes
      console.error("Error analyzing database schema:", error);

      // Throw a more user-friendly error
      throw new Error(
        error.response?.data?.message ||
          "Failed to analyze database schema. Please try again or contact support."
      );
    }
  },
  validateConnection: async (config: DatabaseConnection) => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/api/v0/playground/validate-connection`,
        config
      );
      return response.data;
    } catch (error) {
      console.error("Error validating connection:", error);
      throw error;
    }
  },
  getSampleDatabases: async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/api/v0/playground/sample-databases`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching sample databases:", error);
      throw error;
    }
  },
  chat: async (
    config: DatabaseConnection,
    message: string,
    history: ChatMessage[]
  ) => {
    try {
      const response = await axiosInstance.post<ChatResponse>(
        `${API_URL}/api/v0/playground/chat`,
        {
          connection: config,
          message,
          history,
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error in playground chat:", error);
      throw new Error(
        error.response?.data?.message ||
          "Failed to process chat message. Please try again."
      );
    }
  },
  // chat: async (payload: {
  //   message: string;
  //   teamId: string;
  //   datasetId: string;
  //   conversationHistory: Message[];
  //   chatContext: ChatContext;
  // }) => {
  //   try {
  //     const response = await axiosInstance.post(`${API_URL}/api/v0/chat`, {
  //       ...payload,
  //     });
  //     return response.data;
  //   } catch (error) {
  //     const link = error?.response?.data?.authUrl;
  //     if (link) {
  //       window.location.href = link;
  //     }
  //     console.error("Error generating query:", error);
  //     throw error;
  //   }
  // },
};
