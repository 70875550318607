import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/Common/Dialog";
import { Button } from "@/components/Common/Button";
import { Badge } from "@/components/Common/Badge";
import { Database, ArrowRight, Bot, Boxes, Settings, Lock } from "lucide-react";

interface SetupDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const SetupDialog: React.FC<SetupDialogProps> = ({ isOpen, onClose }) => {
  const steps = [
    {
      icon: <Database className="w-6 h-6" />,
      title: "Connect Your data sources",
      description: "Link your different data sources",
      status: "",
    },
    {
      icon: <Bot className="w-6 h-6" />,
      title: "Select AI Model",
      description: "Bring your own AI model",
      status: "Coming Soon",
    },
  ];

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-xl">
        <DialogHeader>
          <DialogTitle>Connect New Data Source</DialogTitle>
        </DialogHeader>

        <div className="relative">
          {/* Setup Steps */}
          <div className="space-y-4">
            {steps.map((step, index) => (
              <div
                key={step.title}
                className="flex items-start gap-4 p-4 rounded-lg border border-gray-200 dark:border-gray-700"
              >
                <div className="flex-shrink-0 w-10 h-10 flex items-center justify-center rounded-full bg-blue-100 dark:bg-blue-900 text-blue-600">
                  {step.icon}
                </div>
                <div className="flex-1 min-w-0">
                  <div className="flex items-center gap-2">
                    <h3 className="font-medium">{step.title}</h3>
                    <Badge variant="outline" className="text-xs">
                      {step.status}
                    </Badge>
                  </div>
                  <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                    {step.description}
                  </p>
                </div>
                <ArrowRight className="w-5 h-5 text-gray-400" />
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-end gap-3 mt-6">
          <Button onClick={onClose}>
            Cancel
          </Button>
          <Button  variant="outline" onClick={onClose}>Got it</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SetupDialog;
