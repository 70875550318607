import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AlertCircle } from "lucide-react";

import { QueryProvider } from "./context/QueryContext";
import { SetupProvider } from "./context/SetupContext";
import { AuthProvider, useAuth } from "./context/AuthContext";
import { TeamProvider } from "./context/TeamContext";

import SetupWizard from "./pages/SetupWizard";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import InvitationPage from "./pages/InvitationPage";
import Playground from "./pages/Playground";

import { authService } from "@/services/authService";
import Header from "./components/Layout/Header";

import "./styles/globals.css";

import { UserAuthStatus } from "@/context/constants";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  Button,
  Toaster,
} from "@/components/Common";

// Protected route component
const ProtectedRoute: React.FC<{
  children: React.ReactNode;
  requiredStep?: string;
}> = ({ children, requiredStep }) => {
  const { status, isLoading, error } = useAuth();
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      await authService.refreshToken();
      // setStatus(UserAuthStatus.Completed);
      window.location.href = "/dashboard";
      setIsRefreshing(false);
    } catch (refreshError) {
      console.error("Token refresh failed:", refreshError);
      setIsRefreshing(false);
      // If refresh fails, redirect to login
      window.location.href = "/login";
    }
  };

  if (isLoading && !status && !error)
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );

  // TODO: We got to aligned on the flow of BE mapped FE errors
  //
  // Error
  if (status === UserAuthStatus.Unauthorized && !isLoading && !!error) {
    return (
      <Dialog open={!!error}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Authentication Error</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <div className="flex items-center text-red-500 mb-4">
              <AlertCircle className="mr-2 h-5 w-5" />
              <p className="text-sm font-medium">{error.message}</p>
            </div>
            <p className="text-sm text-gray-500">
              Please try logging in again to regain access to your account.
            </p>
          </div>
          <DialogFooter>
            <Button onClick={handleRefresh} disabled={isRefreshing}>
              {isRefreshing ? "Refreshing..." : "Refresh Session"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  if (
    status &&
    !isLoading &&
    requiredStep &&
    status !== requiredStep &&
    status !== UserAuthStatus.Completed
  ) {
    return <Navigate to={`/setup/${status}`} replace />;
  }

  return <>{children}</>;
};

const AppProviders: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Router>
      <TeamProvider>
        <QueryProvider>
          <AuthProvider>{children}</AuthProvider>
        </QueryProvider>
      </TeamProvider>
    </Router>
  );
};

const App: React.FC = () => {
  return (
    <AppProviders>
      <div className="h-full flex flex-col bg-gradient-to-br from-white via-blue-50 to-blue-100 dark:from-gray-900 dark:via-gray-800 dark:to-blue-900 text-gray-900 dark:text-gray-100">
        <Header />
        <main className="flex-grow h-full overflow-hidden">
          <Routes>
            <Route path="/playground" element={<Playground />} />
            {/* Setup Wizard Routes */}
            <Route
              path="/setup/*"
              element={
                <SetupProvider>
                  <SetupWizard />
                </SetupProvider>
              }
            />
            {/* Dashboard */}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute requiredStep="completed">
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route path="/invite/:token" element={<InvitationPage />} />

            {/* Redirect root to setup or dashboard based on setup completion */}
            <Route
              path="/*"
              element={
                <ProtectedRoute requiredStep="completed">
                  <Navigate to="/dashboard" replace />
                </ProtectedRoute>
              }
            />
            <Route path="/login" element={<Login />} />
          </Routes>
        </main>
      </div>
    </AppProviders>
  );
};

export default App;
