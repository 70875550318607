import { Edge } from "reactflow";

export const extractTablesFromSQL = (sql: string): string[] => {
    if (!sql) return [];
    const patterns = [
      /\b\w+\.(\w+)\b/g,                   // matches: schema.table
      /\bFROM\s+(\w+)\b/gi,                // matches: FROM table
      /\bJOIN\s+(\w+)\b/gi,                // matches: JOIN table
      /\bUPDATE\s+(\w+)\b/gi,              // matches: UPDATE table
      /\bINTO\s+(\w+)\b/gi,                // matches: INSERT INTO table
    ];
    const tables = new Set<string>();   
    patterns.forEach(pattern => {
      const matches = [...sql.matchAll(pattern)];
      matches.forEach(match => {
        const tableName = match[1].split('.').pop();
        if (tableName) {
          tables.add(tableName.toLowerCase());
        }
      });
    });
    return Array.from(tables);
  };
  
  export const findRelatedEdges = (
    tableNames: string[],
    edges: Edge[]
  ): string[] => {
    return edges
      .filter(edge => 
        tableNames.includes(edge.source) || 
        tableNames.includes(edge.target)
      )
      .map(edge => edge.id);
  };