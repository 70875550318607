import { axiosInstance } from "../config";

interface UsageInformation {
  useApi: boolean;
  runQueries: boolean;
  apiUsage: boolean;
  messenger: string | null;
}

export const setupService = {
  registerOrg: async (orgData: {
    name: string;
    teamId: string;
    adminEmail: string;
    password: string;
  }) => {
    try {
      const response = await axiosInstance.post(
        `/api/v0/setup/register`,
        orgData
      );
      return {
        headers: response.headers,
        data: response.data as {
          nextStep: string;
          userId: string;
        },
      };
    } catch (error) {
      console.error("Error registering organization:", error);
      throw error;
    }
  },
  initiateGoogleAuth: async (teamId: string, userId: string) => {
    try {
      const response = await axiosInstance.get(
        `/api/v0/auth/google/setup?teamId=${teamId}&userId=${userId}`
      );
      return response.data.url;
    } catch (error) {
      console.error("Error initiating Google auth:", error);
      throw error;
    }
  },
  submitDatasetInfo: async (
    teamId: string,
    datasetInfo: {
      projectId: string;
      datasetId: string;
    }
  ) => {
    try {
      const response = await axiosInstance.post(
        `/api/v0/setup/${teamId}/dataset/info`,
        datasetInfo
      );
      return response.data;
    } catch (error) {
      console.error("Error submitting dataset info:", error);
      throw error;
    }
  },
  submitDataSchemas: async (
    teamId: string,
    datasetId: string,
    datasetInfo: {
      tables: unknown;
      relationships: unknown;
    }
  ) => {
    try {
      const response = await axiosInstance.post(
        `/api/v0/setup/${teamId}/datasets/${datasetId}/relationships/confirm`,
        datasetInfo
      );
      return response.data;
    } catch (error) {
      console.error("Error submitting data description:", error);
      throw error;
    }
  },
  submitModelSelection: async (
    teamId: string,
    data: { modelType: string; apiKey: string }
  ) => {
    try {
      const response = await axiosInstance.post(
        `/api/v0/setup/${teamId}/model/selection`,
        { teamId, ...data }
      );
      return response.data;
    } catch (error) {
      console.error("Error submitting model selection:", error);
      throw error;
    }
  },
  getSetupProgress: async (teamId: string | null) => {
    try {
      const response = await axiosInstance.get(
        `/api/v0/setup/progress/${teamId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error getting setup progress:", error);
      throw error;
    }
  },
  submitUsageInformation: async (
    teamId: string,
    usageInfo: UsageInformation
  ) => {
    try {
      const response = await axiosInstance.post(
        `/api/v0/setup/${teamId}/usage`,
        usageInfo
      );
      return response.data;
    } catch (error) {
      console.error("Error submitting usage information:", error);
      throw error;
    }
  },
  updateSetupProgress: async (teamId: string, progress: string) => {
    try {
      const response = await axiosInstance.post(`/api/v0/setup/progress`, {
        teamId,
        progress,
      });
      return response.data;
    } catch (error) {
      console.error("Error updating setup progress:", error);
      throw error;
    }
  },
  getTablesDataset: async (teamId: string, datasetId: string) => {
    try {
      const response = await axiosInstance.get(
        `/api/v0/setup/${teamId}/datasets/${datasetId}/tables`
      );
      return response.data;
    } catch (error) {
      console.error("Error getting tables:", error);
      throw error;
    }
  },
  postDataDiscovery: async (
    teamId: string,
    warehouseType: string,
    datasetId: string
  ) => {
    try {
      const url =
        warehouseType === "postgres"
          ? `/api/v0/setup/${teamId}/datasets/${datasetId}/relationships/discovery/postgres`
          : `/api/v0/setup/${teamId}/datasets/${datasetId}/relationships/discovery/bigquery`;
      const response = await axiosInstance.post(url);
      return response.data;
    } catch (error) {
      console.error("Error getting tables:", error);
      throw error;
    }
  },
  updateTableDescription: async (
    teamId: string,
    datasetId: string,
    tableId: string,
    description: string
  ) => {
    try {
      const response = await axiosInstance.post(
        `/api/v0/setup/${teamId}/datasets/${datasetId}/tables/${tableId}/description`,
        { description }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating table description:", error);
      throw error;
    }
  },
  updateColumnDescription: async (
    teamId: string,
    datasetId: string,
    tableId: string,
    columnName: string,
    description: string
  ) => {
    try {
      const response = await axiosInstance.post(
        `/api/v0/setup/${teamId}/datasets/${datasetId}/tables/${tableId}/columns/${columnName}/description`,
        { description }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating column description:", error);
      throw error;
    }
  },
  connectWarehouse: async (
    teamId: string,
    data: {
      warehouseType: string;
      connectionDetails: {
        // Postgres fields
        host?: string;
        port?: number;
        database?: string;
        username?: string;
        password?: string;
        schema?: string;
        ssl?: boolean;
        // Supabase fields
        projectUrl?: string;
        apiKey?: string;
        dbPassword?: string;
        // Neon fields
        connectionString?: string;
      };
    }
  ) => {
    try {
      const response = await axiosInstance.post(
        `/api/v0/setup/${teamId}/connect`,
        data
      );
      return response.data;
    } catch (error) {
      console.error("Error connecting warehouse:", error);
      throw error;
    }
  },
  connectTestWarehouse: async (
    teamId: string,
    data: {
      databaseId: string
    }
  ) => {
    try {
      const response = await axiosInstance.post(
        `/api/v0/setup/${teamId}/connect/sample`,
        data
      );
      return response.data;
    } catch (error) {
      console.error("Error connecting test warehouse:", error);
      throw error;
    }
  }
};
