import React from "react";
import { motion } from "framer-motion";
import { Check } from "lucide-react";

import { useSetup } from "../context/SetupContext";

import { Card, CardContent } from "./Common/Card";

const SetupProgress: React.FC = () => {
  const { currentStep } = useSetup();

  const steps = [
    { name: "Register", step: "register" },
    { name: "Connect", step: "connect_warehouse" },
    { name: "Dataset", step: "dataset_info" },
    { name: "AI Model", step: "model_selection" },
    { name: "Complete", step: "completed" },
  ];
  const getCurrentStepIndex = () =>
    steps.findIndex((step) => step.step === currentStep);

  return (
    <Card className="mb-8 mx-auto max-w-4xl border-none shadow-none">
      <CardContent className="pt-6">
        <div className="relative">
          {/* Step indicators and labels */}
          <div className="flex justify-between">
            {steps.map((step, index) => {
              const isCompleted = index < getCurrentStepIndex();
              const isCurrent = step.step === currentStep;

              return (
                <div
                  key={step.name}
                  className="flex flex-col items-center relative"
                >
                  <motion.div
                    className={`w-12 h-12 rounded-full flex items-center justify-center z-10 shadow-md ${
                      isCompleted
                        ? "bg-slate-950"
                        : isCurrent
                        ? "bg-blue-600"
                        : "bg-gray-200"
                    }`}
                    initial={false}
                    animate={{
                      scale: isCurrent ? 1.2 : 1,
                    }}
                    transition={{ duration: 0.3 }}
                  >
                    {isCompleted && index !== getCurrentStepIndex() ? (
                      <Check className="w-6 h-6 text-white" />
                    ) : (
                      <span
                        className={`text-sm font-medium ${
                          isCurrent ? "text-white" : "text-gray-600"
                        }`}
                      >
                        {index + 1}
                      </span>
                    )}
                  </motion.div>
                  <span
                    className={`mt-2 text-xs font-medium text-center w-24 ${
                      isCurrent ? "text-blue-600" : "text-gray-500"
                    }`}
                  >
                    {step.name}
                  </span>
                </div>
              );
            })}
          </div>
          {/* Progress line */}
          <div
            className="absolute top-6 h-1 bg-gray-200 rounded"
            style={{
              left: "30px",
              right: "30px",
            }}
          >
            <motion.div
              className="h-full bg-slate-950 rounded origin-left"
              initial={{ scaleX: 0 }}
              animate={{ scaleX: getCurrentStepIndex() / (steps.length - 1) }}
              transition={{ duration: 0.5 }}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default SetupProgress;
