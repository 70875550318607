import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Sparkles,
  FileSearch,
  DatabaseZap,
  PlayCircle,
  LineChart,
  GitCompareArrows,
  MessagesSquare,
  ChevronRight,
  ChevronDown,
  Loader2,
  CheckCircle2,
  AlertCircle,
  Clock,
} from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/Common";

const stepConfig = {
  DECISION_MAKING: {
    label: "Analysis",
    description: "Analyzing your question and context",
    longDescription:
      "Processing your request to understand the requirements and context",
    icon: FileSearch,
    status: "pending",
    estimatedTime: "1-2 seconds",
    tips: ["Ensure your question is clear", "Provide relevant context"],
    bgClass:
      "bg-gradient-to-br from-purple-500/10 to-purple-600/10 dark:from-purple-900/20 dark:to-purple-800/20",
  },
  DECISION: {
    label: "Making decisions",
    description: "Planning the best approach",
    longDescription:
      "Determining the most efficient way to process your request",
    icon: Sparkles,
    estimatedTime: "1-5 seconds",
    tips: ["Complex queries may take longer", "Multiple steps might be needed"],
    bgClass:
      "bg-gradient-to-br from-blue-500/10 to-blue-600/10 dark:from-blue-900/20 dark:to-blue-800/20",
  },
  QUERY_GENERATION: {
    label: "Building query",
    description: "Crafting optimal database query",
    longDescription:
      "Generating an efficient database query based on your requirements",
    icon: DatabaseZap,
    estimatedTime: "1-5 seconds",
    tips: [
      "Queries are optimized automatically",
      "Complex joins may take longer",
    ],
    bgClass:
      "bg-gradient-to-br from-cyan-500/10 to-cyan-600/10 dark:from-cyan-900/20 dark:to-cyan-800/20",
  },
  QUERY_EXECUTION: {
    label: "Running query",
    description: "Processing data request",
    longDescription:
      "Executing the query and retrieving results from the database",
    icon: PlayCircle,
    estimatedTime: "1-5 seconds",
    tips: [
      "Large datasets may take longer",
      "Results are cached when possible",
    ],
    bgClass:
      "bg-gradient-to-br from-teal-500/10 to-teal-600/10 dark:from-teal-900/20 dark:to-teal-800/20",
  },
  VISUALIZATION: {
    label: "Visualizing data",
    description: "Creating visual representations",
    longDescription:
      "Generating charts and visualizations from the query results",
    icon: LineChart,
    estimatedTime: "1-5 seconds",
    tips: ["Charts are interactive", "Multiple view options available"],
    bgClass:
      "bg-gradient-to-br from-green-500/10 to-green-600/10 dark:from-green-900/20 dark:to-green-800/20",
  },
  COMPARISON: {
    label: "Analyzing patterns",
    description: "Finding insights in data",
    longDescription: "Comparing results and identifying meaningful patterns",
    icon: GitCompareArrows,
    estimatedTime: "1-5 seconds",
    tips: ["Pattern detection is automatic", "Insights are highlighted"],
    bgClass:
      "bg-gradient-to-br from-yellow-500/10 to-yellow-600/10 dark:from-yellow-900/20 dark:to-yellow-800/20",
  },
  CLARIFICATION: {
    label: "Ensuring accuracy",
    description: "Verifying results",
    longDescription: "Double-checking results and preparing final response",
    icon: MessagesSquare,
    estimatedTime: "1-5 seconds",
    tips: ["Results are validated", "Ask questions if needed"],
    bgClass:
      "bg-gradient-to-br from-orange-500/10 to-orange-600/10 dark:from-orange-900/20 dark:to-orange-800/20",
  },
};

const StepTimer = ({ startTime, estimatedSeconds }) => {
  const [elapsed, setElapsed] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setElapsed((Date.now() - startTime) / 1000);
    }, 100);

    return () => clearInterval(timer);
  }, [startTime]);

  return (
    <div className="flex items-center gap-2 mt-2">
      <Clock className="w-4 h-4 text-gray-400" />
      <span className="text-xs text-gray-400">
        {elapsed.toFixed(1)}s / ~{estimatedSeconds}s
      </span>
    </div>
  );
};

const Step = ({ step, isLoading, config, status, isActive, startTime, onClick }) => {
  const [elapsed, setElapsed] = useState(0);
  const estimatedSeconds = parseInt(config.estimatedTime.split("-")[1]);

  useEffect(() => {
    let timer;
    if (isActive && isLoading && startTime) {
      timer = setInterval(() => {
        setElapsed((Date.now() - startTime) / 1000);
      }, 100);
    }
    return () => clearInterval(timer);
  }, [isActive, isLoading, startTime]);

  return (
    <motion.div
      layout
      className={`
        relative overflow-hidden rounded-lg border
        ${config.bgClass}
        ${
          status === "completed"
            ? "border-green-500/30"
            : isActive
            ? "border-blue-500/30"
            : status === "error"
            ? "border-red-500/30"
            : "border-gray-500/30"
        }
        transition-all duration-300
        hover:scale-[1.02]
        cursor-pointer
        group
      `}
      onClick={onClick}
    >
      <div className={`p-2 ${!isActive ? 'py-1' : ''}`}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div
              className={`
              p-1.5 rounded-lg
              ${status === "error" ? "bg-red-500/20" : ""}
            `}
            >
              {status === "completed" ? (
                <CheckCircle2 className="w-4 h-4 text-green-500" />
              ) : status === "error" ? (
                <AlertCircle className="w-4 h-4 text-red-500" />
              ) : (
                <config.icon className="w-4 h-4" />
              )}
            </div>
            <h3
              className={`
              text-sm font-medium
              ${
                status === "completed"
                  ? "text-green-500"
                  : isActive
                  ? "text-blue-500"
                  : status === "error"
                  ? "text-red-500"
                  : "text-gray-400"
              }
            `}
            >
              {config.label}
            </h3>
          </div>
          {isActive ? (
            <ChevronDown className="w-4 h-4 transition-transform rotate-180" />
          ) : (
            <ChevronRight className="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity" />
          )}
        </div>

        <AnimatePresence>
          {isActive && isLoading && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              {startTime && (
                <div className="flex items-center gap-2 mt-2">
                  <Clock className="w-4 h-4 text-gray-400" />
                  <span className="text-xs text-gray-400">
                    {elapsed.toFixed(1)}s / ~{estimatedSeconds}s
                  </span>
                </div>
              )}

              <div className="mt-3 space-y-2">
                <p className="text-xs font-medium text-gray-400">Tips:</p>
                <ul className="text-xs text-gray-500 space-y-1">
                  {config.tips.map((tip, i) => (
                    <li key={i} className="flex items-center gap-2">
                      <div className="w-1 h-1 rounded-full bg-gray-400" />
                      {tip}
                    </li>
                  ))}
                </ul>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

const ProcessingSteps = ({ isLoading, currentStep, steps, onStepClick }) => {
  const [dialogStep, setDialogStep] = useState(null);
  const [stepStartTimes, setStepStartTimes] = useState(new Map());

  useEffect(() => {
    if (currentStep && !stepStartTimes.has(currentStep.step)) {
      setStepStartTimes((prev) => {
        const newMap = new Map(prev);
        newMap.set(currentStep.step, Date.now());
        return newMap;
      });
    }
  }, [currentStep]);

  const getStepStatus = (step) => {
    if (step.status === "completed") return "completed";
    if (currentStep?.step === step.step) return "active";
    if (step.status === "error") return "error";
    return "pending";
  };

  const handleStepClick = (step) => {
    const status = getStepStatus(step);
    if (!isLoading && (step.details || step.status === "completed")) {
      setDialogStep(step);
      onStepClick?.(step);
    }
  };

  return (
    <div className="relative max-w-4xl">
      <AnimatePresence>
        {isLoading && (
          <motion.div
            className="absolute -top-2 left-0 right-0 h-0.5 w-32"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="h-full bg-gradient-to-r from-transparent via-blue-500 to-transparent"
              animate={{
                x: ["0%", "100%"],
                scale: [0.4, 0.5, 0.4],
              }}
              transition={{
                duration: 1,
                ease: "easeInOut",
                repeat: Infinity,
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <motion.div
        layout
        className="grid grid-cols-4 md:grid-cols-3 lg:grid-cols-4 gap-3 py-0.5"
      >
        <AnimatePresence mode="wait">
          {steps.map((step) => {
            const config = stepConfig[step.step];
            const status = getStepStatus(step);
            const isActive = status === "active";
            const startTime = stepStartTimes.get(step.step);

            return (
              <Step
                key={step.step}
                step={step}
                config={config}
                isLoading={isLoading}
                status={status}
                isActive={isActive}
                startTime={startTime}
                onClick={() => handleStepClick(step)}
              />
            );
          })}
        </AnimatePresence>
      </motion.div>

      <Dialog open={!!dialogStep} onOpenChange={() => setDialogStep(null)}>
        <DialogContent className="sm:max-w-2xl">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2">
              {dialogStep &&
                (() => {
                  const config = stepConfig[dialogStep.step];
                  const Icon = config.icon;
                  return (
                    <>
                      <Icon className="w-5 h-5" />
                      {config.label}
                    </>
                  );
                })()}
            </DialogTitle>
          </DialogHeader>
          <div className="mt-4 space-y-4 w-full overflow-hidden">
            {dialogStep && (
              <>
                <div className="space-y-2">
                  <h4 className="text-sm font-medium">Description</h4>
                  <p className="text-sm text-gray-400">
                    {stepConfig[dialogStep.step].longDescription}
                  </p>
                </div>
                {dialogStep.details && (
                  <div className="space-y-2">
                    <h4 className="text-sm font-medium">Details</h4>
                    <pre className="p-4 rounded-lg w-full bg-gray-50 dark:bg-gray-800/50 text-sm overflow-x-scroll">
                      {JSON.stringify(dialogStep.details, null, 2)}
                    </pre>
                  </div>
                )}
                <div className="space-y-2">
                  <h4 className="text-sm font-medium">Tips</h4>
                  <ul className="space-y-2">
                    {stepConfig[dialogStep.step].tips.map((tip, index) => (
                      <li
                        key={index}
                        className="flex items-start gap-2 text-sm text-gray-400"
                      >
                        <div className="w-1 h-1 mt-2 rounded-full bg-gray-400" />
                        {tip}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="flex items-center gap-2 text-sm text-gray-400">
                  <Clock className="w-4 h-4" />
                  <span>
                    Estimated time: {stepConfig[dialogStep.step].estimatedTime}
                  </span>
                </div>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProcessingSteps;