import React, {
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useChat } from "./Chat/useChat";
import { useTheme } from "@/hooks/useTheme";
import MessageList from "./Chat/MessageList";
import InputArea from "./Chat/InputArea";
import QueryPanel from "./Chat/Query/QueryPanel.tsx";
import WelcomeScreen from "./Chat/Welcome";
import TablesPanel from "./Chat/TablesPanel";
import FileImportPanel from "./Chat/FileImportPanel.tsx";
import NoDataSourcePrompt from "./Chat/NoDataSourcePrompt.tsx";
import { TooltipProvider } from "@/components/Common/Tooltip";
import { useTeam } from "@/context/TeamContext.tsx";

interface ChatProps {
  teamId: string;
  userId: string;
  datasetId: string;
  hasDataSource: boolean;
  isCustomDataMode: boolean;
  onFileImportComplete: (fileDetails: any) => void;
  onQuestionClick: (question: string) => void;
}

const Chat = forwardRef<any, ChatProps>(
  (
    {
      teamId,
      userId,
      datasetId,
      hasDataSource,
      isCustomDataMode,
      setChatStarted,
      onFileImportComplete,
      onGenerateQuery,
    },
    ref
  ) => {
    const {
      conversations,
      currentConversation,
      input,
      handleQuickQuestion,
      isLoading,
      queryResult,
      generatedQuery,
      queryMetadata,
      explanations,
      setIsQueryPanelOpen,
      isQueryPanelOpen,
      handleSend,
      handleInputChange,
      isTablesPanelOpen,
      setIsTablesPanelOpen,
      toggleQueryPanel,
      chatContext,
      currentStep,
      processingSteps,
      setCustomData,
    } = useChat(teamId, datasetId, userId);
    const { theme } = useTheme();

    const [isFileImportOpen, setIsFileImportOpen] = useState(false);
    const [isTableVisible, setIsTableVisible] = useState(true);

    const [previewData, setPreviewData] = useState(null);
    const [selectedTablesError, setSelectedTablesError] = useState(false);

    const handleSendWithTableHide = async () => {
      setIsTableVisible(false);
      setChatStarted(true);
      await handleSend();
    };

    const currentMessages =
      conversations.find((conv) => conv.id === currentConversation)?.messages ||
      [];

    const handlePreview = (data) => {
      setPreviewData(data);
    };

    useEffect(() => {
      // Hide the table when a new message is added
      setIsTableVisible(false);
    }, [currentMessages.length]);

    useEffect(() => {
      // Show the table when new query results are available
      if (queryResult) {
        setIsTableVisible(true);
        setIsTablesPanelOpen(false);
        setIsQueryPanelOpen(true);
      }
    }, [queryResult]);

    useImperativeHandle(ref, () => ({
      openFileImport: () => {
        setIsFileImportOpen(true);
      },
    }));

    const toggleTablesPanel = () => {
      setIsTablesPanelOpen((isTablesPanelOpen) => !isTablesPanelOpen);
      setIsQueryPanelOpen((prev) => {
        if (prev) {
          return !prev;
        }
      });
    };

    const handleOpenFileImport = () => {
      setIsFileImportOpen(true);
      if (isTablesPanelOpen) {
        setIsTablesPanelOpen(false);
      }
      if (isQueryPanelOpen) {
        toggleQueryPanel();
      }
    };

    const handleFileImport = async (fileData) => {
      try {
        const fileDetails = {
          datasetId: "custom-file-1",
          name: fileData.fileName,
          type: "csv",
          data: fileData.data,
        };

        setCustomData(fileDetails);
        setIsFileImportOpen(false);
        // onFileImportComplete(fileDetails);
      } catch (error) {
        console.error("Failed to import files:", error);
      }
    };

    const handleTableSelectionChange = (selectedTables: string[]) => {
      setCustomData({ selectedTables });
    };

    const renderActivePanel = () => {
      if (isTablesPanelOpen) {
        return (
          <TablesPanel
            onTableSelectionChange={handleTableSelectionChange}
            teamId={teamId}
            datasetId={datasetId}
            hasError={selectedTablesError}
          />
        );
      }
      if (isQueryPanelOpen) {
        return (
          <QueryPanel
            generatedQuery={generatedQuery}
            explanations={explanations}
            queryResult={queryResult}
            queryMetadata={queryMetadata}
            theme={theme}
            teamId={teamId}
            datasetId={datasetId}
            onClose={toggleQueryPanel}
          />
        );
      }
      if (isFileImportOpen) {
        return (
          <FileImportPanel
            onClose={() => setIsFileImportOpen(false)}
            onImport={handleFileImport}
            onPreview={handlePreview} // Pass preview handler
          />
        );
      }
      return null;
    };

    const handleClosePreview = () => {
      setCustomData(null);
      setPreviewData(null);
    };

    const handleQuestionClick = (question: string) => {
      setChatStarted(true);
      handleQuickQuestion(question);
    };

    if (!hasDataSource && currentMessages.length === 0) {
      return <NoDataSourcePrompt onImportFiles={handleOpenFileImport} />;
    }

    return (
      <TooltipProvider>
        <div className="flex h-full bg-gray-50 dark:bg-gray-900">
          {/* Main Chat Area */}
          <div className="flex-1 flex flex-col h-full">
            <div className="flex-1 min-h-0">
              {currentMessages.length === 0 && !previewData ? (
                <WelcomeScreen onQuestionClick={handleQuestionClick} />
              ) : (
                <MessageList
                  messages={currentMessages}
                  queryResult={queryResult}
                  isTableVisible={isTableVisible}
                  previewData={previewData}
                  currentStep={currentStep}
                  steps={processingSteps}
                  isLoading={isLoading}
                  onClosePreview={handleClosePreview}
                />
              )}
            </div>
            <InputArea
              input={input}
              isLoading={isLoading}
              onInputChange={handleInputChange}
              onSend={handleSendWithTableHide}
              onToggleQueryPanel={toggleQueryPanel}
              onToggleTablesPanel={toggleTablesPanel}
              onOpenCsvImport={() => setIsFileImportOpen(true)}
              isQueryPanelOpen={isQueryPanelOpen}
              isTablesPanelOpen={isTablesPanelOpen}
              isCustomDataMode={isCustomDataMode}
            />
          </div>
          {/* Side Panel Container */}
          <AnimatePresence>
            {(isTablesPanelOpen || isQueryPanelOpen || isFileImportOpen) && (
              <motion.div
                initial={{ width: 0, opacity: 0 }}
                animate={{ width: "400px", opacity: 1 }}
                exit={{ width: 0, opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="flex-shrink-0 border-l dark:border-gray-800 h-full"
                style={{ height: "100%" }}
              >
                {/* Panel Content Container */}
                <div className="h-full relative">
                  <AnimatePresence mode="wait">
                    <motion.div
                      key={
                        isTablesPanelOpen
                          ? "tables"
                          : isQueryPanelOpen
                          ? "query"
                          : "csv"
                      }
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -20 }}
                      transition={{ duration: 0.2 }}
                      className="absolute inset-0"
                    >
                      {renderActivePanel()}
                    </motion.div>
                  </AnimatePresence>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </TooltipProvider>
    );
  }
);

export default Chat;
