import React, { memo, useState, useCallback } from "react";

import { Handle, Position, NodeProps, EdgeProps } from "reactflow";
import { motion, AnimatePresence } from "framer-motion";
import { Link, Table, Eye, ChevronDown, ChevronUp } from "lucide-react";

import { cn } from "@/components/utils";
import { usePlayground } from "@/context/PlaygroundContext";
import MonitoringNode from "@/components/Playground/MonitoringPanel/node";

interface ColumnType {
  name: string;
  type: string;
  isPrimary: boolean;
  isForeign: boolean;
  isNullable: boolean;
}

interface TableData {
  name: string;
  columns: ColumnType[];
  isJunction?: boolean;
  isView?: boolean;
}

const BaseTableNode = memo(({ data, selected, id }: NodeProps<TableData>) => {
  const { schema } = usePlayground();
  const [isExpanded, setIsExpanded] = useState(true);
  const isHighlighted = (schema?.highlightedNodes|| []).includes(id);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{
        opacity: 1,
        scale: 1,
        transition: { duration: 0.2 },
      }}
      className={cn(
        "bg-white border-2 rounded-lg shadow-lg min-w-[280px]",
        data.isView && "border-blue-200",
        data.isJunction && "border-purple-200",
        selected && "ring-2 ring-primary ring-offset-2",
        isHighlighted && "ring-2 ring-green-500 ring-offset-2 border-green-500"
      )}
    >
      {/* Table Header */}
      <div className="p-3 border-b bg-muted/50 rounded-t-lg">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {data.isView ? (
              <Eye className="h-4 w-4 text-blue-500" />
            ) : data.isJunction ? (
              <Link className="h-4 w-4 text-purple-500" />
            ) : (
              <Table className="h-4 w-4 text-gray-500" />
            )}
            <span className="font-medium text-sm">{data.name}</span>
          </div>

          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="p-1 rounded-md hover:bg-muted"
          >
            {isExpanded ? (
              <ChevronUp className="h-4 w-4" />
            ) : (
              <ChevronDown className="h-4 w-4" />
            )}
          </button>
        </div>
      </div>

      {/* Columns List */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className="overflow-hidden"
          >
            <div className="p-2 space-y-1">
              {data.columns.map((column, index) => (
                <ColumnRow
                  key={column.name}
                  column={column}
                  isFirst={index === 0}
                  isLast={index === data.columns.length - 1}
                />
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Connection Handles */}
      <Handle
        type="target"
        position={Position.Left}
        className="w-2 h-2 border-2 bg-background !opacity-0 hover:!opacity-100 transition-opacity"
      />
      <Handle
        type="source"
        position={Position.Right}
        className="w-2 h-2 border-2 bg-background !opacity-0 hover:!opacity-100 transition-opacity"
      />
    </motion.div>
  );
});

const ColumnRow = memo(
  ({
    column,
    isFirst,
    isLast,
  }: {
    column: ColumnType;
    isFirst: boolean;
    isLast: boolean;
  }) => {
    return (
      <div
        className={cn(
          "flex items-center gap-2 px-2 py-1.5 text-xs rounded-md hover:bg-muted",
          isFirst && "mt-0",
          isLast && "mb-0"
        )}
      >
        <div className="flex-1 flex items-center gap-2">
          {column.isPrimary && (
            <span className="text-amber-500 font-medium">PK</span>
          )}
          {column.isForeign && (
            <span className="text-blue-500 font-medium">FK</span>
          )}
          <span className="truncate">{column.name}</span>
        </div>
        <span className="text-muted-foreground">{column.type}</span>
        {column.isNullable && (
          <span className="text-muted-foreground italic">null</span>
        )}
      </div>
    );
  }
);

const RelationshipEdge = memo(
  ({ sourceX, sourceY, targetX, targetY, data, selected, id }: EdgeProps) => {
    const { schema } = usePlayground();
    const isHighlighted =( schema?.highlightedEdges || []).includes(id);

    const relationshipTypes = {
      "one-to-one": {
        start: "url(#one-marker)",
        end: "url(#one-marker)",
      },
      "one-to-many": {
        start: "url(#one-marker)",
        end: "url(#many-marker)",
      },
      "many-to-many": {
        start: "url(#many-marker)",
        end: "url(#many-marker)",
      },
    };

    const type = data?.relationshipType || "one-to-many";
    const { start, end } = relationshipTypes[type];

    const midX = (sourceX + targetX) / 2;
    const midY = (sourceY + targetY) / 2;
    const path = `M ${sourceX} ${sourceY} C ${midX} ${sourceY}, ${midX} ${targetY}, ${targetX} ${targetY}`;

    const pathStyles = {
      stroke: isHighlighted ? "#22c55e" : selected ? "#2563eb" : "#64748b",
      strokeWidth: isHighlighted ? 3 : 2,
      fill: "none",
      strokeDasharray: "4 4",
    };

    const markerStyles = {
      stroke: isHighlighted ? "#22c55e" : selected ? "#2563eb" : "#64748b",
      fill: isHighlighted ? "#22c55e" : selected ? "#2563eb" : "#64748b",
    };

    return (
      <>
        <defs>
          {/* One marker (straight line) */}
          <marker
            id="one-marker"
            viewBox="0 0 10 10"
            refX="5"
            refY="5"
            markerWidth="6"
            markerHeight="6"
            orient="auto-start-reverse"
          >
            <path
              d="M 0 5 L 10 5"
              style={{
                stroke: markerStyles.stroke,
                strokeWidth: 2,
              }}
            />
          </marker>

          {/* Many marker (crow's foot) */}
          <marker
            id="many-marker"
            viewBox="0 0 10 10"
            refX="5"
            refY="5"
            markerWidth="6"
            markerHeight="6"
            orient="auto-start-reverse"
          >
            <path
              d="M 0 0 L 10 5 L 0 10 z"
              style={{
                fill: markerStyles.fill,
              }}
            />
          </marker>
        </defs>

        {/* The main path with dotted line styling */}
        <path d={path} style={pathStyles} markerEnd={end} markerStart={start} />
      </>
    );
  }
);

export const nodeTypes = {
  table: BaseTableNode,
  junction: memo((props: NodeProps<TableData>) => (
    <BaseTableNode {...props} data={{ ...props.data, isJunction: true }} />
  )),
  view: memo((props: NodeProps<TableData>) => (
    <BaseTableNode {...props} data={{ ...props.data, isView: true }} />
  )),
  monitor: MonitoringNode
};

export const edgeTypes = {
  relationship: RelationshipEdge,
};

export default nodeTypes;
