import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  ChevronLeft,
  BarChart2,
  Bot,
  Settings,
  Activity,
  BookOpen,
  HelpCircle,
  Database,
} from "lucide-react";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
} from "@/components/Common/Tooltip";

const Sidebar = ({ activeTab, setActiveTab, collapseSidebar }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (collapseSidebar) {
      setIsCollapsed(true);
    }
  }, [collapseSidebar]);
  
  // Top section navigation items
  const topMenuItems = [
    {
      icon: <Bot size={22} />,
      label: "Chat",
      id: "chat",
      tooltip: "Chat with AI assistant",
    },
    {
      icon: <BarChart2 size={22} />,
      label: "Automations",
      id: "insights",
      tooltip: "View insights and analytics",
    },
  ];

  // Data section items
  const dataMenuItems = [
    {
      icon: <Database size={22} />,
      label: "Data Model",
      id: "schema",
      tooltip: "schema data sources",
    },
    // { icon: <Database size={22} />, label: "Data Model", id: "data", tooltip: "Manage data sources" },
  ];

  // Bottom section items
  const bottomMenuItems = [
    {
      icon: <Settings size={22} />,
      label: "Settings",
      id: "settings",
      tooltip: "Adjust settings",
    },
    {
      icon: <Activity size={22} />,
      label: "Logs",
      id: "logs",
      tooltip: "View activity logs",
    },
  ];

  const helpItems = [
    {
      icon: <BookOpen size={18} />,
      label: "Documentation",
      link: "#",
      tooltip: "Read documentation",
    },
    {
      icon: <HelpCircle size={18} />,
      label: "Get Started",
      link: "#",
      tooltip: "View tutorial",
    },
  ];

  return (
    <motion.div
      initial={false}
      animate={{ width: isCollapsed ? 64 : 240 }}
      className="h-full flex pb-1.5 flex-col bg-white dark:bg-gray-900 border-r border-gray-200 dark:border-gray-800"
    >
      {/* Logo Section */}
      <div className="h-14 flex items-center justify-between px-4 border-b border-gray-200 dark:border-gray-800">
        <button
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="p-1 rounded-lg text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300
                     hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200"
        >
          <motion.div
            animate={{ rotate: isCollapsed ? 180 : 0 }}
            transition={{ duration: 0.2 }}
          >
            <ChevronLeft size={18} />
          </motion.div>
        </button>
      </div>

      {/* Main Navigation - Top Section */}
      <div className="flex flex-col flex-1 pt-4">
        {/* Top Menu Items */}
        <div className="px-3 space-y-1">
          {topMenuItems.map((item) => (
            <NavItem
              key={item.id}
              {...item}
              active={activeTab === item.id}
              onClick={() => {
                setActiveTab(item.id);
              }}
              isCollapsed={isCollapsed}
            />
          ))}
        </div>

        {/* Data Section with Separator */}
        <div className="mt-8 px-3 space-y-1">
          <div className="px-3 mb-2">
            {!isCollapsed && (
              <span className="text-xs font-medium text-gray-400 dark:text-gray-500">
                DATA
              </span>
            )}
          </div>
          {dataMenuItems.map((item) => (
            <NavItem
              key={item.id}
              {...item}
              active={activeTab === item.id}
              onClick={() => {
                if (item.id === "schema") {
                  setIsCollapsed(true);
                }
                setActiveTab(item.id);
              }}
              isCollapsed={isCollapsed}
            />
          ))}
        </div>

        {/* Bottom Fixed Section */}
        <div className="mt-auto px-3">
          <div className="space-y-1">
            {/* Settings & Logs */}
            {bottomMenuItems.map((item) => (
              <NavItem
                key={item.id}
                {...item}
                active={activeTab === item.id}
                onClick={() => setActiveTab(item.id)}
                isCollapsed={isCollapsed}
              />
            ))}
          </div>

          {/* Help Section */}
          <div className="mt-4 pt-4 border-t border-gray-100 dark:border-gray-800 space-y-1">
            {helpItems.map((item) => (
              <NavItem
                key={item.label}
                {...item}
                isLink
                isCollapsed={isCollapsed}
              />
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const NavItem = ({
  icon,
  label,
  tooltip,
  active,
  onClick,
  isCollapsed,
  isLink = false,
  href,
}) => {
  const Component = isLink ? "a" : motion.button;
  const props = isLink ? { href, target: "_blank" } : { onClick };

  const content = (
    <Component
      whileHover={{ scale: 1.01 }}
      whileTap={{ scale: 0.99 }}
      {...props}
      className={`
        w-full flex items-center gap-3 px-3 py-2 rounded-lg text-sm
        transition-all duration-200
        ${
          active
            ? "bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400 font-medium"
            : `text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-800/50 
             hover:text-gray-900 dark:hover:text-gray-300
             ${isLink ? "opacity-75 hover:opacity-100" : ""}`
        }
      `}
    >
      {isCollapsed && icon}
      {!isCollapsed && <span className="truncate">{label}</span>}
    </Component>
  );

  return isCollapsed ? (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger asChild>{content}</TooltipTrigger>
        <TooltipContent side="right" sideOffset={10}>
          <p className="text-sm">{tooltip || label}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ) : (
    content
  );
};

export default Sidebar;
