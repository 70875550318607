import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { usePlayground } from "@/context/PlaygroundContext";
import { DatabasePanel } from "./DatabasePanel";
import { LLMConfigPanel } from "./LLMPanel";
import { ChatPanel } from "./ChatPanel";
import MonitoringPanel from "./MonitoringPanel";

export const PanelContainer = () => {
  const { panels, setPanels, schema, connection } = usePlayground();
  const isConnected = !!connection && !!schema;

  // This will determine which panel to show in the left side
  const showChat = isConnected && panels.activeLeftTab === "chat";
  const showDatabase = panels.activeLeftTab === "connect" || !isConnected;

  return (
    <>
      {/* Left Panel - Database/Chat Toggle */}
      <AnimatePresence mode="wait">
        {panels.isLeftPanelOpen && (
          <motion.div
            initial={{ x: -320, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -320, opacity: 0 }}
            transition={{ type: "spring", damping: 20 }}
            className="absolute left-4 top-4 w-6/12 bg-card rounded-lg z-50"
          >
            <AnimatePresence mode="wait">
              {showDatabase ? (
                <motion.div
                  key="database-panel"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <DatabasePanel />
                </motion.div>
              ) : (
                <motion.div
                  key="chat-panel"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <ChatPanel />
                  {connection.schema === "pagila" && <MonitoringPanel />}
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Right Panel - LLM Config */}
      <AnimatePresence>
        {panels.isRightPanelOpen && (
          <motion.div
            initial={{ x: 320, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 320, opacity: 0 }}
            transition={{ type: "spring", damping: 20 }}
            className="absolute right-4 top-4 w-80 bg-card rounded-lg shadow-lg border z-50"
          >
            <LLMConfigPanel />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
