import { Node, Edge } from "reactflow";

// Define the structure of our monitoring configuration
export interface MonitorConfig {
  name: string;
  description: string;
  query: string;
  emailEnabled: boolean;
  slackEnabled: boolean;
  frequency: string;
  isActive: boolean;
}

// Define all available Pagila tables for type safety
export const PAGILA_TABLES = [
  "actor",
  "address",
  "category",
  "city",
  "country",
  "customer",
  "film",
  "film_actor",
  "film_category",
  "inventory",
  "language",
  "payment",
  "rental",
  "staff",
  "store",
] as const;

export const extractTablesFromPagilaSQL = (sql: string): string[] => {
  // Convert SQL to lowercase for case-insensitive matching
  const lowerSql = sql.toLowerCase();

  // Find all table names mentioned in the SQL
  return PAGILA_TABLES.filter((table) =>
    // Look for table names in FROM and JOIN clauses
    new RegExp(`\\b(from|join)\\s+${table}\\b`, "i").test(lowerSql)
  );
};

export const createEdgesForMonitor = (
  monitorNode: Node,
  affectedTables: string[]
): Edge[] => {
  return affectedTables.map((tableId) => ({
    id: `monitoring-edge-${tableId}-${monitorNode.id}`,
    source: tableId,
    target: monitorNode.id,
    type: "monitoring-edge",
    animated: true, // Shows data flow
    style: {
      stroke: "#9333ea", // Purple color
      strokeWidth: 2,
      strokeDasharray: "5,5", // Dashed line for monitoring
    },
    data: {
      monitoringRelation: true,
      description: `Monitoring connection from ${tableId}`,
    },
  }));
};

export const calculateMonitorNodePosition = (
  affectedTableNodes: Node[]
): { x: number; y: number } => {
  if (affectedTableNodes.length === 0) {
    return { x: 100, y: 100 }; // Default position
  }

  // Calculate the center point of all affected tables
  const centerX =
    affectedTableNodes.reduce((sum, node) => sum + node.position.x, 0) /
    affectedTableNodes.length;
  const centerY =
    affectedTableNodes.reduce((sum, node) => sum + node.position.y, 0) /
    affectedTableNodes.length;

  // Offset the monitor node slightly to avoid overlap
  return {
    x: centerX + 200, // Offset to the right
    y: centerY - 100, // Offset upward
  };
};

export const validateMonitorConfig = (config: MonitorConfig): string[] => {
  const errors: string[] = [];

  if (!config.name?.trim()) {
    errors.push("Monitor name is required");
  }

  if (!config.query?.trim()) {
    errors.push("Query is required");
  }

  // Check if query contains at least one Pagila table
  const tables = extractTablesFromPagilaSQL(config.query || "");
  if (tables.length === 0) {
    errors.push("Query must reference at least one valid Pagila table");
  }

  return errors;
};
