import React, { useState, useCallback } from "react";
import {
  Mail,
  MessageSquare,
  MessageCircle,
  BellRing,
  Lock,
  Rocket,
  ExternalLink,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/Common";
import { Button } from "@/components/Common";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/Common";
import { Badge } from "@/components/Common";
import { CodeEditor } from "@/components/Common";

import { useTheme } from "@/hooks/useTheme";
import { usePlayground } from "@/context/PlaygroundContext";
import {
  MonitorConfig,
  extractTablesFromPagilaSQL,
  createEdgesForMonitor,
  calculateMonitorNodePosition,
} from "./utils";
import { MONITORING_TEMPLATES } from "./constants";

const AUTOMATION_CHANNELS = [
  {
    id: "email",
    name: "Email Notifications",
    icon: Mail,
    description: "Send automated alerts to team members",
  },
  {
    id: "slack",
    name: "Slack Integration",
    icon: MessageSquare,
    description: "Post alerts to your Slack channels",
  },
  {
    id: "whatsapp",
    name: "WhatsApp Business",
    icon: MessageCircle,
    description: "Send instant alerts via WhatsApp",
  },
];

const AutomationSection = () => {
  return (
    <div className="mt-6 space-y-4">
      <div className="flex items-center gap-2 text-sm text-gray-500">
        <Rocket className="h-4 w-4" />
        <span>
          Contact us to access automations for your workflows on our{" "}
          <a
            href="https://wavequery.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline inline-flex items-center gap-1"
          >
            dashboard
            <ExternalLink className="h-3 w-3" />
          </a>
        </span>
      </div>

      <div className="space-y-2">
        {AUTOMATION_CHANNELS.map((channel) => (
          <div
            key={channel.id}
            className="flex items-center justify-between p-3 bg-gray-50 rounded-lg opacity-80 cursor-not-allowed"
          >
            <div className="flex items-center gap-3">
              <div className="p-1.5 bg-gray-100 rounded">
                <channel.icon className="h-4 w-4 text-gray-500" />
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <span className="text-sm font-medium">{channel.name}</span>
                  <Badge
                    variant="outline"
                    className="bg-gray-50 border-gray-200"
                  >
                    <Lock className="h-3 w-3 mr-1" />
                  </Badge>
                </div>
                <p className="text-xs text-gray-500">{channel.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const MonitoringToolsPanel = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [customQuery, setCustomQuery] = useState("");
  const [editingMonitor, setEditingMonitor] = useState(null);

  const { schema, setSchema } = usePlayground();
  const { theme } = useTheme();

  const handleEditMonitor = useCallback(
    (monitorId: string) => {
      console.log("schema.nodes", schema, "id", monitorId);
      // Find the existing monitor node
      const monitorNode = schema.nodes.find((node) => node.id === monitorId);
      if (!monitorNode) return;

      // Set up editing state
      setCustomQuery(monitorNode.data.query);
      setSelectedTemplate(null);
      setEditingMonitor(monitorId);
      setIsModalOpen(true);
    },
    [schema]
  );

  const handleToggleActive = useCallback(
    (monitorId: string, active: boolean) => {
      setSchema((prev) => ({
        ...prev,
        nodes: prev.nodes.map((node) =>
          node.id === monitorId
            ? { ...node, data: { ...node.data, isActive: active } }
            : node
        ),
      }));
    },
    [setSchema]
  );

  const handleCreateMonitor = () => {
    if (!selectedTemplate && !customQuery) return;

    const query = customQuery || selectedTemplate.query;
    const affectedTables = extractTablesFromPagilaSQL(query);

    if (editingMonitor) {
      // Update existing monitor
      setSchema((prev) => ({
        ...prev,
        nodes: prev.nodes.map((node) =>
          node.id === editingMonitor
            ? {
                ...node,
                data: {
                  ...node.data,
                  query,
                  affectedTables,
                  name: selectedTemplate?.name || node.data.name,
                  description:
                    selectedTemplate?.description || node.data.description,
                },
              }
            : node
        ),
        // Highlight the updated monitor and its connections
        highlightedNodes: [editingMonitor, ...affectedTables],
        highlightedEdges: prev.edges
          .filter(
            (edge) =>
              edge.source === editingMonitor || edge.target === editingMonitor
          )
          .map((edge) => edge.id),
      }));
    } else {
      // Create new monitoring node
      const monitorNode = {
        id: `monitor-${Date.now()}`,
        type: "monitor",
        position: calculateMonitorNodePosition(
          affectedTables
            .map((tableId) => schema.nodes.find((node) => node.id === tableId))
            .filter(Boolean)
        ),
        data: {
          onToggleActive: handleToggleActive,
          onEdit: handleEditMonitor,
          name: selectedTemplate?.name || "Custom Monitor",
          description:
            selectedTemplate?.description || "Custom monitoring query",
          query,
          isActive: true,
          affectedTables,
          frequency: "15m",
          emailEnabled: false,
          slackEnabled: false,
          lastCheck: new Date().toISOString(),
          nextCheck: new Date(Date.now() + 15 * 60000).toISOString(),
        },
      };

      // Create edges for the monitoring node
      const monitorEdges = createEdgesForMonitor(monitorNode, affectedTables);

      // Update schema with new node and edges
      setSchema((prev) => {
        const newSchema = {
          ...prev,
          nodes: [...prev.nodes, monitorNode],
          edges: [...prev.edges, ...monitorEdges],
          // Add these new properties to trigger focus
          highlightedNodes: [monitorNode.id, ...affectedTables],
          highlightedEdges: monitorEdges.map((edge) => edge.id),
        };
        return newSchema;
      });
    }

    setIsModalOpen(false);
    setSelectedTemplate(null);
    setCustomQuery("");
  };

  return (
    <>
      {/* Floating Action Button */}
      <motion.div
        // className="absolute right-4 top-1/2 transform -translate-y-1/2"
        initial={{ x: 100 }}
        animate={{ x: 0 }}
        transition={{ type: "spring", damping: 20 }}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <Tooltip open={showTooltip}>
          <TooltipTrigger asChild>
            <Button
              size="lg"
              className="h-10 w-10 ml-1 bg-white shadow-lg hover:bg-gray-50 p-2 rounded-full flex items-center justify-center"
              onClick={() => setIsModalOpen(true)}
            >
              <BellRing className="h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="left">
            <p>Create Monitoring Alert</p>
          </TooltipContent>
        </Tooltip>
      </motion.div>

      {/* Monitoring Modal */}
      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent className="max-w-5xl">
          <DialogHeader>
            <DialogTitle>Create Monitoring Alert</DialogTitle>
            <DialogDescription>
              Set up automated monitoring for your Pagila DVD rental database
            </DialogDescription>
          </DialogHeader>

          <div className="grid grid-cols-[1fr,1.5fr] gap-6">
            <div className="space-y-4">
              <div className="space-y-2">
                <h3 className="text-sm font-medium">Monitoring Templates</h3>
                <div className="space-y-2">
                  {MONITORING_TEMPLATES.map((template) => (
                    <motion.div
                      key={template.id}
                      className={`p-3 border rounded-lg cursor-pointer transition-colors
                        ${
                          selectedTemplate?.id === template.id
                            ? "border-gray-400 bg-gray-50"
                            : "hover:border-gray-300"
                        }
                      `}
                      onClick={() => {
                        setSelectedTemplate(template);
                        setCustomQuery("");
                      }}
                      whileHover={{ scale: 1.01 }}
                      whileTap={{ scale: 0.99 }}
                    >
                      <div className="flex items-start gap-2">
                        <template.icon className="h-4 w-4 text-gray-500 mt-1" />
                        <div>
                          <h3 className="font-medium text-sm">
                            {template.name}
                          </h3>
                          <p className="text-xs text-gray-500 mt-1">
                            {template.description}
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
                <AutomationSection />
              </div>
            </div>

            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <h3 className="text-sm font-medium">Query Editor</h3>
                <div className="flex items-center gap-2">
                  <Badge variant="outline" className="text-gray-500">
                    Pagila DB
                  </Badge>
                  {selectedTemplate && (
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => {
                        setCustomQuery("");
                        setSelectedTemplate(null);
                      }}
                    >
                      Clear
                    </Button>
                  )}
                </div>
              </div>
              <CodeEditor
                value={customQuery || selectedTemplate?.query || ""}
                language="sql"
                theme={theme === "dark" ? "vs-dark" : "light"}
                sqlDialect="postgresql"
                onChange={setCustomQuery}
                height="480px"
              />
              <div className="flex justify-end gap-2">
                <Button variant="outline" onClick={() => setIsModalOpen(false)}>
                  Cancel
                </Button>
                <Button
                  onClick={handleCreateMonitor}
                  disabled={!selectedTemplate && !customQuery}
                >
                  Create Monitor
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MonitoringToolsPanel;
