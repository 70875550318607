import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { ReactFlowProvider } from "reactflow";
import { useTeam } from "@/context/TeamContext";
import Settings from "@/components/Dashboard/Settings/Settings";
import QueryLogs from "@/components/Dashboard/Logs/QueryLogs";
import DefineData from "@/components/DefineData/Setup";
import Chatbot from "@/components/Dashboard/Chat";
import Insights from "@/components/Dashboard/Insights/Insights";

import Sidebar from "@/components/Dashboard/Sidebar";
import SchemaVisualization from "@/components/Dashboard/Schema/Schema";

const Dashboard = () => {
  const { userId, teamId, warehouseDetails, updateWarehouseDetails } =
    useTeam();

  const [customDataSource, setCustomDataSource] = useState(false);
  const [activeTab, setActiveTab] = useState("chat");
  const [chatStarted, setChatStarted] = useState(false);

  const chatRef = useRef();

  const renderContent = () => {
    switch (activeTab) {
      case "insights":
        return (
          <Insights teamId={teamId} datasetId={warehouseDetails?.datasetId} />
        );
      case "chat":
        return (
          <Chatbot
            ref={chatRef}
            setChatStarted={setChatStarted}
            teamId={teamId}
            userId={userId}
            datasetId={warehouseDetails?.datasetId}
            hasDataSource={!!warehouseDetails || customDataSource}
            isCustomDataMode={customDataSource}
            onFileImportComplete={(fileDetails) => {
              // Update warehouse details with file information
              updateWarehouseDetails({
                ...fileDetails,
                type: "custom-file",
              });
              setCustomDataSource(true);
            }}
          />
        );
      case "schema":
        return (
          <ReactFlowProvider>
            <SchemaVisualization
              teamId={teamId}
              datasetId={warehouseDetails?.datasetId}
            />
          </ReactFlowProvider>
        );
      case "settings":
        return <Settings />;
      case "logs":
        return <QueryLogs />;
      case "data":
        return <DefineData />;
      // case "finetuning":
      //   return <h1>COMING SOON</h1>;
      default:
        return null;
    }
  };

  // const handleOpenFileImport = () => {
  //   // Switch to chat tab if not already there
  //   setActiveTab("chat");
  //   // Set custom data source mode
  //   setCustomDataSource(true);

  //   // // Clear warehouse details temporarily
  //   // updateWarehouseDetails(null);

  //   // Trigger file import in chat component
  //   if (chatRef.current) {
  //     chatRef.current.openFileImport();
  //   }
  // };

  return (
    <div className="flex h-full">
      <Sidebar collapseSidebar={chatStarted} activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="flex-1 h-full overflow-hidden">
        <main className="h-full overflow-hidden">
          <motion.div
            layout
            transition={{ duration: 0.3 }}
            className={"w-4/4 h-full overflow-scroll"}
          >
            {renderContent()}
          </motion.div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
