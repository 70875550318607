import React, { useEffect, useState, useCallback } from "react";
import "reactflow/dist/style.css";
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  BackgroundVariant,
  useReactFlow,
  applyNodeChanges,
  applyEdgeChanges,
  Position,
} from "reactflow";
import { motion } from "framer-motion";
import { Search } from "lucide-react";
import dagre from "dagre";

import { nodeTypes, edgeTypes } from "@/components/Dashboard/Schema/FlowNodes";
import { dataService } from "@/services/dataService";
import { Card, CardContent } from "@/components/Common/Card";
import { Input } from "@/components/Common";
import SchemaLoadingDialog from "./SchemaLoadingDialog";

const LAYOUT_CONFIG = {
  GRID_SPACING: 300,
  VERTICAL_SPACING: 100,
  NODE_WIDTH: 280,
  NODE_HEIGHT: 200,
};

const SchemaVisualization = ({ teamId, datasetId }) => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { fitView } = useReactFlow();

  // Transform API data into ReactFlow format
  const transformData = useCallback((data) => {
    const g = new dagre.graphlib.Graph();
    g.setGraph({
      rankdir: "LR",
      nodesep: LAYOUT_CONFIG.GRID_SPACING,
      ranksep: LAYOUT_CONFIG.VERTICAL_SPACING,
    });
    g.setDefaultEdgeLabel(() => ({}));

    const flowNodes = data.tables.map((table) => ({
      id: table.name,
      type: data.junctionTables.includes(table.name) ? "junction" : "table",
      data: {
        name: table.name,
        columns: table.columns.map((col) => ({
          name: col.name,
          type: col.type,
          isPrimary: col.isPrimaryKey,
          isForeign: false, // We'll update this based on relationships
          isNullable: col.isNullable,
        })),
      },
      position: { x: 0, y: 0 },
    }));

    // Add nodes to dagre
    flowNodes.forEach((node) => {
      g.setNode(node.id, {
        width: LAYOUT_CONFIG.NODE_WIDTH,
        height: LAYOUT_CONFIG.NODE_HEIGHT,
      });
    });

    const flowEdges = data.relationships.map((rel, index) => {
      // Mark foreign key columns
      const sourceNode = flowNodes.find((n) => n.id === rel.sourceTable);
      const targetNode = flowNodes.find((n) => n.id === rel.targetTable);

      if (sourceNode && targetNode) {
        sourceNode.data.columns.forEach((col) => {
          if (rel.sourceColumns.includes(col.name)) {
            col.isForeign = true;
          }
        });
      }

      // Create edge
      return {
        id: `edge-${index}`,
        source: rel.sourceTable,
        target: rel.targetTable,
        type: "relationship",
        data: {
          relationshipType:
            rel.type === "implicit" ? "one-to-one" : "one-to-many",
          sourceColumn: rel.sourceColumns[0],
          targetColumn: rel.targetColumns[0],
        },
      };
    });

    // Add edges to dagre
    flowEdges.forEach((edge) => {
      g.setEdge(edge.source, edge.target);
    });

    // Calculate layout
    dagre.layout(g);

    // Apply calculated positions
    const nodesWithPositions = flowNodes.map((node) => {
      const nodeWithPosition = g.node(node.id);
      return {
        ...node,
        position: {
          x: nodeWithPosition.x - LAYOUT_CONFIG.NODE_WIDTH / 2,
          y: nodeWithPosition.y - LAYOUT_CONFIG.NODE_HEIGHT / 2,
        },
      };
    });

    return { nodes: nodesWithPositions, edges: flowEdges };
  }, []);

  // Fetch and set data
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await dataService.getDataModel({ datasetId, teamId });
        const { nodes: flowNodes, edges: flowEdges } = transformData(response);
        setNodes(flowNodes);
        setEdges(flowEdges);

        // Fit view after data is loaded
        setTimeout(() => {
          fitView({ duration: 800 });
          // Add a small delay before hiding the loading dialog
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        }, 100);
      } catch (error) {
        console.error("Error fetching schema:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [teamId, datasetId, transformData, fitView]);

  // Handle node changes (position, selection)
  const onNodesChange = useCallback((changes) => {
    setNodes((prevNodes) => applyNodeChanges(changes, prevNodes));
  }, []);

  // Handle edge changes
  const onEdgesChange = useCallback((changes) => {
    setEdges((prevEdges) => applyEdgeChanges(changes, prevEdges));
  }, []);

  // Filter nodes based on search
  const filteredNodes = React.useMemo(() => {
    if (!searchTerm) return nodes;

    return nodes.filter((node) => {
      const matchesName = node.data.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const matchesColumns = node.data.columns.some(
        (col) =>
          col.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          col.type.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return matchesName || matchesColumns;
    });
  }, [nodes, searchTerm]);

  return (
    <div className="h-full w-full flex flex-col">
       <SchemaLoadingDialog isOpen={isLoading} />
     
      {/* Flow Canvas */}
      <div className="flex-1 h-full m-4">
        <ReactFlow
          nodes={filteredNodes}
          edges={edges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          minZoom={0.1}
          maxZoom={4}
          fitView
        >
          <Background
            variant={BackgroundVariant.Dots}
            gap={12}
            size={1}
            className="transition-opacity duration-300"
          />
          <MiniMap
            nodeColor={(node) => {
              const density = node.data?.relationships?.length || 0;
              return density > 5 ? "var(--primary)" : "var(--muted)";
            }}
            nodeStrokeWidth={3}
            zoomable
            pannable
            className="bg-background/80 backdrop-blur-sm"
          />
          <Controls />
        </ReactFlow>
      </div>
    </div>
  );
};

export default SchemaVisualization;
