import {
    Clock,
    DollarSign,
    Users,
    Package,
    Film,
  } from "lucide-react";

const MONITORING_TEMPLATES = [
  {
    id: "late-rentals",
    name: "Late Rental Alerts",
    icon: Clock,
    description: "Monitor overdue movie rentals and alert staff",
    query: `SELECT
              c.first_name || ' ' || c.last_name as customer,
              c.email,
              f.title as film,
              r.rental_date,
              EXTRACT(DAY FROM (CURRENT_TIMESTAMP - r.rental_date)) as days_overdue
          FROM rental r
          JOIN customer c ON r.customer_id = c.customer_id
          JOIN inventory i ON r.inventory_id = i.inventory_id
          JOIN film f ON i.film_id = f.film_id
          WHERE r.return_date IS NULL
              AND r.rental_date < CURRENT_DATE - INTERVAL '7 days'
          ORDER BY r.rental_date ASC;`,
  },
  {
    id: "inventory-alerts",
    name: "Low Inventory Alert",
    icon: Package,
    description: "Track films with critically low available copies",
    query: `WITH available_inventory AS (
              SELECT 
                  f.film_id,
                  f.title,
                  COUNT(i.inventory_id) as total_copies,
                  COUNT(CASE WHEN r.return_date IS NULL THEN 1 END) as currently_rented
              FROM film f
              LEFT JOIN inventory i ON f.film_id = i.film_id
              LEFT JOIN rental r ON i.inventory_id = r.inventory_id AND r.return_date IS NULL
              GROUP BY f.film_id, f.title
          )
          SELECT 
              title,
              total_copies,
              currently_rented,
              (total_copies - currently_rented) as available_copies
          FROM available_inventory
          WHERE (total_copies - currently_rented) <= 2
          ORDER BY available_copies ASC;`,
  },
  {
    id: "payment-monitoring",
    name: "Payment Anomalies",
    icon: DollarSign,
    description: "Detect unusual payment patterns and high-value transactions",
    query: `WITH customer_averages AS (
                  SELECT 
                      customer_id,
                      AVG(amount) as avg_payment,
                      STDDEV(amount) as stddev_payment
                  FROM payment
                  GROUP BY customer_id
              )
              SELECT 
                  c.first_name || ' ' || c.last_name as customer,
                  p.amount,
                  p.payment_date,
                  ca.avg_payment,
                  p.amount - ca.avg_payment as deviation_from_avg
              FROM payment p
              JOIN customer c ON p.customer_id = c.customer_id
              JOIN customer_averages ca ON p.customer_id = ca.customer_id
              WHERE p.amount > ca.avg_payment + (2 * ca.stddev_payment)
                  AND p.payment_date >= CURRENT_DATE - INTERVAL '24 hours'
              ORDER BY deviation_from_avg DESC;`,
  },
  {
    id: "popular-films",
    name: "Popular Films Tracking",
    icon: Film,
    description: "Monitor highly demanded films for inventory planning",
    query: `WITH rental_counts AS (
              SELECT 
                  f.film_id,
                  f.title,
                  COUNT(r.rental_id) as rental_count,
                  f.rental_rate,
                  f.replacement_cost
              FROM film f
              JOIN inventory i ON f.film_id = i.film_id
              JOIN rental r ON i.inventory_id = r.inventory_id
              WHERE r.rental_date >= CURRENT_DATE - INTERVAL '30 days'
              GROUP BY f.film_id, f.title, f.rental_rate, f.replacement_cost
          )
          SELECT 
              title,
              rental_count,
              rental_rate,
              replacement_cost,
              (rental_count * rental_rate) as monthly_revenue
          FROM rental_counts
          WHERE rental_count > (
              SELECT AVG(rental_count) + STDDEV(rental_count)
              FROM rental_counts
          )
          ORDER BY rental_count DESC;`,
  },
  {
    id: "customer-insights",
    name: "Customer Behavior Analysis",
    icon: Users,
    description: "Track customer preferences and rental patterns",
    query: `WITH customer_stats AS (
          SELECT 
              c.customer_id,
              c.first_name || ' ' || c.last_name as customer_name,
              COUNT(DISTINCT fc.category_id) as genre_variety,
              MODE() WITHIN GROUP (ORDER BY fc.category_id) as favorite_category,
              AVG(f.rental_rate) as avg_rental_rate,
              COUNT(r.rental_id) as total_rentals
          FROM customer c
          JOIN rental r ON c.customer_id = r.customer_id
          JOIN inventory i ON r.inventory_id = i.inventory_id
          JOIN film f ON i.film_id = f.film_id
          JOIN film_category fc ON f.film_id = fc.film_id
          WHERE r.rental_date >= CURRENT_DATE - INTERVAL '90 days'
          GROUP BY c.customer_id, c.first_name, c.last_name
      )
      SELECT 
          cs.*,
          cat.name as preferred_category
      FROM customer_stats cs
      JOIN category cat ON cs.favorite_category = cat.category_id
      WHERE cs.genre_variety > 3
          AND cs.total_rentals > 10
      ORDER BY cs.total_rentals DESC;`,
  },
];

export { MONITORING_TEMPLATES };
