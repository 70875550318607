import { useState, useCallback } from "react";
import { dataService } from "@/services/dataService";
import { QueryMetadata } from "../Insights/types";
import {
  ProcessingStep,
  useSocketConnection,
} from "@/hooks/useSocketConnection";

interface Message {
  role: "user" | "assistant";
  content: string;
}

interface Conversation {
  id: string;
  messages: Message[];
}

interface ChatResponse {
  type: string;
  metadata: QueryMetadata;
  content: {
    query?: string;
    explanation?: string[];
  };
  result?: any;
  followupQuestion?: string;
}

export interface ChatContext {
  data?: any;
  customDataExtension?: boolean;
  selectedTables?: string[];
}

export const useChat = (teamId: string, datasetId: string, userId: string) => {
  const [conversations, setConversations] = useState<Conversation[]>([
    { id: "new", messages: [] },
  ]);
  const [currentConversation, setCurrentConversation] = useState("new");
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [queryResult, setQueryResult] = useState(null);
  const [generatedQuery, setGeneratedQuery] = useState("");
  const [explanations, setExplanations] = useState<string[]>([]);
  const [isQueryPanelOpen, setIsQueryPanelOpen] = useState(false);
  const [isTablesPanelOpen, setIsTablesPanelOpen] = useState(false);
  const [chatContext, setChatContext] = useState<ChatContext | null>(null);
  const [queryMetadata, setQueryMetadata] = useState<QueryMetadata | null>(
    null
  );
  const [processingSteps, setProcessingSteps] = useState<ProcessingStep[]>([]);
  const [currentStep, setCurrentStep] = useState<ProcessingStep | null>(null);

  const handleStepUpdate = useCallback((step: ProcessingStep) => {
    console.log("step", step);
    setProcessingSteps((prev) => [...prev, step]);
    setCurrentStep(step);
  }, []);

  const updateConversation = useCallback(
    (newMessage: Message) => {
      setConversations((prevConversations) =>
        prevConversations.map((conv) =>
          conv.id === currentConversation
            ? { ...conv, messages: [...conv.messages, newMessage] }
            : conv
        )
      );
    },
    [currentConversation]
  );

  const handleQueryResponse = useCallback(
    (response: ChatResponse) => {
      if (response.followupQuestion) {
        updateConversation({
          role: "assistant",
          content: response.followupQuestion,
        });
      }
      setGeneratedQuery(response.content.query || "");
      setExplanations(response.content.explanation || []);
      setQueryResult(response.result);
      setQueryMetadata(response.metadata);
      setIsQueryPanelOpen(true);
    },
    [updateConversation]
  );

  const handleNonQueryResponse = useCallback(
    (response: ChatResponse) => {
      updateConversation({
        role: "assistant",
        content: response.content || "",
      });
    },
    [updateConversation]
  );

  const handleResponse = useCallback(
    (data: any) => {
      const { response } = data;
      if (response.type === "query" || response.type === "visualization") {
        handleQueryResponse(response);
      } else {
        handleNonQueryResponse(response);
      }
      setIsLoading(false);
      setCurrentStep(null);
    },
    [handleQueryResponse, handleNonQueryResponse]
  );

  const handleErrorResponse = useCallback(() => {
    updateConversation({
      role: "assistant",
      content: "Sorry, I encountered an error. Please try again.",
    });
  }, [updateConversation]);

  const { isConnected, isReconnecting, sendMessage } = useSocketConnection({
    teamId,
    datasetId,
    userId,
    onStepUpdate: handleStepUpdate,
    onResponse: handleResponse,
    onError: handleErrorResponse,
  });

  const setCustomData = useCallback((fileDetails: any) => {
    if (!fileDetails) return setChatContext(null);
    setChatContext(() => ({
      // ...prev,
      selectedTables: fileDetails.selectedTables,
      data: fileDetails.data,
      customDataExtension: fileDetails.type,
    }));
  }, []);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setInput(e.target.value);
    },
    []
  );

  const toggleQueryPanel = useCallback(() => {
    setIsTablesPanelOpen((isTablesPanelOpen) => {
      if (isTablesPanelOpen) {
        return !isTablesPanelOpen;
      }
    });
    setIsQueryPanelOpen((prev) => !prev);
  }, []);

  // const sendMessage = useCallback(
  //   async (message: string) => {
  //     const currentMessages = conversations.find(
  //       (conv) => conv.id === currentConversation
  //     )?.messages;

  //     return await dataService.chat({
  //       message,
  //       teamId,
  //       datasetId,
  //       conversationHistory: currentMessages,
  //       context: chatContext,
  //     });
  //   },
  //   [conversations, currentConversation, teamId, datasetId, chatContext]
  // );

  const handleSend = useCallback(async () => {
    if (!input.trim()) return;

    updateConversation({ role: "user", content: input });
    setInput("");
    setIsLoading(true);
    setProcessingSteps([]); // Reset steps for new message

    try {
      await sendMessage(input, {
        conversationHistory: conversations.find(
          (conv) => conv.id === currentConversation
        )?.messages,
        context: chatContext,
      });
    } catch (error) {
      console.error("Error in chat:", error);
      handleErrorResponse();
    }
  }, [input, sendMessage, currentConversation, conversations, chatContext]);

  const handleQuickQuestion = useCallback(
    async (question: string) => {
      if (!question.trim()) return;

      updateConversation({ role: "user", content: question });
      setInput(""); // Clear input
      setIsLoading(true);
      // setProcessingSteps([]); // Reset steps for new message

      try {
        await sendMessage(question, {
          conversationHistory: conversations.find(
            (conv) => conv.id === currentConversation
          )?.messages,
          context: chatContext,
        });
      } catch (error) {
        console.error("Error in chat:", error);
        handleErrorResponse();
      }
    },
    [
      sendMessage,
      handleQueryResponse,
      handleNonQueryResponse,
      handleErrorResponse,
      updateConversation,
    ]
  );

  return {
    conversations,
    currentConversation,
    input,
    handleQuickQuestion,
    isLoading,
    queryResult,
    generatedQuery,
    explanations,
    isQueryPanelOpen,
    handleSend,
    handleInputChange,
    setIsQueryPanelOpen,
    isTablesPanelOpen,
    setIsTablesPanelOpen,
    toggleQueryPanel,
    queryMetadata,
    setCustomData,
    chatContext,
    processingSteps,
    currentStep,
    isConnected,
    isReconnecting,
  };
};
