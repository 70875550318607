import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Database, Shield, AlertCircle, ArrowRight } from "lucide-react";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  Switch,
} from "@/components/Common";
import { Input } from "@/components/Common";
import { Label } from "@/components/Common";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/Common";
import { Button } from "@/components/Common";

import { useSetup } from "@/context/SetupContext";
import { useTeam } from "@/context/TeamContext";
import { setupService } from "@/services/setupService";

import { warehouses as providers } from "../constants/warehouses";

import { BeakerIcon, BookOpen, LayersIcon, InfoIcon } from "lucide-react";

const sampleDatabases = [
  {
    id: "pagila",
    name: "Pagila DVD Rental",
    tag: "ENTERTAINMENT",
    description:
      "A comprehensive DVD rental store database with complex relationships",
    icon: Database,
    bgClass: "from-orange-50 via-orange-50 to-orange-100",
    complexity: "intermediate",
    stats: {
      tables: 22,
    },
    documentation: "https://github.com/devrimgunduz/pagila",
  },
  {
    id: "northwind",
    name: "Northwind Traders",
    tag: "BUSINESS",
    description:
      "Classic business database for product management and order processing",
    icon: Database,
    bgClass: "from-blue-50 via-blue-50 to-blue-100",
    complexity: "beginner",
    stats: {
      tables: 13,
    },
    documentation: "https://github.com/pthom/northwind_psql",
  },
];

interface ConnectionFormProps {
  warehouseId: string;
  onSubmit: (data: any) => void;
  onCancel: () => void;
}

const ConnectionForm: React.FC<ConnectionFormProps> = ({
  warehouseId,
  onSubmit,
  onCancel,
  isLoading,
}) => {
  const [formData, setFormData] = useState({
    // PostgreSQL fields
    host: "",
    port: "5432",
    database: "",
    username: "",
    password: "",
    schema: "public",
    ssl: true,
    projectId: `proj-${Math.random().toString(36).substr(2, 9)}`, // Auto-generated
    datasetId: "", // Optional field
    // Supabase fields
    projectUrl: "",
    apiKey: "",
    dbPassword: "",
    // Neon fields
    connectionString: "",
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  if (warehouseId === "postgres") {
    return (
      <div className="flex gap-6 min-h-[600px]">
        {/* Left Side - Sample Databases */}
        <div className="w-[350px] border-r border-gray-100 dark:border-gray-800 pr-6">
          <div className="space-y-4">
            <div>
              <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100 mb-1">
                Try Sample Databases
              </h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Explore WaveQuery instantly with our pre-configured databases
              </p>
            </div>

            <div className="space-y-3">
              {sampleDatabases.map((database) => (
                <div
                  key={database.id}
                  onClick={() =>
                    !isLoading &&
                    onSubmit({ type: "test", databaseId: database.id })
                  }
                  className={`
            group relative p-4 rounded-lg border border-gray-100 dark:border-gray-800 
            ${
              !isLoading
                ? "hover:border-blue-200 dark:hover:border-blue-800 cursor-pointer"
                : "cursor-not-allowed opacity-50"
            }
            transition-all duration-200 hover:shadow-sm bg-gray-50/50 dark:bg-gray-900/50
          `}
                >
                  <div className="flex items-start gap-3">
                    <div className="p-2 rounded-md bg-white dark:bg-gray-800 shadow-sm">
                      <database.icon className="w-4 h-4 text-blue-500" />
                    </div>
                    <div className="flex-1">
                      <div className="flex items-start justify-between">
                        <h3 className="font-medium text-sm text-gray-900 dark:text-gray-100">
                          {database.name}
                        </h3>
                        <span
                          className={`
                          text-[10px] px-2 py-0.5 rounded-full font-medium
                          ${
                            database.complexity === "beginner"
                              ? "bg-green-100 text-green-700 dark:bg-green-900/30 dark:text-green-400"
                              : "bg-orange-100 text-orange-700 dark:bg-orange-900/30 dark:text-orange-400"
                          }
                        `}
                        >
                          {database.complexity.charAt(0).toUpperCase() +
                            database.complexity.slice(1)}
                        </span>
                      </div>
                      <div className="flex items-center gap-2 mt-1">
                        <span className="text-xs text-gray-500">
                          {database.tag}
                        </span>
                        <span className="text-gray-300 dark:text-gray-600">
                          •
                        </span>
                        <span className="text-xs text-gray-500">
                          {database.stats.tables} tables
                        </span>
                      </div>
                      <p className="text-xs text-gray-600 dark:text-gray-300 mt-2 line-clamp-2">
                        {database.description}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-3">
                    <span className="text-xs text-blue-600 dark:text-blue-400 flex items-center gap-1 font-medium">
                      {isLoading ? "Connecting..." : "Try instantly"}
                      <ArrowRight className="w-3 h-3 transition-transform group-hover:translate-x-0.5" />
                    </span>
                    <a
                      href={database.documentation}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => e.stopPropagation()}
                      className="text-xs text-gray-500 hover:text-gray-700 flex items-center gap-1"
                    >
                      <BookOpen className="w-3 h-3" />
                      Docs
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Right Side - Connection Form */}
        <div className="flex-1">
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Info Banner */}
            <div className="relative overflow-hidden rounded-lg bg-gradient-to-r from-blue-50 to-indigo-50 dark:from-blue-900/20 dark:to-indigo-900/20 p-6 border border-blue-100 dark:border-blue-800">
              <h3 className="text-sm font-medium text-blue-900 dark:text-blue-100 mb-2">
                About Your Connection
              </h3>
              <p className="text-sm text-blue-700 dark:text-blue-300 leading-relaxed">
                We only analyze your schema to enhance query suggestions. Your
                data stays secure in your database—we never store or access the
                actual data.
              </p>
            </div>

            {/* Main Connection Section */}
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label
                    className="text-sm text-gray-600 dark:text-gray-400"
                    htmlFor="host"
                  >
                    Host
                  </Label>
                  <Input
                    id="host"
                    value={formData.host}
                    onChange={(e) =>
                      setFormData({ ...formData, host: e.target.value })
                    }
                    className="h-9"
                    placeholder="e.g., localhost or db.example.com"
                    required
                  />
                </div>
                <div className="space-y-2">
                  <Label
                    className="text-sm text-gray-600 dark:text-gray-400"
                    htmlFor="port"
                  >
                    Port
                  </Label>
                  <Input
                    id="port"
                    value={formData.port}
                    onChange={(e) =>
                      setFormData({ ...formData, port: e.target.value })
                    }
                    className="h-9"
                    placeholder="5432"
                    required
                  />
                </div>
              </div>
            </div>

            {/* Database and Schema Section */}
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label
                    className="text-sm text-gray-600 dark:text-gray-400"
                    htmlFor="database"
                  >
                    Database Name
                  </Label>
                  <Input
                    id="database"
                    value={formData.database}
                    onChange={(e) =>
                      setFormData({ ...formData, database: e.target.value })
                    }
                    className="h-9"
                    placeholder="e.g., myapp_db"
                    required
                  />
                </div>
                <div className="space-y-2">
                  <Label
                    className="text-sm text-gray-600 dark:text-gray-400"
                    htmlFor="schema"
                  >
                    Schema
                  </Label>
                  <Input
                    id="schema"
                    value={formData.schema}
                    onChange={(e) =>
                      setFormData({ ...formData, schema: e.target.value })
                    }
                    className="h-9"
                    placeholder="public"
                    required
                  />
                  <span className="text-xs text-gray-500">
                    Default schema is "public"
                  </span>
                </div>
              </div>
            </div>

            {/* Authentication Section */}
            <div>
              <div className="space-y-2">
                <div className="space-y-2">
                  <Label
                    className="text-sm text-gray-600 dark:text-gray-400"
                    htmlFor="username"
                  >
                    Username
                  </Label>
                  <Input
                    id="username"
                    value={formData.username}
                    onChange={(e) =>
                      setFormData({ ...formData, username: e.target.value })
                    }
                    className="h-9"
                    placeholder="Database user"
                    required
                  />
                </div>
                <div className="space-y-2">
                  <Label
                    className="text-sm text-gray-600 dark:text-gray-400"
                    htmlFor="password"
                  >
                    Password
                  </Label>
                  <Input
                    id="password"
                    type="password"
                    value={formData.password}
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                    className="h-9"
                    required
                  />
                </div>
              </div>
            </div>

            {/* Optional Settings */}
            <div>
              <div className="space-y-2">
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label
                      className="text-sm text-gray-600 dark:text-gray-400"
                      htmlFor="projectId"
                    >
                      Project ID
                      <span className="ml-2 text-xs text-gray-400">
                        (Optional)
                      </span>
                    </Label>
                    <Input
                      id="projectId"
                      value={
                        formData.projectId ||
                        `proj-${Math.random().toString(36).substr(2, 9)}`
                      }
                      onChange={(e) =>
                        setFormData({ ...formData, projectId: e.target.value })
                      }
                      className="h-9"
                      placeholder="Auto-generated ID"
                    />
                  </div>
                  <div className="space-y-2">
                    <Label
                      className="text-sm text-gray-600 dark:text-gray-400"
                      htmlFor="datasetId"
                    >
                      Dataset ID
                      <span className="ml-2 text-xs text-gray-400">
                        (Optional)
                      </span>
                    </Label>
                    <Input
                      id="datasetId"
                      value={formData.datasetId}
                      onChange={(e) =>
                        setFormData({ ...formData, datasetId: e.target.value })
                      }
                      className="h-9"
                      placeholder="Custom identifier"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between p-4 rounded-lg bg-gray-50 dark:bg-gray-800/50">
                  <div className="flex items-center space-x-3">
                    <Shield className="w-4 h-4 text-gray-500" />
                    <Label
                      className="text-sm text-gray-600 dark:text-gray-400"
                      htmlFor="ssl"
                    >
                      Enable SSL Connection
                    </Label>
                  </div>
                  <Switch
                    id="ssl"
                    checked={formData.ssl}
                    onCheckedChange={(checked) =>
                      setFormData({ ...formData, ssl: checked })
                    }
                  />
                </div>
              </div>
            </div>

            {/* Action Buttons */}
            <DialogFooter className="pt-6 border-t border-gray-100 dark:border-gray-800">
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center text-xs text-gray-500">
                  <AlertCircle className="w-3 h-3 mr-1" />
                  Ensure database user has schema read access
                </div>
                <div className="space-x-2">
                  <Button type="button" variant="ghost" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button type="submit">Connect Database</Button>
                </div>
              </div>
            </DialogFooter>
          </form>
        </div>
      </div>
    );
  }

  if (warehouseId === "supabase") {
    return (
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="projectUrl">Project URL</Label>
          <Input
            id="projectUrl"
            value={formData.projectUrl}
            onChange={(e) =>
              setFormData({ ...formData, projectUrl: e.target.value })
            }
            required
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="apiKey">API Key</Label>
          <Input
            id="apiKey"
            value={formData.apiKey}
            onChange={(e) =>
              setFormData({ ...formData, apiKey: e.target.value })
            }
            required
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="dbPassword">Database Password</Label>
          <Input
            id="dbPassword"
            type="password"
            value={formData.dbPassword}
            onChange={(e) =>
              setFormData({ ...formData, dbPassword: e.target.value })
            }
            required
          />
        </div>
        <DialogFooter>
          <Button type="button" variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Connect</Button>
        </DialogFooter>
      </form>
    );
  }

  if (warehouseId === "neon") {
    return (
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="connectionString">Connection String</Label>
          <Input
            id="connectionString"
            value={formData.connectionString}
            onChange={(e) =>
              setFormData({ ...formData, connectionString: e.target.value })
            }
            required
            placeholder="postgres://user:password@host:port/database"
          />
          <p className="text-sm text-gray-500">
            Find this in your Neon dashboard under Connection Details
          </p>
        </div>
        <DialogFooter>
          <Button type="button" variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Connect</Button>
        </DialogFooter>
      </form>
    );
  }

  return null;
};

const WarehouseCard = ({ provider, onSelect, isSelected }) => {
  const Icon = provider.icon;

  return (
    <div
      onClick={() => provider.available && onSelect(provider.id)}
      className={`
        relative overflow-hidden rounded-xl p-6 transition-all duration-300
        ${
          provider.available
            ? "cursor-pointer hover:shadow-lg hover:scale-[1.02] active:scale-[0.99]"
            : "opacity-75"
        }
        ${
          isSelected
            ? "ring-2 ring-blue-500 shadow-lg bg-blue-50 dark:bg-blue-900/20"
            : "border border-gray-100 dark:border-gray-800 hover:border-blue-100 dark:hover:border-blue-900/50"
        }
        bg-gradient-to-br ${provider.bgClass}
      `}
    >
      {/* Sample Database Badge - Only show for PostgreSQL */}
      {provider.id === "postgres" && (
        <div className="absolute top-1 right-0 mt-4 mr-4">
          <span className="inline-flex items-center px-3 py-1.5 rounded-full text-xs font-medium bg-blue-50 text-blue-600 dark:bg-blue-900/30 dark:text-blue-400 border border-blue-100 dark:border-blue-800 shadow-sm">
            <Database className="w-3.5 h-3.5 mr-1.5" />
            Try Sample Database
          </span>
        </div>
      )}

      {/* Background Icon */}
      <div className="absolute right-0 bottom-0 opacity-[0.07] transform translate-x-1/4 translate-y-1/4 transition-transform group-hover:scale-110">
        <Icon className="w-48 h-48" />
      </div>

      {/* Content */}
      <div className="relative z-10">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <div className="p-2 rounded-lg bg-white dark:bg-gray-800 mr-3">
              <Icon className="w-5 h-5" />
            </div>
            <div>
              <h3 className="font-medium text-gray-900 dark:text-gray-100">
                {provider.name}
              </h3>
              {provider.tag && (
                <span className="text-[10px] font-medium tracking-wider text-gray-500 dark:text-gray-400">
                  {provider.tag}
                </span>
              )}
            </div>
          </div>
          {!provider.available && (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-200">
              Coming Soon
            </span>
          )}
        </div>

        <p className="text-sm text-gray-600 dark:text-gray-300 line-clamp-2">
          {provider.description}
        </p>

        {provider.available && (
          <div className="mt-4 flex items-center justify-between">
            <div className="flex items-center text-sm text-blue-600 dark:text-blue-400">
              Connect
              <ArrowRight className="w-4 h-4 ml-1 transition-transform group-hover:translate-x-1" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ConnectWarehouse: React.FC = () => {
  const navigate = useNavigate();
  const { currentStep, updateStep } = useSetup();
  const { teamId, userId, updateWarehouseDetails } = useTeam();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedProvider, setSelectedProvider] = useState<string | null>(null);
  const [showConnectionModal, setShowConnectionModal] = useState(false);

  useEffect(() => {
    if (currentStep !== "connect_warehouse") {
      navigate(`/setup/${currentStep}`);
    }
  }, [currentStep, navigate]);

  const handleGoogleAuth = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const authUrl = await setupService.initiateGoogleAuth(
        teamId as string,
        userId as string
      );
      window.location.href = authUrl;
    } catch (err) {
      console.error("Failed to initiate Google authentication:", err);
      setError("Failed to start Google authentication. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleConnectionSubmit = async (data: any) => {
    setIsLoading(true);
    setError(null);
    try {
      // Check if this is a test database connection
      if (data.type === "test") {
        const response = await setupService.connectTestWarehouse(
          teamId as string,
          {
            databaseId: data.databaseId,
          }
        );

        updateWarehouseDetails({
          projectId: response.projectId,
          datasetId: response.datasetId,
        });

        await updateStep(response.nextStep, null);
        navigate(`/setup/${response.nextStep}`);
      } else {
        // Handle regular database connection
        const response = await setupService.connectWarehouse(teamId as string, {
          warehouseType: selectedProvider?.toLowerCase() as string,
          connectionDetails: data,
        });

        updateWarehouseDetails({
          projectId: response.projectId,
          datasetId: response.datasetId,
        });

        await updateStep(response.nextStep, null);
        navigate(`/setup/${response.nextStep}`);
      }
    } catch (err) {
      console.error("Failed to connect:", err);
      setError(
        data.type === "test"
          ? "Failed to connect to the test database. Please try again."
          : "Failed to connect to the database. Please check your credentials."
      );
    } finally {
      setIsLoading(false);
      setShowConnectionModal(false);
    }
  };

  const handleProviderSelect = (providerId: string) => {
    setSelectedProvider(providerId);
    if (providerId === "bigquery") {
      handleGoogleAuth();
    } else if (["postgres", "supabase", "neon"].includes(providerId)) {
      setShowConnectionModal(true);
    }
  };

  return (
    <>
      <Card className="max-w-6xl mx-auto">
        <CardHeader className="pb-8">
          <div className="space-y-2">
            <CardTitle className="text-3xl font-normal text-gray-900 dark:text-gray-100">
              Select your data source
            </CardTitle>
            <p className="mt-2 text-base text-gray-600 dark:text-gray-400">
              Connect with your preferred database to start analyzing your data
            </p>
          </div>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {providers.map((provider) => (
              <WarehouseCard
                key={provider.id}
                provider={provider}
                onSelect={handleProviderSelect}
                isSelected={selectedProvider === provider.id}
              />
            ))}
          </div>
          {error && (
            <div className="mt-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded-md flex items-center">
              <AlertCircle className="mr-2 h-5 w-5" />
              <p>{error}</p>
            </div>
          )}
        </CardContent>
      </Card>

      <Dialog open={showConnectionModal} onOpenChange={setShowConnectionModal}>
        <DialogContent
          className={`${
            selectedProvider === "postgres"
              ? "sm:max-w-[1000px]"
              : "sm:max-w-[625px]"
          }`}
        >
          <DialogHeader>
            <DialogTitle>
              Connect to{" "}
              {providers.find((w) => w.id === selectedProvider)?.name}
            </DialogTitle>
          </DialogHeader>
          <ConnectionForm
            warehouseId={selectedProvider || ""}
            onSubmit={handleConnectionSubmit}
            onCancel={() => setShowConnectionModal(false)}
            isLoading={isLoading}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConnectWarehouse;
