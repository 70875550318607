import React, { memo, useState } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Bell, 
  Play, 
  Pause, 
  Settings, 
  ChevronDown, 
  ChevronUp,
  Clock,
  Mail,
  MessageSquare,
} from 'lucide-react';
import { Button } from '@/components/Common/Button';
import { Switch } from '@/components/Common';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/Common/Tooltip';
import { Badge } from '@/components/Common/Badge';


interface MonitoringNodeData {
  name: string;
  description: string;
  query: string;
  isActive: boolean;
  affectedTables: string[];
  frequency: string;
  lastCheck?: string;
  nextCheck?: string;
  emailEnabled: boolean;
  slackEnabled: boolean;
  // Callback functions for interactions
  onEdit?: (id: string) => void;
  onToggleActive?: (id: string, active: boolean) => void;
}

const MonitoringNode = memo(({ data, selected, id }: NodeProps<MonitoringNodeData>) => {
  // Local state for expand/collapse functionality
  const [isExpanded, setIsExpanded] = useState(true);
  
  const formatQuery = (query: string) => {
    return isExpanded ? query : query.slice(0, 100) + '...';
  };

  const handleEdit = (event: React.MouseEvent) => {
    console.log('data.onEdit', data.onEdit ,'id', id);
    event.stopPropagation(); // Prevent node selection
    if (data.onEdit) {
      data.onEdit(id);
    }
  };

  const formatNextCheck = (nextCheck: string) => {
    if (!nextCheck) return 'Not scheduled';
    const date = new Date(nextCheck);
    const diff = date.getTime() - Date.now();
    const minutes = Math.floor(diff / 60000);
    if (minutes < 1) return 'Less than a minute';
    if (minutes < 60) return `${minutes} minutes`;
    return `${Math.floor(minutes / 60)} hours`;
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{
        opacity: 1,
        scale: 1,
        transition: { duration: 0.2 }
      }}
      className={`
        bg-white border-2 rounded-lg shadow-lg min-w-[300px] max-w-[400px]
        ${selected ? 'ring-2 ring-purple-500 ring-offset-2' : ''}
        ${data.isActive ? 'border-purple-500' : 'border-gray-300'}
      `}
    >
      {/* Monitor Header */}
      <div className="p-3 border-b bg-purple-50 rounded-t-lg">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Bell className="h-4 w-4 text-purple-500" />
            <span className="font-medium text-sm">{data.name}</span>
            {data.isActive && (
              <Badge variant="outline" className="bg-purple-100 text-purple-700">
                Active
              </Badge>
            )}
          </div>
          <div className="flex items-center gap-2">
            {/* Settings Button */}
            {/*
            
            todo: whats wrong?!
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="sm"
                  className="h-6 w-6 p-0 hover:bg-purple-100"
                  onClick={handleEdit}
                >
                  <Settings className="h-3 w-3 text-purple-500" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Edit monitor settings</TooltipContent>
            </Tooltip> */}

            {/* Active/Inactive Toggle */}
            <Tooltip>
              <TooltipTrigger asChild>
                <Switch
                className='bg-gray-200'
                  checked={data.isActive}
                  onCheckedChange={(checked) => data.onToggleActive?.(id, checked)}
                  size="sm"
                />
              </TooltipTrigger>
              <TooltipContent>
                {data.isActive ? 'Disable monitor' : 'Enable monitor'}
              </TooltipContent>
            </Tooltip>

            {/* Expand/Collapse Button */}
            <Button
              variant="ghost"
              size="sm"
              className="h-6 w-6 p-0 hover:bg-purple-100"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? (
                <ChevronUp className="h-3 w-3" />
              ) : (
                <ChevronDown className="h-3 w-3" />
              )}
            </Button>
          </div>
        </div>
      </div>

      {/* Monitor Content */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className="overflow-hidden"
          >
            <div className="p-3 space-y-3">
              {/* Description */}
              <div className="text-xs text-gray-600">{data.description}</div>
              
              {/* Query Preview */}
              <div className="space-y-2">
                <div className="text-xs font-medium">Monitoring Query:</div>
                <div className="bg-gray-50 p-2 rounded text-xs font-mono overflow-x-auto">
                  {formatQuery(data.query)}
                </div>
              </div>

              {/* Status Information */}
              <div className="flex items-center justify-between text-xs text-gray-500">
                <div className="flex items-center gap-2">
                  <Clock className="h-3 w-3" />
                  <span>Every {data.frequency}</span>
                </div>
                <div className="flex items-center gap-2">
                  {data.emailEnabled && (
                    <Tooltip>
                      <TooltipTrigger>
                        <Mail className="h-3 w-3" />
                      </TooltipTrigger>
                      <TooltipContent>Email alerts enabled</TooltipContent>
                    </Tooltip>
                  )}
                  {data.slackEnabled && (
                    <Tooltip>
                      <TooltipTrigger>
                        <MessageSquare className="h-3 w-3" />
                      </TooltipTrigger>
                      <TooltipContent>Slack alerts enabled</TooltipContent>
                    </Tooltip>
                  )}
                </div>
              </div>

              {/* Affected Tables */}
              <div className="border-t pt-2">
                <div className="text-xs font-medium mb-1">Monitored Tables:</div>
                <div className="flex flex-wrap gap-1">
                  {data.affectedTables.map((table) => (
                    <span
                      key={table}
                      className="text-xs bg-purple-100 text-purple-700 px-2 py-0.5 rounded-full"
                    >
                      {table}
                    </span>
                  ))}
                </div>
              </div>

              {/* Next Check Time */}
              {data.nextCheck && (
                <div className="text-xs text-gray-500 border-t pt-2">
                  Next check in: {formatNextCheck(data.nextCheck)}
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Flow Handles */}
      <Handle
        type="target"
        position={Position.Left}
        className="w-2 h-2 border-2 bg-purple-500 !opacity-0 hover:!opacity-100 transition-opacity"
      />
      <Handle
        type="source"
        position={Position.Right}
        className="w-2 h-2 border-2 bg-purple-500 !opacity-0 hover:!opacity-100 transition-opacity"
      />
    </motion.div>
  );
});

export default MonitoringNode;