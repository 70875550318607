import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { Node, Edge } from "reactflow";
import { useSchemaAnalyzer } from "@/hooks/useSchemaAnalyzer";

type DatabaseType = "postgres";
type LLMProvider = "openai" | "anthropic" | "cohere" | "mistral";

interface ChatMessage {
  role: "user" | "assistant";
  content: string;
}

interface ChatState {
  messages: ChatMessage[];
  isLoading: boolean;
}

export interface DatabaseConnection {
  type: DatabaseType;
  host?: string;
  port?: number;
  database?: string;
  username?: string;
  password?: string;
  schema?: string;
  sslMode?: string;
}

interface LLMConfig {
  provider: LLMProvider;
  apiKey?: string;
  model?: string;
  enabled: boolean;
  temperature?: number;
  maxTokens?: number;
}

interface PanelState {
  isLeftPanelOpen: boolean;
  isRightPanelOpen: boolean;
  isChatOpen: boolean;
  leftPanelWidth: number;
  rightPanelWidth: number;
  activeLeftTab?: "connect" | "samples";
  activeRightTab?: string;
}

interface SchemaState {
  nodes: Node[];
  edges: Edge[];
  tables: any[];
  relationships: any[];
  junctionTables: string[];
  views: { name: string; definition: string }[];
  error: string | null;
  lastUpdated?: Date;
  highlightedNodes: string[];
  highlightedEdges: string[];
}

const STORAGE_KEYS = {
  PANELS: "playground-panels",
  LLM_CONFIG: "playground-llm-config",
  LAST_CONNECTION: "playground-last-connection",
} as const;

interface PlaygroundContextType {
  // Database Connection State
  connection: DatabaseConnection | null;
  setConnection: (conn: DatabaseConnection | null) => void;
  lastSuccessfulConnection: DatabaseConnection | null;

  // Schema/Visualization State
  schema: SchemaState | null;
  setSchema: (schema: Partial<SchemaState>) => void;

  // Search and Filtering
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  filteredNodes: Node[];

  // LLM Configuration
  llmConfig: LLMConfig;
  setLLMConfig: (config: Partial<LLMConfig>) => void;

  // Panel States
  panels: PanelState;
  setPanels: (state: Partial<PanelState>) => void;

  // Canvas State Management
  canvasState: "initial" | "loading" | "ready" | "error";
  setCanvasState: (state: "initial" | "loading" | "ready" | "error") => void;

  // Chat State
  chat: ChatState;
  sendChatMessage: (message: string) => Promise<void>;
  clearChat: () => void;

  // Actions
  connectToDatabase: (conn: DatabaseConnection) => Promise<void>;
  disconnectDatabase: () => void;
  loadTestDatabase: (config: { type: string }) => Promise<void>;
  resetState: () => void;
}

const PlaygroundContext = createContext<PlaygroundContextType | undefined>(
  undefined
);

export const PlaygroundProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const {
    analyze,
    disconnect: disconnectAnalyzer,
    isLoading,
  } = useSchemaAnalyzer();

  // State management with localStorage persistence
  const [connection, setConnection] = useState<DatabaseConnection | null>(null);
  const [lastSuccessfulConnection, setLastSuccessfulConnection] =
    useState<DatabaseConnection | null>(() => {
      const saved = localStorage.getItem(STORAGE_KEYS.LAST_CONNECTION);
      return saved ? JSON.parse(saved) : null;
    });

  const [schema, setSchema] = useState<SchemaState | null>(null);

  const [searchTerm, setSearchTerm] = useState("");

  const [llmConfig, setLLMConfig] = useState<LLMConfig>(() => {
    const saved = localStorage.getItem(STORAGE_KEYS.LLM_CONFIG);
    return saved
      ? JSON.parse(saved)
      : {
          provider: "openai",
          enabled: false,
          temperature: 0.7,
          maxTokens: 2000,
        };
  });

  const [panels, setPanels] = useState<PanelState>(() => {
    const saved = localStorage.getItem(STORAGE_KEYS.PANELS);
    return saved
      ? JSON.parse(saved)
      : {
          isLeftPanelOpen: true,
          isRightPanelOpen: false,
          leftPanelWidth: 300,
          rightPanelWidth: 300,
          activeLeftTab: "samples",
        };
  });

  const setHighlightedElements = useCallback(
    (nodes: string[], edges: string[]) => {
      setSchema((prev) => ({
        ...prev,
        highlightedNodes: nodes,
        highlightedEdges: edges,
      }));
    },
    []
  );

  const [canvasState, setCanvasState] = useState<
    "initial" | "loading" | "ready" | "error"
  >("initial");

  const [chat, setChat] = useState<ChatState>({
    messages: [],
    isLoading: false,
  });

  // Persist state changes to localStorage
  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.PANELS, JSON.stringify(panels));
  }, [panels]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.LLM_CONFIG, JSON.stringify(llmConfig));
  }, [llmConfig]);

  useEffect(() => {
    if (connection) {
      localStorage.setItem(
        STORAGE_KEYS.LAST_CONNECTION,
        JSON.stringify(connection)
      );
    }
  }, [connection]);

  // Calculate filtered nodes based on search term
  const filteredNodes = React.useMemo(() => {
    if (!searchTerm || !schema?.nodes) return schema?.nodes || [];

    return schema.nodes.filter((node) => {
      const matchesName = node.data.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const matchesColumns = node.data.columns.some(
        (col) =>
          col.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          col.type.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return matchesName || matchesColumns;
    });
  }, [schema?.nodes, searchTerm]);

  // Enhanced database connection handling
  const connectToDatabase = useCallback(
    async (conn: DatabaseConnection) => {
      try {
        setCanvasState("loading");

        const result = await analyze({
          type: conn.type,
          host: conn.host,
          port: conn.port,
          database: conn.database,
          username: conn.username,
          password: conn.password,
          schema: conn.schema,
          sslMode: "verify-full",
        });

        setConnection(conn);
        setLastSuccessfulConnection(conn);
        setSchema(result);
        setCanvasState("ready");
      } catch (error) {
        setSchema(null);
        setCanvasState("error");
        throw error;
      }
    },
    [analyze]
  );

  const disconnectDatabase = useCallback(async () => {
    try {
      await disconnectAnalyzer();
      setConnection(null);
      setSchema(null);
      setCanvasState("initial");
      setSearchTerm("");
    } catch (error) {
      console.error("Error disconnecting:", error);
    }
  }, [disconnectAnalyzer]);

  const loadTestDatabase = useCallback(
    async (config: { type: string }) => {
      try {
        await connectToDatabase(config);
      } catch (error) {
        setCanvasState("error");
        throw error;
      }
    },
    [connectToDatabase]
  );

  const resetState = useCallback(() => {
    disconnectDatabase();
    setLLMConfig({
      provider: "openai",
      enabled: false,
      temperature: 0.7,
      maxTokens: 2000,
    });
    setPanels({
      isLeftPanelOpen: true,
      isRightPanelOpen: true,
      leftPanelWidth: 300,
      rightPanelWidth: 300,
    });
    localStorage.clear();
  }, [disconnectDatabase]);

  const sendChatMessage = useCallback(
    async (message: string) => {
      if (!connection) return;

      setChat((prev) => ({
        ...prev,
        isLoading: true,
        messages: [...prev.messages, { role: "user", content: message }],
      }));

      try {
        const response = await playgroundService.chat(
          connection,
          message,
          chat.messages
        );

        setChat((prev) => ({
          isLoading: false,
          messages: [
            ...prev.messages,
            { role: "assistant", content: response.content },
          ],
        }));

        // If it's a query response, update the visualization
        if (response.type === "query" && response.metadata?.query) {
          setSchema((prev) => ({
            ...prev,
            // Update visualization based on query result
          }));
        }
      } catch (error) {
        setChat((prev) => ({
          isLoading: false,
          messages: [
            ...prev.messages,
            {
              role: "assistant",
              content: "Sorry, I encountered an error. Please try again.",
            },
          ],
        }));
      }
    },
    [connection, chat.messages]
  );

  const clearChat = useCallback(() => {
    setChat({
      messages: [],
      isLoading: false,
    });
  }, []);

  const value = {
    connection,
    setConnection,
    lastSuccessfulConnection,
    schema,
    setSchema,
    searchTerm,
    setSearchTerm,
    filteredNodes,
    llmConfig,
    setLLMConfig,
    panels,
    setPanels,
    canvasState,
    setCanvasState,
    connectToDatabase,
    disconnectDatabase,
    loadTestDatabase,
    resetState,
    chat,
    sendChatMessage,
    clearChat,
    setHighlightedElements,
  };

  return (
    <PlaygroundContext.Provider value={value}>
      {children}
    </PlaygroundContext.Provider>
  );
};

export const usePlayground = () => {
  const context = useContext(PlaygroundContext);
  if (context === undefined) {
    throw new Error("usePlayground must be used within a PlaygroundProvider");
  }
  return context;
};
