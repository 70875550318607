import { Slack, MessageCircle } from "lucide-react";
import { Button } from "@/components/Common";

const AutomationBanner = () => (
  <div className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-lg p-4 mb-6">
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-4">
        <div>
          <h3 className="font-medium">Automated Insights</h3>
          <p className="text-sm text-gray-500">
            Set up automated query runs and get results delivered
          </p>
        </div>
      </div>
      <div className="flex gap-2">
        <Button variant="outline" size="sm" disabled className="opacity-60">
          <Slack className="w-4 h-4 mr-2" />
          Slack
        </Button>
        <Button variant="outline" size="sm" disabled className="opacity-60">
          <MessageCircle className="w-4 h-4 mr-2" />
          Email
        </Button>
      </div>
    </div>
  </div>
);

export default AutomationBanner;
