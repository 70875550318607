import {
  FaGoogle,
  FaSnowflake,
  FaAmazon,
  FaMicrosoft,
  FaDatabase,
} from "react-icons/fa";
import { SiSupabase, SiAirtable, SiPostgresql } from "react-icons/si";

const ClickhouseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M21.333 10H24v4h-2.667ZM16 1.335h2.667v21.33H16Zm-5.333 0h2.666v21.33h-2.666ZM0 22.665V1.335h2.667v21.33zm5.333-21.33H8v21.33H5.333Z"
    ></path>
  </svg>
);

const NeonIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 100 100"
    fill="currentColor"
  >
    <path d="M73.315 36.5c-.879-13.496-12.646-24.167-27.315-24.167-15.162 0-27.316 11.367-27.316 25.417 0 11.667 8.673 21.433 20.686 24.208l.676.208v18.75l17.901-18.75c8.878-2.142 15.582-9.142 15.582-17.75 0-2.808-.758-5.433-2.072-7.791L73.315 36.5z"/>
    <path d="M55.154 95.833a4.167 4.167 0 110-8.333 4.167 4.167 0 010 8.333zM80.154 70.833a4.167 4.167 0 110-8.333 4.167 4.167 0 010 8.333z"/>
    <path d="M71.821 83.166a4.167 4.167 0 110-8.333 4.167 4.167 0 010 8.333z"/>
  </svg>
);

export const warehouses = [
  {
    id: "bigquery",
    name: "Google BigQuery",
    icon: FaGoogle,
    available: true,
    description: "Enterprise data warehouse for large-scale analytics",
    bgClass: "from-blue-50 via-indigo-50 to-red-50",
    tag: "ENTERPRISE"
  },
  {
    id: "postgres",
    name: "PostgreSQL",
    icon: SiPostgresql,
    available: true,
    description: "World's most advanced open source database",
    bgClass: "from-blue-50 via-indigo-50 to-blue-100",
    tag: "POPULAR"
  },
  {
    id: "supabase",
    name: "Supabase",
    icon: SiSupabase,
    available: false,
    description: "Open source Firebase alternative with PostgreSQL",
    bgClass: "from-emerald-50 via-green-50 to-emerald-100",
    tag: "MODERN"
  },
  {
    id: "clickhouse",
    name: "ClickHouse",
    icon: ClickhouseIcon,
    available: false,
    description: "High-performance column-oriented database",
    bgClass: "from-yellow-50 via-amber-50 to-yellow-100",
    tag: "FAST"
  },
  // {
  //   id: "neon",
  //   name: "Neon",
  //   icon: NeonIcon,
  //   available: false,
  //   description: "Serverless Postgres built for the cloud",
  //   bgClass: "from-violet-50 via-purple-50 to-violet-100",
  //   tag: "SERVERLESS"
  // },
  {
    id: "snowflake",
    name: "Snowflake",
    icon: FaSnowflake,
    available: false,
    description: "Cloud data platform for all your data needs",
    bgClass: "from-cyan-50 via-sky-50 to-cyan-100",
    tag: "CLOUD NATIVE"
  },
  // {
  //   id: "redshift",
  //   name: "Amazon Redshift",
  //   icon: FaAmazon,
  //   available: false,
  //   description: "Fast, simple, cost-effective data warehousing",
  //   bgClass: "from-orange-50 via-red-50 to-orange-100",
  //   tag: "AWS NATIVE"
  // },
  {
    id: "airtable",
    name: "Airtable",
    tag: "NO-CODE FRIENDLY",
    description: "Powerful spreadsheet-database hybrid with API access",
    icon: SiAirtable,
    available: false,
    bgClass: "from-teal-50 via-cyan-50 to-teal-100"
  },
];
// {
//   id: "databricks",
//   name: "Databricks",
//   icon: FaDatabase,
//   available: false,
// },
// { id: "azure", name: "Azure Synapse", icon: FaMicrosoft, available: false },
