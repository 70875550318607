import { useState, useCallback, useRef } from "react";
import { Node, Edge, Position } from "reactflow";
import dagre from "dagre";

import { playgroundService } from "@/services/playgroundService";

interface ConnectionConfig {
  type: "postgres";
  host: string;
  port: number;
  database: string;
  username: string;
  password: string;
  sslMode: string;
}

interface TableNode extends Node {
  type: "table" | "junction" | "view";
  data: {
    name: string;
    columns: Array<Column>;
    description?: string;
    relationships?: Array<string>; // Store related table IDs for optimized rendering
    highlighted?: boolean; // For search functionality
  };
}

interface Column {
  name: string;
  type: string;
  isPrimary: boolean;
  isForeign: boolean;
  isNullable: boolean;
  defaultValue?: string;
  description?: string;
}

interface RelationshipEdge extends Edge {
  type: "relationship";
  data: {
    relationshipType: "one-to-one" | "one-to-many" | "many-to-many";
    sourceColumn: string;
    targetColumn: string;
    animated?: boolean;
  };
}

interface SchemaData {
  nodes: TableNode[];
  edges: RelationshipEdge[];
  tables: any[];
  relationships: any[];
  junctionTables: string[];
  views: { name: string; definition: string }[];
}

const LAYOUT_CONFIG = {
  GRID_SPACING: 300,
  VERTICAL_SPACING: 100,
  INITIAL_POSITION: { x: 100, y: 100 },
  NODE_WIDTH: 280,
  NODE_HEIGHT: 200,
};

export const useSchemaAnalyzer = () => {
  const [state, setState] = useState<{
    isLoading: boolean;
    error: string | null;
    data: SchemaData | null;
  }>({
    isLoading: false,
    error: null,
    data: null,
  });

  const progressRef = useRef({ current: 0, total: 0 });

  const calculateOptimalLayout = useCallback(
    (nodes: TableNode[], edges: RelationshipEdge[]) => {
      const g = new dagre.graphlib.Graph();
      g.setGraph({
        rankdir: "LR",
        nodesep: LAYOUT_CONFIG.GRID_SPACING,
        ranksep: LAYOUT_CONFIG.VERTICAL_SPACING,
      });
      g.setDefaultEdgeLabel(() => ({}));

      // Add nodes to the graph
      nodes.forEach((node) => {
        g.setNode(node.id, {
          width: LAYOUT_CONFIG.NODE_WIDTH,
          height: LAYOUT_CONFIG.NODE_HEIGHT,
        });
      });

      // Add edges to the graph
      edges.forEach((edge) => {
        g.setEdge(edge.source, edge.target);
      });

      // Calculate layout
      dagre.layout(g);

      // Apply calculated positions to nodes
      return nodes.map((node) => {
        const nodeWithPosition = g.node(node.id);
        return {
          ...node,
          position: {
            x: nodeWithPosition.x - LAYOUT_CONFIG.NODE_WIDTH / 2,
            y: nodeWithPosition.y - LAYOUT_CONFIG.NODE_HEIGHT / 2,
          },
        };
      });
    },
    []
  );

  const transformToReactFlow = useCallback(
    (
      tables: any[],
      relationships: any[],
      junctionTables: string[]
    ): { nodes: TableNode[]; edges: RelationshipEdge[] } => {
      // Create nodes with initial positions
      const nodes: TableNode[] = tables.map((table, index) => ({
        id: table.name,
        type: junctionTables.includes(table.name) ? "junction" : "table",
        position: LAYOUT_CONFIG.INITIAL_POSITION,
        data: {
          name: table.name,
          columns: table.columns.map((col: any) => ({
            name: col.name,
            type: col.type,
            isPrimary: col.isPrimary,
            isForeign: col.isForeign,
            isNullable: col.isNullable,
            defaultValue: col.defaultValue,
            description: col.description,
          })),
          relationships: relationships
            .filter(
              (rel) =>
                rel.sourceTable === table.name || rel.targetTable === table.name
            )
            .map((rel) =>
              rel.sourceTable === table.name ? rel.targetTable : rel.sourceTable
            ),
        },
      }));

      // Create edges with relationship types
      const edges: RelationshipEdge[] = relationships.map((rel, index) => {
        let relationshipType: "one-to-one" | "one-to-many" | "many-to-many" =
          "one-to-many";

        // Determine relationship type based on table structure
        const sourceTable = tables.find((t) => t.name === rel.sourceTable);
        const targetTable = tables.find((t) => t.name === rel.targetTable);

        if (sourceTable && targetTable) {
          const sourcePK = sourceTable.columns.find(
            (c: any) => c.name === rel.sourceColumn
          )?.isPrimary;
          const targetPK = targetTable.columns.find(
            (c: any) => c.name === rel.targetColumn
          )?.isPrimary;

          if (sourcePK && targetPK) {
            relationshipType = "one-to-one";
          } else if (
            junctionTables.includes(sourceTable.name) ||
            junctionTables.includes(targetTable.name)
          ) {
            relationshipType = "many-to-many";
          }
        }

        return {
          id: `edge-${index}`,
          source: rel.sourceTable,
          target: rel.targetTable,
          type: "relationship",
          animated: relationshipType === "many-to-many",
          data: {
            relationshipType,
            sourceColumn: rel.sourceColumn,
            targetColumn: rel.targetColumn,
          },
        };
      });

      // Calculate optimal layout
      const nodesWithLayout = calculateOptimalLayout(nodes, edges);

      return { nodes: nodesWithLayout, edges };
    },
    [calculateOptimalLayout]
  );

  const analyze = useCallback(
    async (config: ConnectionConfig) => {
      setState((prev) => ({ ...prev, isLoading: true, error: null }));
      progressRef.current = { current: 0, total: 3 }; // Three steps: connection, analysis, transformation

      try {
        // Step 1: Connect and fetch schema
        progressRef.current.current = 1;

        const result = await playgroundService.analyzeSchema(config);

        // Step 2: Analyze relationships and structure
        progressRef.current.current = 2;
        const visualData = transformToReactFlow(
          result.tables,
          result.relationships,
          result.junctionTables
        );

        // Step 3: Transform and optimize
        progressRef.current.current = 3;
        const schemaData = {
          nodes: visualData.nodes,
          edges: visualData.edges,
          tables: result.tables,
          relationships: result.relationships,
          junctionTables: result.junctionTables,
          views: result.views,
        };

        setState({
          isLoading: false,
          error: null,
          data: schemaData,
        });

        return schemaData;
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : "Failed to analyze database";
        setState((prev) => ({
          ...prev,
          isLoading: false,
          error: errorMessage,
          data: null,
        }));
        throw error;
      }
    },
    [transformToReactFlow]
  );

  const disconnect = useCallback(() => {
    setState({
      isLoading: false,
      error: null,
      data: null,
    });
    progressRef.current = { current: 0, total: 0 };
  }, []);

  return {
    analyze,
    disconnect,
    isLoading: state.isLoading,
    error: state.error,
    data: state.data,
    progress: progressRef.current,
  };
};
