import { useEffect, useRef, useState, useCallback } from "react";
import io, { Socket } from "socket.io-client";
import { API_URL } from "@/config/index";

export interface ProcessingStep {
  step: string;
  details: any;
  timestamp: Date;
  status: "pending" | "active" | "completed" | "error";
}

interface UseSocketConnectionProps {
  teamId: string;
  datasetId: string;
  userId: string;
  onStepUpdate?: (step: ProcessingStep) => void;
  onResponse?: (response: any) => void;
  onError?: (error: any) => void;
}

export const useSocketConnection = ({
  teamId,
  datasetId,
  userId,
  onStepUpdate,
  onResponse,
  onError,
}: UseSocketConnectionProps) => {
  const socketRef = useRef<Socket>();
  const [isConnected, setIsConnected] = useState(false);
  const [isReconnecting, setIsReconnecting] = useState(false);
  const reconnectAttempts = useRef(0);
  const MAX_RECONNECT_ATTEMPTS = 3;

  const connect = useCallback(() => {
    socketRef.current = io(API_URL, {
      path: "/chat/",
      reconnection: true,
      transports: ["websocket", "polling"],
      reconnectionAttempts: MAX_RECONNECT_ATTEMPTS,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      timeout: 20000,
      withCredentials: true,
      auth: {
        teamId,
        datasetId,
        userId,
      },
    });

    socketRef.current.on("connect", () => {
      console.log("Socket connected, attempting to join room with:", {
        teamId,
        datasetId,
        userId,
      });

      setIsConnected(true);
      setIsReconnecting(false);
      reconnectAttempts.current = 0;

      // Join room with proper object structure
      socketRef.current?.emit("join_room", {
        teamId,
        datasetId,
        userId,
      });
    });

    socketRef.current.on("join_room", (response) => {
      console.log("Received join_room response:", response);
    });

    socketRef.current.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
      onError?.({
        message: `Connection error: ${error.message}`,
      });
    });

    socketRef.current.on("connect_timeout", () => {
      console.error("Socket connection timeout");
      onError?.({
        message: "Connection timeout. Please try again.",
      });
    });

    socketRef.current.on("disconnect", () => {
      setIsConnected(false);
    });

    socketRef.current.on("reconnecting", () => {
      setIsReconnecting(true);
      reconnectAttempts.current += 1;
    });

    socketRef.current.on("reconnect_failed", () => {
      setIsReconnecting(false);
      onError?.({
        message: "Failed to reconnect to server. Please refresh the page.",
      });
    });

    socketRef.current.on("processing_step", (stepUpdate: ProcessingStep) => {
      onStepUpdate?.(stepUpdate);
    });

    socketRef.current.on("chat_response", (response: any) => {
      onResponse?.(response);
    });

    socketRef.current.on("error", (error: any) => {
      onError?.(error);
    });
  }, [teamId, datasetId, userId, onStepUpdate, onResponse, onError]);

  useEffect(() => {
    connect();
    return () => {
      socketRef.current?.disconnect();
    };
  }, [connect]);

  const sendMessage = useCallback(
    async (message: string, data: {conversationHistory: any, context?: any} ) => {
      // TODO:
      // if (!isConnected) {
      //   // Fallback to HTTP if socket is not connected
      //   try {
      //     const response = await dataService.chat({
      //       message,
      //       teamId,
      //       datasetId,
      //       context,
      //     });
      //     onResponse?.(response);
      //     return response;
      //   } catch (error) {
      //     onError?.(error);
      //     throw error;
      //   }
      // }
      const {context, conversationHistory} = data
      return new Promise((resolve, reject) => {
        socketRef.current?.emit(
          "chat_message",
          {
            message,
            teamId,
            datasetId,
            userId,
            context,
            conversationHistory,
          },
          (response: any) => {
            if (response.error) {
              reject(response.error);
            } else {
              resolve(response);
            }
          }
        );
      });
    },
    [isConnected, teamId, datasetId, userId, onResponse, onError]
  );

  return {
    isConnected,
    isReconnecting,
    sendMessage,
  };
};
