import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetup, SetupStep } from "../context/SetupContext";
import { useTeam } from "../context/TeamContext";
import { setupService } from "../services/setupService";
import { FaBrain, FaGoogle } from "react-icons/fa";
import { SiOpenai } from "react-icons/si";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../components/Common/Card";
import { Button } from "../components/Common/Button";
import {
  AlertCircle,
  ArrowRight,
  Eye,
  EyeOff,
  Lock,
  Check,
  Sparkles,
  Loader2,
} from "lucide-react";
import { useAuth } from "@/context/AuthContext";
import { Input, Label } from "@/components/Common";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/Common";

enum ModelType {
  OpenAI = "openai",
  WaveQuery = "wavequery",
  Anthropic = "anthropic",
  Gemini = "gemini",
}

const models = [
  {
    id: ModelType.OpenAI,
    name: "OpenAI",
    icon: SiOpenai,
    available: true,
    tag: "RECOMMENDED",
    description:
      "Best-in-class language models for natural language processing",
    bgClass: "from-green-50 via-emerald-50 to-green-100",
  },
  {
    id: ModelType.Anthropic,
    name: "Anthropic Claude",
    icon: FaBrain,
    available: false,
    tag: "",
    description: "Advanced AI model with enhanced reasoning capabilities",
    bgClass: "from-purple-50 via-violet-50 to-purple-100",
  },
  {
    id: ModelType.Gemini,
    name: "Google Gemini",
    icon: FaGoogle,
    available: false,
    tag: "",
    description: "Google's next-generation multimodal AI model",
    bgClass: "from-blue-50 via-red-50 to-yellow-100",
  },
];

const LoadingOverlay = ({ isLoading, currentStep }) => {
  if (!isLoading) return null;

  const steps = [
    { id: "connecting", label: "Connecting to OpenAI" },
    { id: "discovering", label: "Discovering Data Relationships" },
    { id: "finalizing", label: "Finalizing Setup" },
  ];

  return (
    <div className="absolute inset-0 bg-white/80 dark:bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center">
      <div className="w-full max-w-sm p-6 space-y-6">
        <div className="space-y-8">
          {steps.map((step, index) => {
            const isActive = currentStep === step.id;
            const isComplete =
              steps.findIndex((s) => s.id === currentStep) > index;

            return (
              <div key={step.id} className="flex items-center gap-3">
                <div className="relative flex-shrink-0">
                  {isComplete ? (
                    <div className="w-8 h-8 rounded-full bg-green-500 flex items-center justify-center">
                      <Check className="w-5 h-5 text-white" />
                    </div>
                  ) : isActive ? (
                    <div className="w-8 h-8 rounded-full border-4 border-blue-500 border-t-transparent animate-spin" />
                  ) : (
                    <div className="w-8 h-8 rounded-full border-2 border-gray-200 dark:border-gray-700 flex items-center justify-center">
                      <span className="text-sm text-gray-500">{index + 1}</span>
                    </div>
                  )}
                </div>
                <div className="flex-1">
                  <p
                    className={`text-sm font-medium ${
                      isActive
                        ? "text-blue-600 dark:text-blue-400"
                        : isComplete
                        ? "text-green-600 dark:text-green-400"
                        : "text-gray-500 dark:text-gray-400"
                    }`}
                  >
                    {step.label}
                  </p>
                  {isActive && (
                    <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                      {step.id === "connecting" &&
                        "Validating API key and establishing connection..."}
                      {step.id === "discovering" &&
                        "Analyzing your database structure..."}
                      {step.id === "finalizing" &&
                        "Preparing your environment..."}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const ModelConnectionForm = ({ modelId, onSubmit, onCancel }) => {
  const [apiKey, setApiKey] = useState("");
  const [showApiKey, setShowApiKey] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingStep, setLoadingStep] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setLoadingStep("connecting");

    try {
      // Simulate API connection
      await new Promise((resolve) => setTimeout(resolve, 1500));
      setLoadingStep("discovering");

      // Call the actual submission
      await onSubmit({ apiKey });

      setLoadingStep("finalizing");
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (err) {
      setError(err.message || "Failed to connect. Please try again.");
      setIsLoading(false);
      setLoadingStep(null);
    }
  };

  if (modelId === "openai") {
    return (
      <form onSubmit={handleSubmit} className="space-y-6 relative">
        <LoadingOverlay isLoading={isLoading} currentStep={loadingStep} />

        <div className="space-y-6">
          {/* First Info Block - About OpenAI Integration */}
          <div className="relative overflow-hidden rounded-lg bg-gradient-to-r from-blue-50 to-indigo-50 dark:from-blue-900/20 dark:to-indigo-900/20 p-6 border border-blue-100 dark:border-blue-800">
            <h3 className="text-sm font-medium text-blue-900 dark:text-blue-100 mb-2">
              About OpenAI Integration
            </h3>
            <p className="text-sm text-blue-700 dark:text-blue-300 leading-relaxed">
              Your API key is used to process natural language queries and
              analyze your data through the OpenAI API. All communications are
              encrypted and secure.
            </p>
          </div>

          {/* Second Info Block - WaveQuery Integration Option */}
          <div className="relative overflow-hidden rounded-lg bg-gradient-to-r from-amber-50 to-yellow-50 dark:from-amber-900/20 dark:to-yellow-900/20 p-6 border border-amber-100 dark:border-amber-800">
            <div className="flex gap-4">
              <div className="space-y-3">
                <div>
                  <h3 className="text-sm font-medium text-amber-900 dark:text-amber-100">
                    Consider Our Built-in OpenAI Integration
                  </h3>
                  <p className="mt-2 text-sm text-amber-700 dark:text-amber-300 leading-relaxed">
                    This option includes an allocation of queries to help you
                    evaluate the service. When you're ready to scale up your
                    usage, you can easily upgrade your account.
                  </p>
                </div>

                <div className="flex flex-wrap items-center gap-2">
                  <span className="inline-flex items-center px-2.5 py-1.5 rounded-full text-xs font-medium bg-amber-100/70 text-amber-800 dark:bg-amber-900/50 dark:text-amber-300">
                    <Sparkles className="w-3.5 h-3.5 mr-1.5" />
                    Limited Free Queries
                  </span>
                  <span className="inline-flex items-center px-2.5 py-1.5 rounded-full text-xs font-medium bg-amber-100/70 text-amber-800 dark:bg-amber-900/50 dark:text-amber-300">
                    <Check className="w-3.5 h-3.5 mr-1.5" />
                    No OpenAI Setup Required
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-2">
          <Label
            className="flex items-center gap-2 text-sm font-medium text-gray-700 dark:text-gray-300"
            htmlFor="modelVersion"
          >
            Model Version
          </Label>
          <div className="relative">
            <Input
              type="text"
              id="modelVersion"
              value="gpt-4o-mini"
              className="h-9 pl-4 bg-gray-50 dark:bg-gray-900"
              disabled
            />
            <div className="absolute right-3 top-1.5">
              <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-600">
                Customizable Soon
              </span>
            </div>
          </div>
        </div>

        <div className="space-y-2">
          <Label
            className="flex items-center gap-2 text-sm font-medium text-gray-700 dark:text-gray-300"
            htmlFor="apiKey"
          >
            API Key
          </Label>
          <div className="relative">
            <Input
              type={showApiKey ? "text" : "password"}
              id="apiKey"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              className="h-9 pr-10"
              placeholder="Enter your OpenAI API key or leave it empty"
              // required
              disabled={isLoading}
            />
            <button
              type="button"
              onClick={() => setShowApiKey(!showApiKey)}
              className="absolute right-3 top-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
              disabled={isLoading}
            >
              {showApiKey ? (
                <EyeOff className="w-4 h-4" />
              ) : (
                <Eye className="w-4 h-4" />
              )}
            </button>
          </div>
          <p className="text-xs text-gray-500 flex items-center gap-1">
            <Lock className="w-3 h-3" />
            Your API key is encrypted and securely stored
          </p>
        </div>

        {error && (
          <div className="p-4 rounded-lg bg-red-50 dark:bg-red-900/10 border border-red-100 dark:border-red-900/50">
            <div className="flex gap-3">
              <AlertCircle className="w-5 h-5 text-red-600 dark:text-red-400" />
              <p className="text-sm text-red-600 dark:text-red-400">{error}</p>
            </div>
          </div>
        )}

        <DialogFooter className="pt-6 border-t border-gray-100 dark:border-gray-800">
          <div className="flex justify-end gap-2 w-full">
            <Button type="button" onClick={onCancel} disabled={isLoading}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="outline"
              disabled={isLoading}
              className="min-w-[120px]"
            >
              {isLoading ? (
                <span className="flex items-center gap-2">
                  <Loader2 className="w-4 h-4 animate-spin" />
                  Connecting...
                </span>
              ) : (
                "Connect Model"
              )}
            </Button>
          </div>
        </DialogFooter>
      </form>
    );
  }

  return null;
};

const ModelSelectionDialog = ({ isOpen, onClose, modelId, onSubmit }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[625px]">
        <DialogHeader>
          <DialogTitle>Configure OpenAI Integration</DialogTitle>
        </DialogHeader>
        <ModelConnectionForm
          modelId={modelId}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};

const ModelCard = ({ model, isSelected, onSelect }) => {
  const Icon = model.icon;

  return (
    <div
      onClick={() => model.available && onSelect(model.id)}
      className={`
        relative overflow-hidden rounded-xl p-6 transition-all duration-300
        ${
          model.available
            ? "cursor-pointer hover:shadow-lg hover:scale-[1.02] active:scale-[0.99]"
            : "opacity-75"
        }
        ${
          isSelected
            ? "ring-2 ring-blue-500 shadow-lg bg-blue-50 dark:bg-blue-900/20"
            : "border border-gray-100 dark:border-gray-800 hover:border-blue-100 dark:hover:border-blue-900/50"
        }
        bg-gradient-to-br ${model.bgClass}
      `}
    >
      {/* Test Badge for OpenAI */}
      {model.id === ModelType.OpenAI && (
        <div className="absolute top-0 right-0 mt-4 mr-4">
          <span className="inline-flex items-center px-3 py-1.5 rounded-full text-xs font-medium bg-blue-50 text-blue-600 dark:bg-blue-900/30 dark:text-blue-400 border border-blue-100 dark:border-blue-800 shadow-sm">
            <Sparkles className="w-3.5 h-3.5 mr-1.5" />
            Try without API key
          </span>
        </div>
      )}

      {/* Background Icon */}
      <div className="absolute right-0 bottom-0 opacity-[0.07] transform translate-x-1/4 translate-y-1/4 transition-transform group-hover:scale-110">
        <Icon className="w-48 h-48" />
      </div>

      {/* Content */}
      <div className="relative z-10">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <div className="p-2 rounded-lg bg-white dark:bg-gray-800 mr-3">
              <Icon className="w-5 h-5" />
            </div>
            <div>
              <h3 className="font-medium text-gray-900 dark:text-gray-100">
                {model.name}
              </h3>
              <span className="text-[10px] font-medium tracking-wider text-gray-500 dark:text-gray-400">
                {model.tag}
              </span>
            </div>
          </div>
          {!model.available && (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-200">
              Coming Soon
            </span>
          )}
        </div>

        <p className="text-sm text-gray-600 dark:text-gray-300 line-clamp-2">
          {model.description}
        </p>

        {model.available && (
          <div className="mt-4 flex items-center text-sm text-blue-600 dark:text-blue-400">
            Select Model
            <ArrowRight className="w-4 h-4 ml-1 transition-transform group-hover:translate-x-1" />
          </div>
        )}
      </div>
    </div>
  );
};

const ModelSelection: React.FC = () => {
  const navigate = useNavigate();
  const { currentStep, updateStep } = useSetup();
  const { updateAuthStatus } = useAuth();
  const { teamId, warehouseDetails, updateDatasetDetails } = useTeam();

  const [selectedModel, setSelectedModel] = useState<ModelType | null>(null);
  const [showConnectionModal, setShowConnectionModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (currentStep !== "model_selection") {
      navigate(`/setup/${currentStep}`);
    }
  }, [currentStep, navigate]);

  const handleModelSelect = (modelId: string) => {
    setSelectedModel(modelId as ModelType);
    if (modelId === ModelType.OpenAI) {
      setShowConnectionModal(true);
    }
  };

  const handleConnectionSubmit = async (data: any) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await setupService.submitModelSelection(
        teamId as string,
        {
          modelType: selectedModel,
          apiKey: data.apiKey,
        }
      );

      const discovery = await setupService.postDataDiscovery(
        teamId as string,
        warehouseDetails?.warehouse ?? "",
        warehouseDetails?.datasetId ?? ""
      );

      updateDatasetDetails({
        datasetId: discovery.datasetId,
        tables: discovery.tables,
        clusters: discovery.clusters,
      });

      await updateStep(response.nextStep as SetupStep, {
        modelType: selectedModel,
        projectId: warehouseDetails?.projectId,
        datasetId: warehouseDetails?.datasetId,
      });

      await updateAuthStatus(response.nextStep);
      setShowConnectionModal(false);
    } catch (err) {
      setError("Failed to submit model selection. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <Card className="border-0 shadow-none dark:bg-black/50 backdrop-blur-sm">
        <CardHeader className="pb-8">
          <div className="space-y-2">
            <span className="text-blue-600 dark:text-blue-500 text-sm font-medium">
              AI MODEL
            </span>
            <div className="space-y-2">
              <CardTitle className="text-3xl font-normal">
                Choose your AI model
              </CardTitle>
              <p className="text-base text-gray-600 dark:text-gray-400">
                Select the AI model that will power your data analytics
              </p>
            </div>
          </div>
        </CardHeader>

        <CardContent className="space-y-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {models.map((model) => (
              <ModelCard
                key={model.id}
                model={model}
                isSelected={selectedModel === model.id}
                onSelect={handleModelSelect}
              />
            ))}
          </div>

          {error && (
            <div className="p-4 rounded-lg bg-red-50/50 dark:bg-red-900/10 border border-red-100 dark:border-red-900/50">
              <div className="flex gap-3">
                <AlertCircle className="w-5 h-5 text-red-600 dark:text-red-400 flex-shrink-0" />
                <p className="text-sm text-red-600 dark:text-red-400">
                  {error}
                </p>
              </div>
            </div>
          )}
        </CardContent>
      </Card>

      <ModelSelectionDialog
        isOpen={showConnectionModal}
        onClose={() => setShowConnectionModal(false)}
        modelId={selectedModel?.toLowerCase()}
        onSubmit={handleConnectionSubmit}
      />
    </div>
  );
};

export default ModelSelection;
