import React from "react";
import { Sparkles } from "lucide-react";

const ComingSoonOverlay = ({
  title = "Coming Soon",
  description = "This feature is currently under development.",
}) => {
  return (
    <div className="absolute inset-0 bg-background/80 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="text-center space-y-4 max-w-[280px] p-6">
        <div className="relative mx-auto w-12 h-12">
          <Sparkles className="w-12 h-12 text-primary/20 absolute" />
          <Sparkles
            className="w-12 h-12 text-primary absolute animate-pulse"
            style={{ animationDuration: "3s" }}
          />
        </div>
        <div className="space-y-2">
          <h3 className="font-semibold text-xl">{title}</h3>
          <p className="text-sm text-muted-foreground leading-relaxed">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonOverlay;
