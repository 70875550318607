import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  LogOut,
  LogIn,
  Building2,
  Mail,
  ChevronDown,
  Database,
  Plus,
} from "lucide-react";
import logo from "@/assets/logo.png";
import { authService } from "@/services/authService";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/Common/DropDown";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  Alert,
  AlertDescription,
} from "@/components/Common";
import { Button } from "@/components/Common";
import { Input } from "@/components/Common";
import { TextArea } from "@/components/Common";
import { useTeam } from "@/context/TeamContext";
import SetupDialog from "./SetupDialog";
import {
  GoogleLogo,
  SnowflakeLogo,
  DefaultDatabaseLogo,
} from "@/components/Logos";

interface HeaderRightProps {
  warehouseDetails: any;
  onOpenFilePanel: () => void;
  WarehouseLogo: React.ComponentType<{ className?: string }>;
}

const warehouseLogos = {
  gcp: GoogleLogo,
  snowflake: SnowflakeLogo,
  default: DefaultDatabaseLogo,
};

const HeaderRight: React.FC<HeaderRightProps> = ({
  warehouseDetails,
  onOpenFilePanel,
}) => {
  const [isSetupDialogOpen, setIsSetupDialogOpen] = useState(false);
  const [isCustomDataSourceOpen, setIsCustomDataSourceOpen] = useState(false);

  const WarehouseLogo =
    warehouseLogos[warehouseDetails?.warehouse] || warehouseLogos.default;

  const handleConnectDatabase = () => {
    setIsSetupDialogOpen(true);
  };

  const handleConnectCurrentWarehouse = () => {
    setIsSetupDialogOpen(false);
    setIsCustomDataSourceOpen(false);
  };

  const handleConnectCustomData = () => {
    setIsCustomDataSourceOpen(true);
    onOpenFilePanel();
  };

  return (
    <>
      <div className="flex items-center space-x-4">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="flex items-center gap-2">
              {isCustomDataSourceOpen ? (
                <>
                  <span className="text-sm">Custom data source</span>
                </>
              ) : warehouseDetails ? (
                <>
                  <WarehouseLogo className="w-5 h-5" />
                  <span className="text-sm">{warehouseDetails.datasetId}</span>
                </>
              ) : (
                <>
                  <Database className="w-4 h-4" />
                  <span className="text-sm">Select Data Source</span>
                </>
              )}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-56">
            {warehouseDetails && (
              <>
                <DropdownMenuItem
                  onClick={handleConnectCurrentWarehouse}
                  className="text-gray-500"
                >
                  <Database className="w-4 h-4 mr-2" />
                  Connected to: {warehouseDetails.datasetId}
                </DropdownMenuItem>
                <DropdownMenuSeparator />
              </>
            )}
            <DropdownMenuItem onClick={handleConnectDatabase}>
              <Plus className="w-4 h-4 mr-2" />
              New data sources
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <SetupDialog
        isOpen={isSetupDialogOpen}
        onClose={() => setIsSetupDialogOpen(false)}
      />
    </>
  );
};

const YouTubeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="currentColor"
    className="text-white"
  >
    <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
  </svg>
);

const Header = ({ onToggleTheme, theme }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId, teamName, clearUserDataFromStore, warehouseDetails } =
    useTeam();

  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackData, setFeedbackData] = useState({
    subject: "",
    message: "",
  });

  const isPlayground = location.pathname.includes("playground");
  const isDashboard = location.pathname.includes("dashboard");
  const isLoginPage = location.pathname === "/login";

  const handleLogout = async () => {
    try {
      await authService.logout();
      clearUserDataFromStore();
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleWatchDemo = () => {
    window.open(
      "https://www.youtube.com/watch?v=kpasCOmJT5I&ab_channel=aminkhorrami",
      "_blank"
    );
  };

  const handleTryPlayground = () => {
    navigate("/playground");
  };

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    console.log("Feedback submitted:", feedbackData);
    setFeedbackOpen(false);
    setFeedbackData({ subject: "", message: "" });
  };

  return (
    <header className="bg-white dark:bg-gray-800 shadow-md">
      <nav className="container mx-auto py-4 flex justify-between items-center">
        {/* Left Section - Logo and Organization */}
        <div className="flex items-center space-x-8">
          <Link to="/" className="flex items-center">
            <img src={logo} alt="WaveQuery Logo" className="h-6 w-auto" />
          </Link>
        </div>

        {/* Right Section - Actions */}
        <div className="flex items-center gap-4">
          {!isPlayground && !isLoginPage && !isDashboard && (
            <Button
              onClick={handleTryPlayground}
              className="bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white px-4 py-1.5 rounded-full text-sm font-medium transition-colors duration-200 shadow-sm"
            >
              Try Playground
            </Button>
          )}
          
          {userId && warehouseDetails?.name && (
            <>
              <HeaderRight
                warehouseDetails={warehouseDetails}
                // onOpenFilePanel={handleOpenFilePanel}
              />

              <div className="flex items-center">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <button className="flex items-center space-x-3 px-3 py-1.5 rounded-lg text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-800/50 transition-colors">
                      <Building2 className="h-4 w-4 text-gray-400" />
                      <span className="text-sm font-medium">
                        {warehouseDetails?.name || "Your Organization"}
                      </span>
                      <ChevronDown className="h-4 w-4 text-gray-400" />
                    </button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    sideOffset={10}
                    align="start"
                    className="w-64"
                  >
                    <DropdownMenuGroup>
                      <DropdownMenuItem onClick={() => setFeedbackOpen(true)}>
                        <Mail className="mr-2 h-4 w-4 text-gray-400" />
                        <span>Send Feedback</span>
                      </DropdownMenuItem>
                      {/* <DropdownMenuItem onClick={onToggleTheme}>
                        {theme === "dark" ? (
                          <Sun className="mr-2 h-4 w-4 text-gray-400" />
                        ) : (
                          <Moon className="mr-2 h-4 w-4 text-gray-400" />
                        )}
                        <span>
                          {theme === "dark" ? "Light Theme" : "Dark Theme"}
                        </span>
                      </DropdownMenuItem> */}
                      <DropdownMenuSeparator />
                      <DropdownMenuItem
                        onClick={handleLogout}
                        className="text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/10"
                      >
                        <LogOut className="mr-2 h-4 w-4" />
                        <span>Log Out</span>
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </>
          )}

          {isPlayground && (
            <>
              <div className="bg-blue-100 text-blue-800 px-4 py-1 rounded-full text-sm font-medium mr-4">
                Please contact us to see the dashboard with more features
              </div>

              <Button
                onClick={handleWatchDemo}
                className="bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white px-4 py-1.5 rounded-full text-sm font-medium mr-4 transition-colors duration-200 flex items-center gap-2 shadow-sm"
              >
                <YouTubeIcon />
                Dashboard Demo
              </Button>
            </>
          )}

          {!userId && !isLoginPage && (
            <div className="flex items-center gap-2">
              <Button
                onClick={handleLogin}
                variant="ghost"
                className="text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 flex items-center gap-2"
              >
                <LogIn className="h-4 w-4" />
                Login
              </Button>
            </div>
          )}
        </div>
      </nav>

      {/* Feedback Dialog */}
      <Dialog open={feedbackOpen} onOpenChange={setFeedbackOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Send Feedback</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleFeedbackSubmit} className="space-y-4">
            <Alert>
              <AlertDescription>
                Your feedback is going to make WaveQuery closer to how you want
                it to be :)
              </AlertDescription>
            </Alert>
            <div>
              <Input
                placeholder="Subject"
                value={feedbackData.subject}
                onChange={(e) =>
                  setFeedbackData({ ...feedbackData, subject: e.target.value })
                }
                className="w-full"
              />
            </div>
            <div>
              <TextArea
                placeholder="Your feedback..."
                value={feedbackData.message}
                onChange={(e) =>
                  setFeedbackData({ ...feedbackData, message: e.target.value })
                }
                className="min-h-[100px]"
              />
            </div>
            <DialogFooter>
              <Button type="submit" className="w-full">
                Send Feedback
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </header>
  );
};

export default Header;
