import { Table } from "@/components/Common/Table";
import { Download, ChevronDown, ChevronUp, Info } from "lucide-react";
import { useState } from "react";
import { Button } from "@/components/Common/Button";
import { motion, AnimatePresence } from "framer-motion";
import { 
  Tooltip,
  TooltipTrigger,
  TooltipContent 
} from "@/components/Common/Tooltip";
import { PopoverWrapper } from "@/components/Common/Popover";

const QueryResults = ({ data }) => {
  const [expanded, setExpanded] = useState(false);
  
  if (!data?.result?.result || !Array.isArray(data.result.result) || data.result.result.length === 0) {
    return null;
  }
  
  const allResults = data.result.result;
  const visibleRows = expanded ? allResults : allResults.slice(0, 5);
  
  // Create columns with tooltips for long content
  const columns = Object.keys(allResults[0] || {}).map(key => ({
    title: key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
    dataIndex: key,
    key: key,
    render: (value) => {
      const displayValue = value === null || value === undefined ? '' 
        : typeof value === 'object' ? JSON.stringify(value) 
        : String(value);
        
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="max-w-[150px] truncate">
              {displayValue}
            </div>
          </TooltipTrigger>
          <TooltipContent>
            {displayValue}
          </TooltipContent>
        </Tooltip>
      );
    }
  }));

  const downloadCSV = () => {
    if (allResults.length === 0 || columns.length === 0) return;
    
    const headers = columns.map(col => col.title).join(',');
    const rows = allResults.map(record => 
      columns.map(column => {
        const value = record[column.dataIndex];
        if (value === null || value === undefined) return '';
        return typeof value === 'string' && value.includes(',') 
          ? `"${value}"` 
          : value;
      }).join(',')
    );

    const csvContent = [headers, ...rows].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'query_result.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 1, height: "auto" }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ duration: 0.3 }}
        className="flex items-start space-x-4 overflow-hidden"
      >
        <div 
          className="relative border rounded-lg shadow-sm overflow-hidden"
          style={{ width: "450px" }}
        >
          <div className="max-h-[300px] overflow-auto">
            <Table
              columns={columns}
              dataSource={visibleRows}
              pagination={false}
              size="small"
            />
          </div>

          {allResults.length > 5 && (
            <div className="text-center py-2">
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    onClick={() => setExpanded(!expanded)}
                    className="text-blue-500 hover:text-blue-700"
                    variant="ghost"
                  >
                    {expanded ? (
                      <>
                        <ChevronUp className="w-4 h-4 mr-1" />
                        Show Less
                      </>
                    ) : (
                      <>
                        <ChevronDown className="w-4 h-4 mr-1" />
                        Show More ({allResults.length - 5} more rows)
                      </>
                    )}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  {expanded ? "Show fewer rows" : "Show more rows"}
                </TooltipContent>
              </Tooltip>
            </div>
          )}
        </div>

        <div className="flex flex-col space-y-2">
          <PopoverWrapper
            trigger={
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    className="p-2 hover:bg-gray-100 rounded"
                    variant="ghost"
                    size="sm"
                  >
                    <Info className="w-4 h-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>View Query Information</TooltipContent>
              </Tooltip>
            }
            content={<div className="p-4 text-sm">Query execution time: {data?.metadata?.executionTime}ms</div>}
          />
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={downloadCSV}
                className="p-2 hover:bg-gray-100 rounded"
                variant="ghost"
                size="sm"
              >
                <Download className="w-4 h-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Download as CSV</TooltipContent>
          </Tooltip>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default QueryResults;