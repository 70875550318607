import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Brain,
  X,
  Lock,
  Power,
  Settings,
  AlertCircle,
  Check,
  Key,
  Sparkles,
  Cpu,
  Bot,
  ChevronDown,
  Shield,
  Zap,
} from "lucide-react";
import { usePlayground } from "@/context/PlaygroundContext";
import { cn } from "@/components/utils";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Common/Select";
import { Input } from "@/components/Common/Input";
import { Button } from "@/components/Common/Button";
import { Card, CardContent } from "@/components/Common/Card";
import { Switch } from "@/components/Common/Switch";
import { Slider } from "@/components/Common/Slider";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/Common/Tooltip";
import { Badge } from "@/components/Common/Badge";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/Common/Accordion";

import OpenAIImage from "@/assets/openai.png";
import AnthropicAIImage from "@/assets/anthropicai.png";
import ComingSoonOverlay from "../ComingSoonOverlay";

const LLM_PROVIDERS = [
  {
    value: "openai",
    label: "OpenAI",
    icon: OpenAIImage,
    description: "Leading AI models with broad capabilities",
    disabled: false,
    models: [
      {
        value: "gpt-4",
        label: "GPT-4",
        description: "Most capable model, best for complex database analysis",
        tokensPerMinute: 40000,
        costPer1kTokens: 0.03,
      },
      {
        value: "gpt-4-turbo",
        label: "GPT-4 Turbo",
        description: "Faster version with latest database knowledge",
        tokensPerMinute: 60000,
        costPer1kTokens: 0.01,
      },
      {
        value: "gpt-3.5-turbo",
        label: "GPT-3.5 Turbo",
        description: "Fast and cost-effective for simpler queries",
        tokensPerMinute: 90000,
        costPer1kTokens: 0.002,
      },
    ],
  },
  {
    value: "anthropic",
    label: "Anthropic",
    icon: AnthropicAIImage,
    description: "Specialized in database understanding",
    disabled: true,
    comingSoon: true,
  },
  // Additional providers...
];

// Configuration validation functions
const validateApiKey = (key: string): boolean => {
  if (!key) return false;
  // Add provider-specific key validation
  return key.length >= 32;
};

export const LLMConfigPanel = () => {
  const { panels, setPanels, llmConfig, setLLMConfig } = usePlayground();
  const [isConfigured, setIsConfigured] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const selectedProvider = LLM_PROVIDERS.find(
    (p) => p.value === llmConfig.provider
  );
  const selectedModel = selectedProvider?.models?.find(
    (m) => m.value === llmConfig.model
  );

  const handleApiKeyChange = (key: string) => {
    setLLMConfig((prev) => ({ ...prev, apiKey: key }));
    setHasUnsavedChanges(true);

    if (!validateApiKey(key)) {
      setValidationError("Invalid API key format");
    } else {
      setValidationError(null);
    }
  };

  const handleSaveConfig = async () => {
    setIsValidating(true);
    setValidationError(null);

    try {
      // Validate API key format
      if (!validateApiKey(llmConfig.apiKey || "")) {
        throw new Error("Invalid API key format");
      }

      // Simulate API key validation with the provider
      await new Promise((resolve) => setTimeout(resolve, 1000));

      setIsConfigured(true);
      setLLMConfig((prev) => ({ ...prev, enabled: true }));
      setHasUnsavedChanges(false);
    } catch (error) {
      setValidationError(
        error instanceof Error ? error.message : "Validation failed"
      );
    } finally {
      setIsValidating(false);
    }
  };

  const handleReset = () => {
    if (hasUnsavedChanges) {
      // Add confirmation dialog here
      if (
        !window.confirm(
          "You have unsaved changes. Are you sure you want to reset?"
        )
      ) {
        return;
      }
    }

    setIsConfigured(false);
    setLLMConfig({
      provider: "openai",
      enabled: false,
      apiKey: "",
      model: "gpt-4",
      temperature: 0.7,
      maxTokens: 2000,
    });
    setValidationError(null);
    setHasUnsavedChanges(false);
  };

  const panelVariants = {
    hidden: { x: 300, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        damping: 20,
        stiffness: 300,
      },
    },
    exit: {
      x: 300,
      opacity: 0,
      transition: {
        type: "spring",
        damping: 30,
        stiffness: 400,
      },
    },
  };

  return (
    <AnimatePresence>
      {panels.isRightPanelOpen && (
        <motion.div
          variants={panelVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="absolute right-4 top-4 w-80 bg-white rounded-lg shadow-lg border overflow-hidden"
        >
          <ComingSoonOverlay
            title="Custom Model Selection Coming Soon"
            description="You will be able to choose the Model you want. Stay tuned!"
          />
          {/* Panel Header */}
          <div className="p-4 border-b flex items-center justify-between bg-muted/50">
            <div className="flex items-center gap-2">
              <Brain className="h-5 w-5 text-primary" />
              <div>
                <h3 className="font-semibold">AI Assistant</h3>
                <ConfigurationStatus
                  isConfigured={isConfigured}
                  hasUnsavedChanges={hasUnsavedChanges}
                />
              </div>
            </div>
            <Button
              variant="ghost"
              size="sm"
              onClick={() =>
                setPanels((prev) => ({ ...prev, isRightPanelOpen: false }))
              }
            >
              <X className="h-4 w-4" />
            </Button>
          </div>

          <div className="p-4 space-y-6">
            {/* Provider Selection */}
            <ProviderSelector
              selectedProvider={llmConfig.provider}
              onProviderChange={(value) => {
                setLLMConfig((prev) => ({ ...prev, provider: value }));
                setHasUnsavedChanges(true);
              }}
              isConfigured={isConfigured}
            />

            {/* Model Selection */}
            <ModelSelector
              provider={selectedProvider}
              selectedModel={llmConfig.model}
              onModelChange={(value) => {
                setLLMConfig((prev) => ({ ...prev, model: value }));
                setHasUnsavedChanges(true);
              }}
              isConfigured={isConfigured}
            />

            {/* API Key Input */}
            <ApiKeyInput
              value={llmConfig.apiKey || ""}
              onChange={handleApiKeyChange}
              error={validationError}
              isConfigured={isConfigured}
            />

            {/* Advanced Settings */}
            <AdvancedSettings
              config={llmConfig}
              onConfigChange={(changes) => {
                setLLMConfig((prev) => ({ ...prev, ...changes }));
                setHasUnsavedChanges(true);
              }}
              isConfigured={isConfigured}
            />

            {/* Status and Actions */}
            <ConfigurationActions
              isConfigured={isConfigured}
              isValidating={isValidating}
              hasUnsavedChanges={hasUnsavedChanges}
              onSave={handleSaveConfig}
              onReset={handleReset}
              disabled={!!validationError}
            />

            {/* Usage Information */}
            {selectedModel && (
              <UsageInformation
                tokensPerMinute={selectedModel.tokensPerMinute}
                costPer1kTokens={selectedModel.costPer1kTokens}
              />
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const ConfigurationStatus = ({ isConfigured, hasUnsavedChanges }) => (
  <div className="flex items-center gap-1.5 text-xs">
    {isConfigured ? (
      <div className="flex items-center gap-1.5 text-green-600">
        <Check className="h-3 w-3" />
        <span>{hasUnsavedChanges ? "Changes pending..." : "Ready to use"}</span>
      </div>
    ) : (
      <p className="text-muted-foreground">Configure your AI assistant</p>
    )}
  </div>
);

const ProviderSelector = ({
  selectedProvider,
  onProviderChange,
  isConfigured,
}) => (
  <div className="space-y-2">
    <label className="text-sm font-medium flex items-center gap-2">
      <Bot className="h-4 w-4 text-muted-foreground" />
      AI Provider
    </label>
    <Select
      value={selectedProvider}
      onValueChange={onProviderChange}
      disabled={isConfigured}
    >
      <SelectTrigger>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        {LLM_PROVIDERS.map((provider) => (
          <SelectItem
            key={provider.value}
            value={provider.value}
            disabled={provider.disabled}
            className="flex items-center gap-2"
          >
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center gap-2">
                <img
                  src={provider.icon}
                  alt={provider.label}
                  className="h-4 w-4"
                />
                <span>{provider.label}</span>
              </div>
              {provider.comingSoon && (
                <Badge variant="secondary" className="ml-auto text-xs">
                  Soon
                </Badge>
              )}
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  </div>
);

const ModelSelector = ({
  provider,
  selectedModel,
  onModelChange,
  isConfigured,
}) => (
  <div className="space-y-2">
    <label className="text-sm font-medium flex items-center gap-2">
      <Cpu className="h-4 w-4 text-muted-foreground" />
      Model
    </label>
    <Select
      value={selectedModel}
      onValueChange={onModelChange}
      disabled={isConfigured}
    >
      <SelectTrigger>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        {provider?.models?.map((model) => (
          <SelectItem key={model.value} value={model.value}>
            <div className="flex flex-col gap-1">
              <span>{model.label}</span>
              <span className="text-xs text-muted-foreground">
                {model.description}
              </span>
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  </div>
);

const ApiKeyInput = ({ value, onChange, error, isConfigured }) => (
  <div className="space-y-2">
    <label className="text-sm font-medium flex items-center gap-2">
      <Key className="h-4 w-4 text-muted-foreground" />
      API Key
    </label>
    <div className="relative">
      <Input
        type="password"
        placeholder="Enter your API key"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={isConfigured}
        className={cn("pr-8", error && "border-red-500")}
      />
      <Lock className="absolute right-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
    </div>
    {error && (
      <p className="text-xs text-red-500 flex items-center gap-1">
        <AlertCircle className="h-3 w-3" />
        {error}
      </p>
    )}
  </div>
);

const AdvancedSettings = ({ config, onConfigChange, isConfigured }) => (
  <Accordion type="single" collapsible>
    <AccordionItem value="advanced">
      <AccordionTrigger className="text-sm font-medium">
        <div className="flex items-center gap-2">
          <Settings className="h-4 w-4 text-muted-foreground" />
          Advanced Settings
        </div>
      </AccordionTrigger>
      <AccordionContent className="space-y-4 pt-4">
        {/* <div className="space-y-2">
          <label className="text-sm font-medium flex items-center gap-2">
            <Zap className="h-4 w-4 text-muted-foreground" />
            Temperature
          </label>
          <Slider
            value={[config.temperature || 0.7]}
            min={0}
            max={1}
            step={0.1}
            onValueChange={([value]) => onConfigChange({ temperature: value })}
            disabled={isConfigured}
          />
          <p className="text-xs text-muted-foreground">
            Controls randomness in responses
          </p>
        </div> */}
        <div className="space-y-2">
          <label className="text-sm font-medium flex items-center gap-2">
            <Shield className="h-4 w-4 text-muted-foreground" />
            Max Tokens
          </label>
          <Input
            type="number"
            value={config.maxTokens || 2000}
            onChange={(e) =>
              onConfigChange({ maxTokens: parseInt(e.target.value) })
            }
            disabled={isConfigured}
            min={1}
            max={4000}
          />
          <p className="text-xs text-muted-foreground">
            Maximum length of generated responses
          </p>
        </div>
      </AccordionContent>
    </AccordionItem>
  </Accordion>
);

const ConfigurationActions = ({
  isConfigured,
  isValidating,
  hasUnsavedChanges,
  onSave,
  onReset,
  disabled,
}) => (
  <div className="flex gap-2">
    {!isConfigured ? (
      <Button
        className="w-full"
        onClick={onSave}
        disabled={disabled || isValidating}
      >
        {isValidating ? (
          <>
            <Settings className="h-4 w-4 mr-2 animate-spin" />
            Validating...
          </>
        ) : (
          <>Save Configuration</>
        )}
      </Button>
    ) : (
      <>
        {hasUnsavedChanges && (
          <Button
            className="flex-1"
            variant="default"
            onClick={onSave}
            disabled={disabled || isValidating}
          >
            <Check className="h-4 w-4 mr-2" />
            Apply Changes
          </Button>
        )}
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="outline" className="flex-1" onClick={onReset}>
              <Settings className="h-4 w-4 mr-2" />
              Reset Configuration
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Clear all settings and start over</p>
          </TooltipContent>
        </Tooltip>
      </>
    )}
  </div>
);

const UsageInformation = ({ tokensPerMinute, costPer1kTokens }) => (
  <Card className="bg-muted/50 border-none">
    <CardContent className="p-3 space-y-3">
      <div className="flex gap-2 text-xs text-muted-foreground">
        <Sparkles className="h-4 w-4 flex-shrink-0" />
        <p>
          With AI assistance enabled, you can analyze your database schema,
          generate queries, and get intelligent suggestions.
        </p>
      </div>
      <div className="grid grid-cols-2 gap-2 text-xs">
        <div className="flex items-center gap-1.5">
          <Zap className="h-3 w-3 text-muted-foreground" />
          <span>{tokensPerMinute.toLocaleString()} tokens/min</span>
        </div>
        <div className="flex items-center gap-1.5">
          <Shield className="h-3 w-3 text-muted-foreground" />
          <span>${costPer1kTokens}/1k tokens</span>
        </div>
      </div>
    </CardContent>
  </Card>
);

export default LLMConfigPanel;
